import React from 'react';
import { Box } from '@material-ui/core';
import ScheduleComponent from '@Components-Project/scheduleComponent';
import { setMeeting } from '@Redux-Project/actions/meetingActions';
import { useDispatch } from 'react-redux';
import { _formatMeeting } from '@Functions-Project/formatData';
import api from '@Constants-Project/api';

import { Helmet } from "react-helmet";

export default function Schedule() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(!open);
    const getTestimony = async () => {
        let currentDate = new Date();
        let date1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let InitDate = `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}T00:00:00`;
        let date2 = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        let EndDate = `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}T23:59:59`;
        let params = { InitDate, EndDate };
        let response = await api.GET('api/Meeting', (e) => { console.log('e => ', e) }, params);
        if (response.success) dispatch(setMeeting(await _formatMeeting(response.data)));
        else alert(response.message + ' Code: ' + response.status)
    }

    //document.title = "Polen | Eventos";

    React.useEffect(() => {
        getTestimony();
    }, [])

    return (
        <>
        <Helmet 
            title={"Polen | Eventos"} 
            meta={[
            {
                name: 'description',
                content: "Prográmate para nuestros eventos",
            }
        ]}
        />
        <Box>
            <ScheduleComponent open={open} handleClose={handleClose} />
        </Box>
        </>
    );
}