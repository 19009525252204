import React, { useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import Videos from "@Constants-Project/videos";
import { LINK_VIDEO_WHATISPOLEN } from "@Constants-Project/constants";

export default function MakeYourGoalOurGoal() {
    const wipStyles = whatIsPolenStyles();
    const src = LINK_VIDEO_WHATISPOLEN;
    const refVideo = useRef(null);
    useEffect(() => onPlay(), []);
    const onPlay = (currentTime) => {/*console.log('Tiempo restante del video => ', currentTime)*/ };
    return (
        <ContainerComponent className={wipStyles.divVideo}>
            <Typography variant={'h1'}>Hacemos de tu meta, nuestra meta</Typography>
            <VideoPlayer ref={refVideo} src={src} onPlay={onPlay} auto={true} mute={false} />
        </ContainerComponent>
    );
}
