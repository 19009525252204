import FontBeVietnamBold from '@Assets-Project/fonts/BeVietnam-Bold.ttf';
import FontBeVietnamBoldWoff2 from '@Assets-Project/fonts/BeVietnam-Bold.woff2';
import FontBeVietnamBoldSVG from '@Assets-Project/fonts/BeVietnam-Bold.svg';

import FontBeVietnamRegular from '@Assets-Project/fonts/BeVietnam-Regular.ttf';
import FontBeVietnamRegularWoff2 from '@Assets-Project/fonts/BeVietnam-Regular.woff2';
import FontBeVietnamRegularSVG from '@Assets-Project/fonts/BeVietnam-Regular.svg';

import FontBeVietnamMedium from '@Assets-Project/fonts/BeVietnam-Medium.ttf';
import FontBeVietnamMediumWoff2 from '@Assets-Project/fonts/BeVietnam-Medium.woff2';
import FontBeVietnamMediumSVG from '@Assets-Project/fonts/BeVietnam-Medium.svg';

import FontBeVietnamLight from '@Assets-Project/fonts/BeVietnam-Light.ttf';
import FontBeVietnamLightWoff2 from '@Assets-Project/fonts/BeVietnam-Light.woff2';
import FontBeVietnamLightSVG from '@Assets-Project/fonts/BeVietnam-Light.svg';


import FontMyriadProRegular from '@Assets-Project/fonts/MyriadPro-Regular.otf';
import FontMyriadProRegularTtf from '@Assets-Project/fonts/MyriadPro-Regular.ttf';
import FontMyriadProRegularWoff2 from '@Assets-Project/fonts/MyriadPro-Regular.woff2';

export const BeVietnamBold = {
  fontFamily: 'BeVietnam-Bold',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  //fontWeight: 400,
  src: `
    local('BeVietnamBold'),
    url(${FontBeVietnamBold}) format('ttf')
    url(${FontBeVietnamBoldWoff2}) format('woff2')
    url(${FontBeVietnamBoldSVG}) format('svg')
  `,
  unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const BeVietnamRegular = {
  fontFamily: 'BeVietnamRegular',
  fontStyle: 'normal',
  src: `
    local('BeVietnamRegular'),
    local('BeVietnam-Regular'),
    url(${FontBeVietnamRegular}) format('ttf')
    url(${FontBeVietnamRegularWoff2}) format('woff2')
    url(${FontBeVietnamRegularSVG}) format('svg')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const BeVietnamLight = {
  fontFamily: 'BeVietnamLight',
  fontStyle: 'normal',
  src: `
    local('BeVietnamLight'),
    local('BeVietnam-Light'),
    url(${FontBeVietnamLight}) format('ttf')
    url(${FontBeVietnamLightWoff2}) format('woff2')
    url(${FontBeVietnamLightSVG}) format('svg')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const BeVietnamMedium = {
  fontFamily: 'BeVietnamMedium',
  fontStyle: 'normal',
  src: `
    local('BeVietnamMedium'),
    local('BeVietnam-Medium'),
    url(${FontBeVietnamMedium}) format('ttf')
    url(${FontBeVietnamMediumWoff2}) format('woff2')
    url(${FontBeVietnamMediumSVG}) format('svg')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const MyriadProRegular = {
  fontFamily: 'MyriadProRegular',
  fontStyle: 'normal',
  src: `
    local('MyriadProRegular'),
    local('MyriadPro-Regular'),
    url(${FontMyriadProRegular}) format('otf')
    url(${FontMyriadProRegularTtf}) format('ttf')
    url(${FontMyriadProRegularWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
