import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import ContainerComponent from "@Components-Project/containerComponent";
import { scheduleStyles } from '@Styles-Project/theme/layout/schedule';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    AppointmentTooltip,
    Scheduler,
    DateNavigator,
    MonthView,
    Toolbar,
    TodayButton,
    Appointments,
    Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useSelector, useDispatch } from 'react-redux';
import { setMeeting, setDateTarget } from '@Redux-Project/actions/meetingActions';
import { _formatMeeting } from '@Functions-Project/formatData';
import api from '@Constants-Project/api';

export default function ScheduleComp({ setDateTarget }) {
    const sStyles = scheduleStyles();
    const bStyles = buttonsStyles();
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = React.useState(new Date());
    const { Meeting } = useSelector(state => state.meeting);
    const [ meetings, setMeetings ] = React.useState(Meeting);
    const [ reload, setReload ] = React.useState(true);
    const [ dateFilter, setDateFilter ] = React.useState(new Date());

    useEffect(() => {
        setReload(!meetings);
    }, [meetings]);

    useEffect(() => {
        setMeetings(Meeting);
    }, Meeting);

    const Colors = [
        { text: "Culminado", id: 1, color: "#a7a8aa", },
        { text: "Proximo", id: 2, color: "#fe5b02" },
    ];

    const resources = [
        { fieldName: "ColorId", instances: Colors }
    ];

    const filterMeetings = (restProps) => {
        const varDateFilter = new Date(restProps.initDate);
        setReload(true);
        if (dateFilter.toLocaleDateString() !== varDateFilter.toLocaleDateString()) {
            setDateFilter(varDateFilter);
            setMeetings(Meeting.filter(m => new Date(m.initDate).toLocaleDateString() === varDateFilter.toLocaleDateString()));
            setReload(false);
        } else {
            setDateFilter(new Date());
            setMeetings(Meeting);
            setReload(false);
        }
    }

    const Appointment = ({ children, style, ...restProps }) => {
        // console.log('style ::= ', style)
        return <Appointments.Appointment
            {...restProps}
            style={{
                ...style,
                borderRadius: '15px',
                width: '30px',
                height: '30px',
                color: 'orange',
                margin: 'auto',
                position: 'relative',
                left: '50%',
                marginLeft: '-16px',
                top: '-8px',
                opacity: 0.7
            }}
        >
            {/* {children} */}
            <div style={{ width: '30px', height: '30px' }}>
                <button className={sStyles.btnDay} onClick={() => { filterMeetings(restProps.data) }}/>
            </div>
        </Appointments.Appointment>
    };

    const getTestimony = async (currentDate) => {
        let date1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let InitDate = `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}T00:00:00`;
        let date2 = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        let EndDate = `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}T23:59:59`;
        let params = { InitDate, EndDate };
        let response = await api.GET('api/Meeting', () => { console.log('callbak') }, params);
        if (response.success) dispatch(setMeeting(await _formatMeeting(response.data)));
        else alert(response.message + ' Code: ' + response.status)
    }

    const changeDateSchedule = (currentDate) => {
        // console.log('currentDate => ', currentDate)
        getTestimony(currentDate)
        setDateTarget(false)
    }

    return <Box className={sStyles.bgGraySchedule}>
        <ContainerComponent className={'divSchedule'}>
            <Typography variant={'h1'}>¡Prográmate para nuestros eventos!</Typography>
            <Grid container direction='row' className={'calendarContent'}>
                <Grid item xs={12} sm={5} className={'divScheduler'}>
                    <Scheduler data={Meeting} height={350}
                               locale={"es"} >
                        <ViewState
                            defaultCurrentDate={currentDate}
                            onCurrentDateChange={(currentDate) => changeDateSchedule(currentDate)}
                        />
                        <MonthView dayScaleCellComponent={DayScaleCell} />
                        <Toolbar />
                        <DateNavigator />
                        <TodayButton />
                        <Appointments appointmentComponent={Appointment} />
                        <Resources data={resources} />
                    </Scheduler>
                </Grid>
                <Grid item xs={12} sm={7} className={'meetings'}>
                    {
                        reload ?
                            null
                            :
                            meetings.map((item, key) => {
                                return <Event item={item} key={key} setDateTarget={setDateTarget} />
                            })
                    }
                    {/* <Grid container direction='row' className={'buttons'}>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" className={`${bStyles.button} ${bStyles.whiteButton}`}>Enviar programación</Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" className={`${bStyles.button} ${bStyles.orangeButton}`}>Resultados asambleas</Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </ContainerComponent>
    </Box>
}

const Event = ({ item, setDateTarget }) => {
    const { title, description, initDate } = item;
    let date = new Date(initDate);
    let day = date.getDate();
    let active = new Date() <= new Date(initDate);
    const setEventTarget = (data) => setDateTarget(data);
    return <Button onClick={() => setEventTarget(item)} key={item.id} className={`${'cardSchedule'} ${active ? 'active' : 'inactive'}`}>
        <Grid container direction='row'>
            <Grid item xs={3} className={'date'}>
                <Typography variant={'h1'}>{day}</Typography>
            </Grid>
            <Grid item xs={9} className={'event'}>
                <Box>
                    <Typography variant={'h1'}>{title}</Typography>
                    <Typography variant="body1">{description}</Typography>
                </Box>
            </Grid>
        </Grid>
    </Button>
}

const DayScaleCell = (props) => (
    <MonthView.DayScaleCell
        {...props}
        style={{ textAlign: "center", fontWeight: "bold", backgroundColor: 'white', borderWidth: 10 }}
    />
);
