import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'; // Importa Carousel de la manera correcta
import Images from '@Constants-Project/images';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { URL_AUTOCOMPRA, LINK_VIDEO_HOME } from "@Constants-Project/constants";
import { Box, Button, Typography } from '@material-ui/core';

import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import { CarouselNews } from "@Components-Project/homeComponents/carouselNews";
import ContainerComponent from '@Components-Project/containerComponent';
import { useSelector } from 'react-redux';
import $ from "jquery";

import { FormularioNewHome } from '@Components-Project/homeComponents/homeNewCarousel/formularioNewHome/index';
import { MetasHoy } from '@Components-Project/homeComponents/homeNewCarousel/metasHoy/index';
import { CarroNuevoSeminuevo } from '@Components-Project/homeComponents/homeNewCarousel/carroNuevoSeminuevo/index';
export const HomeNewCarousel = ({ data, onClick, scroll }) => {
    // Altura para el vídeo
    const imagenRef = useRef(null); const divRef = useRef(null);
    useEffect(() => {
        const ajustarAltura = () => {
          if (imagenRef.current && divRef.current) {
            const alturaImagen = imagenRef.current.getBoundingClientRect().height;
            console.log('alturaImagen', alturaImagen);
            divRef.current.style.height = `${alturaImagen}px`;
          }
        };
    
        // Llamada a la función después de que la imagen se haya cargado
        const imagenCargada = () => {
          ajustarAltura();
          window.removeEventListener('resize', ajustarAltura);
        };
    
        // Agregar evento de carga a la imagen
        if (imagenRef.current) {
          imagenRef.current.addEventListener('load', imagenCargada);
        }
    
        // Limpieza del evento al desmontar el componente
        return () => {
          if (imagenRef.current) {
            imagenRef.current.removeEventListener('load', imagenCargada);
          }
          window.removeEventListener('resize', ajustarAltura);
        };
      }, []);

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm")); // Desde 600 px en adelante
    const isMobileScreen = useMediaQuery(theme => theme.breakpoints.down("xs")); // Menor a 600 px

    const [scrollY, setValueScrollY] = useState(0);
    React.useEffect(() => { RefFromPalmYourHand.current.style.animation = "animatCallToAction 1s ease-out" }, []);
    const homeClasses = homeStyles();
    const bStyles = buttonsStyles();
    const RefFromPalmYourHand = React.createRef();

    // Video
    const src = 'https://media.polen.com.co/documentos/video_intro_polen_desktop.mp4'; //LINK_VIDEO_HOME;
    const refVideo = useRef(null);
    const onPlay = () => { };

    //<p className="legend">Legend 1</p>
    const onChange = (currentIndex) => {
        // Implementa tu lógica cuando cambia el índice del carrusel
    };

    const onClickItem = (index, item) => {
        // Implementa tu lógica cuando se hace clic en un elemento del carrusel
    };

    const onClickThumb = (index) => {
        // Implementa tu lógica cuando se hace clic en una miniatura del carrusel
    };

    // Datos carousel letras
    const tClasses = typographiesStyles();
    const BoxTheyTrustUs = React.createRef();
    const { Post, Brands } = useSelector(state => state.parallax)

    const verticalMove = (idElement, top, minTop, start) => {
        let secPositionS6 = $('#' + idElement).position();
        let secHeight = $('#' + idElement).height();
        let a = { opacity: '0', top: top };
        if (secPositionS6 !== undefined && secPositionS6.hasOwnProperty('top')) {
            let startPoint = secPositionS6.top - (secHeight * start);
            if (scroll > startPoint) {
                a.top = 0;
                a.opacity = 1;
                let rest = top - (scroll - startPoint);
                if (rest > minTop) {
                    a.top = rest;
                    a.opacity = 1 - rest/top;
                } else {
                    a.top = 0;
                }
            }
        }
        return a;
    };
    const title = () => {
        return verticalMove('section6', 100, 0, 0.9);
    };
    const carousels = () => {
        return verticalMove('section6', 100, 0, 0.9);
    };
    /**el tiempo tiene que ser a 7seg           en meta con polen en el boon cambiar de 67px a 100px*/
    return (
        <div>
            { !isSmallScreen ?
                <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}
                    onChange={onChange} onClickItem={onClickItem} interval={7000} onClickThumb={onClickThumb}>
                        <div isSmallScreen>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_0} src={Images.ImagesCarousel.image_0} />
                        </div> 
                        <div>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_1} src={Images.ImagesCarousel.image_1} />
                        </div>
                        <div onClick={() => { window.open('https://media.polen.com.co/documentos/Cartilla_SAPAC.pdf') }}>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_2} src={Images.ImagesCarousel.image_2} />
                        </div>
                        <div>
                            <Box className={homeClasses.containerFromPalmYourHand} >
                                <ContainerComponent className={'container'}>
                                    <iframe ref={divRef} className='video'
                                            src="https://www.youtube.com/embed/9_k61QuXipQ?si=rke4-oAqO0QunfXW" 
                                            title="YouTube video player" frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                            allowfullscreen></iframe>
                                    {/*<VideoPlayer ref={refVideo} src={src} onPlay={onPlay} auto={true} mute={true} />
                                    <Button ref={RefFromPalmYourHand} className={`${bStyles.whiteButton}`} variant="contained" onClick={() => { window.open(URL_AUTOCOMPRA) }} disabled={false} >
                                        <img className={homeClasses.iconPolen} alt={Images.iconPolen} src={Images.iconPolen} /> ¡Inicia tu meta!
                                    </Button> */}
                                </ContainerComponent>
                            </Box >
                        </div>
                </Carousel>
            :
                <Carousel showArrows={false} showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true}
                    onChange={onChange} onClickItem={onClickItem} interval={7000} onClickThumb={onClickThumb}>
                        <div isSmallScreen>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_0_mobile} src={Images.ImagesCarousel.image_0_mobile} />
                        </div>
                        <div>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_1_mobile} src={Images.ImagesCarousel.image_1_mobile} />
                        </div>
                        <div onClick={() => { window.open('https://media.polen.com.co/documentos/Cartilla_SAPAC.pdf') }}>
                            <img ref={imagenRef} className="strip__content" alt={Images.ImagesCarousel.image_2_mobile} src={Images.ImagesCarousel.image_2_mobile} />
                        </div>
                        <div>
                            <Box className={'strip__content'} >
                                <ContainerComponent className={'container'}>
                                    <iframe ref={divRef} className='video'
                                        src="https://www.youtube.com/embed/9_k61QuXipQ?si=rke4-oAqO0QunfXW" 
                                        title="YouTube video player" frameborder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        allowfullscreen></iframe>
                                    {/*<VideoPlayer ref={refVideo} src={src} onPlay={onPlay} auto={true} mute={true} />
                                    <Button ref={RefFromPalmYourHand} className={`${bStyles.whiteButton}`} variant="contained" onClick={() => { window.open(URL_AUTOCOMPRA) }} disabled={false} >
                                        <img className={homeClasses.iconPolen} alt={Images.iconPolen} src={Images.iconPolen} /> ¡Inicia tu meta!
                                    </Button> */}
                                </ContainerComponent>
                            </Box >
                        </div>
                </Carousel>
            }
            <div className="inicia-tu-meta">
                <Button ref={RefFromPalmYourHand} className={`${bStyles.whiteButton} text-dark`} variant="contained" onClick={() => { window.open(URL_AUTOCOMPRA) }} disabled={false} >
                    <img className={`${homeClasses.iconPolen} mr-8px`} alt={Images.iconPolen} src={Images.iconPolen} /> ¡Inicia tu meta!
                </Button>
            </div>
            <div className="con-polen-logras col-lg-12">
                <div className="question col-lg-12">
                    <h1>¿Por qué con <span>Polen</span> la logras?</h1>
                    <div className="imagen-container">
                        <img className="imagen-desktop col-lg-12" src={Images.ImagesBeneficios.desktop} alt="Imagen para desktop" />
                        <img className="imagen-tablet col-lg-12" src={Images.ImagesBeneficios.tablet} alt="Imagen para tablet" />
                        <img className="imagen-mobile col-lg-12" src={Images.ImagesBeneficios.mobile} alt="Imagen para mobile" />
                    </div>
                </div>
                <div className="question col-lg-12">
                    <p>* Comparado con el costo financiero de un crédito con una tasa de interés mensual del 1.8% N.M.V. a 72 meses sin cuota inicial. Aplican términos y condiciones.</p>
                </div>
            </div>
            <div className="col-lg-12 que-meta-tienes-hoy contenedor-imagen p-6 pb-0">
                {/*<img className="imagen-fondo col-lg-12" src={Images.ImagesMetahoy.fondo} alt="Imagen para fondo" />*/}
                <MetasHoy scroll={scrollY}/>
            </div>
            <div className="meta-con-polen">
                <div className="question col-lg-12">
                    <p><strong>Selecciona la meta</strong> que alcanzarás con Polen:</p>
                </div>
                <CarroNuevoSeminuevo  scroll={scrollY}/>
                {/*<a href='/#' className="contenido-imagen text-center p-0">
                    <img className="imagen-desktop col-lg-12" src={Images.ImagesMetahoy.carro_nuevo} alt="Imagen para desktop" />
                    <img className="imagen-tablet col-lg-12" src={Images.ImagesMetahoy.carro_nuevo} alt="Imagen para tablet" />
                    <img className="imagen-mobile col-lg-12" src={Images.ImagesMetahoy.carro_nuevo} alt="Imagen para mobile" />
                    <div className="texto-izquierda"><h1><span className='border-botom-text'>Ca</span>rro nuevo</h1></div>
                </a>
                <a href='/#' className="contenido-imagen text-center p-0">
                    <img className="imagen-desktop col-lg-12" src={Images.ImagesMetahoy.carro_usado} alt="Imagen para desktop" />
                    <img className="imagen-tablet col-lg-12" src={Images.ImagesMetahoy.carro_usado} alt="Imagen para tablet" />
                    <img className="imagen-mobile col-lg-12" src={Images.ImagesMetahoy.carro_usado} alt="Imagen para mobile" />
                    <div className="texto-derecha"><h1><span className='border-botom-text'>Ca</span>rro usado</h1></div>
                </a> */}
            </div>
            <div className="dicen-de-nosotros"> {/* p-6 */}
                <div className='col-lg-12'>
                    <section id={'section6'} className={`${homeClasses.sixthParallax} `}>
                        <div style={title()} className={'one'} >
                            <ContainerComponent style={{ maxWidth: '1024px', width: '100%' }}>
                                <Box ref={BoxTheyTrustUs} style={{ width: '100%', marginTop: '50px' }} direction="column">
                                    <Typography variant="h1" className={`${tClasses.h136Black} TheyTrust`} >¿Qué dicen de nosotros?</Typography>
                                </Box>
                            </ContainerComponent>
                        </div>
                        <div style={carousels()} className={'two'}>
                            <ContainerComponent style={{ maxWidth: '1024px', width: '100%' }}>
                                <Box ref={BoxTheyTrustUs} style={{ width: '100%', }} direction="column">
                                    {/*Brands && Brands.length !== 0 ? <CarouselBrands data={Brands} /> : null*/}
                                    {Post && Post.length !== 0 ? <CarouselNews data={Post} /> : null}
                                </Box>
                            </ContainerComponent>
                        </div>
                    </section>
                </div>
            </div>
            <div className='mt-0'>
                <FormularioNewHome scroll={scrollY}/>
            </div>
        </div>
    )
};
