import React from 'react';
import {Grid, Typography, Box, Button, Link} from '@material-ui/core';
import Images from '@Constants-Project/images'
import { testimoniesStyles } from '@Styles-Project/theme/components/testimonies';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import ContainerComponent from '@Components-Project/containerComponent';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { Parallax } from 'react-scroll-parallax';
import '@Styles-Project/theme/layout/animations.css';
import { useSelector } from 'react-redux';
import { useMediaQuery } from "@material-ui/core";
// import Swiper core and required components
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import $ from 'jquery';
// install Swiper components
SwiperCore.use([Navigation, A11y]);

export const Testimonies = ({ scroll, onClick, RefParallax }) => {
    const { Testimony } = useSelector(state => state.parallax);
    const [ testimonySelected, setTestimonySelected] = React.useState(null);
    const homeClasses = homeStyles();
    const BoxTestimonies = React.createRef();
    const [circule, setCircule] = React.useState(0);
    const tClasses = typographiesStyles();
    const tStyles = testimoniesStyles();
    const tyStyles = typographiesStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isXsScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const generalDiv = () => {
        let a = { transition: '1s' };
        if (scroll < 4200) {
            a.height = 800;
        }
        return a;
    };

    const ptContainerText1 = () => {
        let a = { marginTop: 100 };
        if (scroll > 3900) {
            a.marginTop = 0;
            let rest = 100 - (scroll - 3900);
            if (rest > 0) a.marginTop = rest;
        }
        return a;
    };

    const ptContainerText2 = () => {
        let a = { marginTop: 150 };
        if (scroll > 3950) {
            a.marginTop = 0;
            let rest = 150 - (scroll - 3950);
            if (rest > 0) a.marginTop = rest;
            else a.marginTop = 0;
        }
        return a;
    };

    const ptContainerText3 = () => {
        let a = { marginTop: 200 };
        if (scroll > 4300) {
            a.marginTop = 0;
            let rest = 200 - (scroll - 4300);
            if (rest > 0) a.marginTop = rest;
            else a.marginTop = 0;
        }
        return a;
    };

    const ptContainerTestimonies = () => {
        let secPosition = $('#section5').position();
        let secHeight = $('#section5').height();
        let a = {
            marginTop: '200',
            justifyContent: 'center',
            maxHeight: 'auto',
            opacity: '0',
        };
        if (secPosition !== undefined && secPosition.hasOwnProperty('top')) {
            let startPoint = secPosition.top - (secHeight * 0.70);
            if (scroll > startPoint) {
                a.marginTop = 0;
                a.opacity = 1;
                let rest = 200 - (scroll - startPoint);
                if (rest > 0) {
                    a.marginTop = rest;
                    a.opacity = 1 - rest/200;
                } else {
                    a.marginTop = 0;
                }
            }
        }
        return a;
    };

    const TestimoniesLG = () => {
        return (
            <section id={'section5'} style={ ptContainerTestimonies() } className={`${homeClasses.fifthParallax} ${circule != 0 ? ' select' : ''}`} >
                <Grid container={false} ref={BoxTestimonies} container className={`${tStyles.containerT}`} direction={"row"}>
                    <Grid container={false} item xs={12} md={6} className={'containterTexts'}>
                        <Typography variant={'h1'}>En Polen unidos llegamos a donde queremos</Typography>
                        <Typography variant="body1" className={`${tyStyles.body116} ${tyStyles.marginTop20} ${'mb'} ${circule != 0 ? ' TextSelectWhite' : ''} `}>En comunidad se construye confianza, conoce a las personas que creyeron y confiaron en Polen para alcanzar sus metas.</Typography>
                        <Typography variant="body1" className={`${tyStyles.body116} ${circule != 0 ? ' TextSelectWhite' : ''}`}>Descubre cuál era su meta, y cómo a través de Polen la lograron.</Typography>
                    </Grid>
                    <Grid container={false} item xs={12} md={6} className={`${tStyles.imageContainer}`}>
                        <div>
                            {
                                !Testimony ? null :
                                    <>
                                        <Grid container item direction={"row"} className={`${tStyles.imageContainerT}`}>
                                            {
                                                circule != 0 ? <Box className={`${tStyles.showCircleContainer}`}>
                                                    <Box className={`${circule != 0 ? "" : tStyles.displayNone} ${tStyles.showCircle} ${circule != 0 ? ' showCirculeWhite' : ''} `}>
                                                        <Link onClick={() => setCircule(0)} >
                                                            <img src={Images.iconCloseBlack} />
                                                        </Link>
                                                        <Typography variant="body2" className={`name ${tClasses.textAlignLeft} ${tClasses.body118} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} ><b>{Testimony[circule - 1].fullName}</b></Typography>
                                                        <Typography variant="body2" className={`title ${tClasses.textAlignLeft} ${tClasses.body115} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} >{Testimony[circule - 1].title}</Typography>
                                                        <Typography variant="body2" className={`body ${tClasses.textAlignLeft} ${tClasses.body116} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} >{Testimony[circule - 1].description}</Typography>
                                                    </Box>
                                                </Box> : null
                                            }
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 1 ? '' :
                                                    setCircule(circule == 1 ? 0 : 1))
                                            } item xs={4} className={`imageContainerDivT ${circule == 1 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 0 ? <ImageCricle circule={{ circule, position: 0 + 1 }} testimony={Testimony[0]} myClassName={tStyles.imageBottom}></ImageCricle> : null }
                                            </Grid>
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 2 ? '' :
                                                    setCircule(circule == 2 ? 0 : 2))
                                            } item xs={4} className={`imageContainerDivT ${circule == 2 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 1 ? <ImageCricle circule={{ circule, position: 1 + 1 }} testimony={Testimony[1]} myClassName={tStyles.imageTop}></ImageCricle> : null }
                                            </Grid>
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 3 ? '' :
                                                    setCircule(circule == 3 ? 0 : 3))
                                            } item xs={4} className={`imageContainerDivT ${circule == 3 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 2 ? <ImageCricle circule={{ circule, position: 2 + 1 }} testimony={Testimony[2]} myClassName={tStyles.imageBottom}></ImageCricle> : null }
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction={"row"} className={`${tStyles.imageContainerT}`}>
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 4 ? '' :
                                                    setCircule(circule == 4 ? 0 : 4))
                                            } item xs={4} className={`imageContainerDivT ${circule == 4 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 3 ? <ImageCricle circule={{ circule, position: 3 + 1 }} testimony={Testimony[3]} myClassName={tStyles.imageTop}></ImageCricle> : null }
                                            </Grid>
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 5 ? '' :
                                                    setCircule(circule == 5 ? 0 : 5))
                                            } item xs={4} className={`imageContainerDivT ${circule == 5 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 4 ? <ImageCricle circule={{ circule, position: 4 + 1 }} testimony={Testimony[4]} myClassName={tStyles.imageBottom}></ImageCricle> : null }
                                            </Grid>
                                            <Grid container={false} onClick={() => (
                                                circule != 0 && circule != 6 ? '' :
                                                    setCircule(circule == 6 ? 0 : 6))
                                            } item xs={4} className={`imageContainerDivT ${circule == 6 ? tStyles.zIndex20 : ""}`}>
                                                { Testimony.length > 5 ? <ImageCricle circule={{ circule, position: 5 + 1 }} testimony={Testimony[5]} myClassName={tStyles.imageTop}></ImageCricle> : null }
                                            </Grid>
                                        </Grid>
                                    </>
                            }
                        </div>
                    </Grid>
                </Grid>
            </section>
        )
    };

    const TestimoniesXS = () => {
        return (
            <section id={'section5'} style={ generalDiv() } className={`${homeClasses.fifthParallax}`} >
                <ContainerComponent className={tStyles.divContainer}>
                    <Box container={false} ref={BoxTestimonies} container className={`${tStyles.containerT}`} direction={"row"}>
                        <Grid container={false} item xs={12} md={6}>
                            <div style={!isSmallScreen ? ptContainerText1() : {}} className={'containterTexts'} >
                                <Typography variant="h1" className={`${tyStyles.h136Black}`}>En Polen unidos llegamos a donde queremos</Typography>
                            </div>
                            <div style={!isSmallScreen ? ptContainerText2() : {}}  >
                                <Typography variant="body1" className={`${tyStyles.body116} ${'mb'}`}>En comunidad se construye confianza, conoce a las personas que creyeron y confiaron en Polen para alcanzar sus metas.</Typography>
                                <Typography variant="body1" className={`${tyStyles.body116}`}>Descubre cual era su meta, y como a tráves de Polen la lograron cumplir.</Typography>
                            </div>
                        </Grid>
                        <Box className={'mobileTestimony'}>
                            {
                                !Testimony ? null :
                                    <Swiper
                                        spaceBetween={0}
                                        slidesPerView={ isXsScreen ? 2 : 3 }
                                        navigation
                                        onSwiper={() => {  }}
                                        onSlideChange={() => {  }}
                                    >
                                        {Testimony.map((val, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <ImageCricle circule={{ circule, position: index + 1 }} testimony={val} myClassName={tStyles.imageCarrousel} onClick={() => {setTestimonySelected(circule === index + 1 ? null : val); setCircule(circule === index + 1 ? 0 : index + 1)}}></ImageCricle>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                            }
                            {
                                !testimonySelected ? null :
                                    <div className={'showTestimony'}>
                                        <Link onClick={() => { setTestimonySelected(null); setCircule(0) } } >
                                            <img src={Images.iconClose} alt={'close'} />
                                        </Link>
                                        <Typography variant={'h1'} className={`name ${tClasses.textAlignLeft} ${tClasses.body118} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} ><b>{testimonySelected.fullName}</b></Typography>
                                        <Typography variant={'h2'} className={`title ${tClasses.textAlignLeft} ${tClasses.body115} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} >{testimonySelected.title}</Typography>
                                        <Typography variant="body1" className={`body ${tClasses.textAlignLeft} ${tClasses.body116} ${tClasses.colorWhite} ${circule != 0 ? ' TextSelectBlack' : ''}`} >{testimonySelected.description}</Typography>
                                    </div>
                            }
                        </Box>
                    </Box>
                </ContainerComponent>
            </section>
        )
    };

    return (
        <>
            { !isSmallScreen ? TestimoniesLG() : TestimoniesXS() }
        </>
    )
};

export function ImageCricle(testimony) {
    const tStyles = testimoniesStyles();
    const tyStyles = typographiesStyles();
    const t = testimony.testimony;
    let circule = testimony.circule
    return <Box className={`${testimony.myClassName}`} onClick={testimony.onClick}>
        <img alt={t.image} src={t.image} />
        {(circule.circule) !== (circule.position) ? <Box className={`${tStyles.circleSepia}`}></Box> : null}
        <Typography variant={'h1'} className={`${tyStyles.h118White} ${tStyles.title} click ${circule.circule === circule.position ? 'black' : ''}`}>{t.name}</Typography>
    </Box>
}
