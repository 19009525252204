import { types } from '../types/types';

export const talkUsReducer = (state = {}, action) => {
    switch (action.type) {
        case types.setAllFAQsActive:
            return {
                ...state,
                AllFAQsActive: action.payload.AllFAQsActive
            };

        case types.setPQRTypes:
            return {
                ...state,
                PQRTypes: action.payload.PQRTypes
            };

        default:
            return state;
    }
};
