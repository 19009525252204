import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { HelpDesktop, HelpMobile } from '@Components-Project/helpComponent';
import { ImageGoal } from '@Components-Project/homeComponents/imageGoals/index';
import { PolenOnYourHand } from '@Components-Project/homeComponents/polenOnYourHand/index';
import { FromPalmYourHand } from '@Components-Project/homeComponents/fromPalmYourHand/index';
import { StartYourStory } from '@Components-Project/homeComponents/startYourStory/index';
import { Testimonies } from "@Components-Project/homeComponents/testimonies/index";
import { WithPollenAchievesGoals } from "@Components-Project/homeComponents/withPollenAchievesGoals/index";
import { CarouselBrands } from "@Components-Project/homeComponents/carouselBrands";
import { CarouselNews } from "@Components-Project/homeComponents/carouselNews";
import { Box, Typography, } from '@material-ui/core';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import ContainerComponent from '@Components-Project/containerComponent';
import { useOnScreen } from '@Functions-Project/generals';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { stepsStyles } from '@Styles-Project/theme/layout/steps/steps';
import { useMediaQuery } from "@material-ui/core";
import $ from "jquery";
import { URL_AUTOCOMPRA } from "@Constants-Project/constants";

import { HomeNewCarousel } from '@Components-Project/homeComponents/homeNewCarousel/index';
export default function HomeComponentsMovil({ open, handleClose }) {

    const [scrollY, setValueScrollY] = useState(0);
    const [scrollYEvent, setValueScrollYEvent] = useState(null);
    const { Post, Brands, Testimony } = useSelector(state => state.parallax);
    const stepClasses = stepsStyles();
    const homeClasses = homeStyles();
    const tClasses = typographiesStyles();

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight } = event.target.scrollingElement;
        setValueScrollY(scrollTop)
        setValueScrollYEvent(event)
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);
    /*HOME   <FromPalmYourHand scroll={scrollY} /> */
    return (
        <>
            <div className={`${homeClasses.ContaintParallax}`}>
                <Help open={open} handleClose={handleClose} />
                <HomeNewCarousel  scroll={scrollY} />
                {/*<FromPalmYourHand scroll={scrollY} />
                <StartYourStory scroll={scrollY} />
                <SelectTheGoalYouWillAchieveWithPollen scroll={scrollY} />
                <Box direction="row" justify="center" alignItems="center" >
                    <Typography variant="h1" className={`${tClasses.h136BlackH} ${tClasses.mb40} ${tClasses.hauto}`}>Polen es la comunidad en la que logras metas que<br/>parecían inalcanzables
                    </Typography>
                </Box>
                <WithPollenAchievesGoals scroll={scrollY} />*/}
                { Testimony && Testimony.length !== 0 ? <Testimonies scroll={scrollY} /> : null }
                {/*
                { (Post && Post.length !== 0) || (Brands && Brands.length !== 0) ? <TheyTrustUs scroll={scrollY} /> : null }
                <PolenOnYourHand scrollYEvent={scrollYEvent} scroll={scrollY} />*/}
            </div>
        </>
    )
}

export function Help({ handleClose, open }) {
    const homeClasses = homeStyles();
    const RefHelpDesktop = React.createRef();
    useEffect(() => { RefHelpDesktop.current.style.animation = "animatHelpDesktop 1s ease-out" }, []);
    return <div className={`${homeClasses.helpParallax}`}>
        <ContainerComponent style={{ justifyContent: "flex-end", maxWidth: '1024px' }}>
            <Box ref={RefHelpDesktop} className={`${homeClasses.divHelp} `}> <HelpDesktop open={open} handleClose={handleClose} /> </Box>
        </ContainerComponent>
    </div>
}

export function SelectTheGoalYouWillAchieveWithPollen({ scroll }) {
    const tClasses = typographiesStyles();
    const reff = React.useRef();
    const onScreen = useOnScreen(reff, '-100px');
    const { goal } = useSelector(state => state.global);
    const [goalActive, setGoalActive] = useState(goal || false);
    const onClickGoal = ({ target }) => {
        setGoalActive(true);
        var win = window.open(URL_AUTOCOMPRA, '_blank');
        win.focus();
    };
    const homeClasses = homeStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return <div className={`${homeClasses.thirdyParallax}`}>
        <Box direction="row" justify="center" alignItems="center" >
            <Typography variant="h1" className={`${tClasses.h136Black}`}>Selecciona la meta que alcanzarás con Polen</Typography>
        </Box>
        <Box ref={reff} direction="row" justify="center" alignItems="center" >
            {
                !isSmallScreen ? (onScreen ? <ImageGoal onClick={onClickGoal} /> : null)
                    : <ImageGoal onClick={onClickGoal} />
            }
        </Box>
    </div>
}

export function TheyTrustUs({ scroll }) {

    const homeClasses = homeStyles();
    const tClasses = typographiesStyles();
    const BoxTheyTrustUs = React.createRef();
    const { Post, Brands } = useSelector(state => state.parallax)

    const verticalMove = (idElement, top, minTop, start) => {
        let secPositionS6 = $('#' + idElement).position();
        let secHeight = $('#' + idElement).height();
        let a = { opacity: '0', top: top };
        if (secPositionS6 !== undefined && secPositionS6.hasOwnProperty('top')) {
            let startPoint = secPositionS6.top - (secHeight * start);
            if (scroll > startPoint) {
                a.top = 0;
                a.opacity = 1;
                let rest = top - (scroll - startPoint);
                if (rest > minTop) {
                    a.top = rest;
                    a.opacity = 1 - rest/top;
                } else {
                    a.top = 0;
                }
            }
        }
        return a;
    };

    const title = () => {
        return verticalMove('section6', 100, 0, 0.9);
    };

    const carousels = () => {
        return verticalMove('section6', 100, 0, 0.9);
    };

    return <section id={'section6'} className={`${homeClasses.sixthParallax} `}>
        <div style={ title() } className={'one'} >
            <ContainerComponent style={{ maxWidth: '1024px', width: '100%' }}>
                <Box ref={BoxTheyTrustUs} style={{ width: '100%', marginTop: '50px' }} direction="column">
                    <Typography variant="h1" className={`${tClasses.h136Black} TheyTrust`} >Ellos confían en nosotros</Typography>
                </Box>
            </ContainerComponent>
        </div>

        <div style={ carousels() } className={'two'}>
            <ContainerComponent style={{ maxWidth: '1024px', width: '100%' }}>
                <Box ref={BoxTheyTrustUs} style={{ width: '100%', }} direction="column">
                    { Brands && Brands.length !== 0 ? <CarouselBrands data={Brands} /> : null }
                    { Post && Post.length !== 0 ? <CarouselNews data={Post} /> : null }
                </Box>
            </ContainerComponent>
        </div>

    </section>
}
