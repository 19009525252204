import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Images from '@Constants-Project/images';
import {
    Menu, MenuItem, IconButton, Toolbar, AppBar, Container, Box, Typography, Link as LinkMaterial
} from '@material-ui/core';
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import { headerStyles } from '@Styles-Project/theme/layout/header';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { URL_ALIADOS, URL_AUTOATENCION, URL_AUTOCOMPRA } from '@Constants-Project/constants';
import { withStyles } from '@material-ui/core/styles';

const mobileMenuId = 'menu-mobile-polen';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid rgba(254,91,2,1)',
        marginTop: '5px',
        minWidth: '180px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


export default () => {

    const hClasses = headerStyles();
    const bClasses = buttonsStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const FancyLink = React.forwardRef((props) => {
        return <LinkMaterial style={{ textDecoration:'none' }} color="inherit" href={props.ref} {...props} >{props.children}</LinkMaterial>
    })

    const FancyLink2 = React.forwardRef((props) => {
        return <LinkMaterial color="inherit" href={props.ref} {...props} >{props.children}</LinkMaterial>
    })

    function HeaderView(route) {
        const location = useLocation();
        return location.pathname.includes(route)
    }

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            className={`${hClasses.headerContainer} test`}>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`} >
                <Link to="/que-es-polen" component={FancyLink}><Typography variant={'body1'}>¿Qué es Polen?</Typography></Link>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`} >
                <Link to="/eventos" component={FancyLink}><Typography variant={'body1'}>Eventos</Typography></Link>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`} >
                <Link to="/hablanos/1" component={FancyLink}><Typography variant={'body1'}>Háblanos</Typography></Link>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`}  target={'_blank'}>
                <LinkMaterial href={URL_AUTOCOMPRA} className={`${hClasses.clickLink}`} color="inherit">
                    <Typography className={`${hClasses.clickLink}`} variant={'body1'}>Simula tu plan</Typography>
                </LinkMaterial>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`}  target={'_blank'}>
                <LinkMaterial href={URL_AUTOATENCION} className={`${hClasses.clickLink}`} color="inherit">
                    <Typography className={`${hClasses.clickLink}`} variant={'body1'}>Clientes</Typography>
                </LinkMaterial>
            </MenuItem>

            <MenuItem onClick={handleMobileMenuClose} className={`${hClasses.clickLink}`}  target={'_blank'}>
                <LinkMaterial href={URL_ALIADOS} className={`${hClasses.clickLink}`} color="inherit">
                    <Typography className={`${hClasses.clickLink}`} variant={'body1'}>Aliados</Typography>
                </LinkMaterial>
            </MenuItem>

        </Menu>
    );

    return (
        <Box>
            <AppBar position="static" color="white" className={hClasses.headerContainer}>
                <Container>
                    <Toolbar>
                        <div className={hClasses.logo} >
                            <Link to="/"><img alt={Images.logoHeader} src={Images.logoHeader} className={hClasses.logo_image} /></Link>
                        </div>
                        <div className={hClasses.sectionDesktopLink}>
                            <Link to="/que-es-polen" component={FancyLink}><Typography className={`${HeaderView('que-es-polen') ? hClasses.textOrange : hClasses.textBase}`} variant="body1">¿Qué es Polen?</Typography></Link>
                            <Link to="/eventos" component={FancyLink}><Typography className={`${HeaderView('eventos') ? hClasses.textOrange : hClasses.textBase}`} variant={'body1'}>Eventos</Typography></Link>
                            <Link to="/hablanos/1" component={FancyLink}><Typography className={`${HeaderView('hablanos') ? hClasses.textOrange : hClasses.textBase}`} variant={'body1'}>Háblanos</Typography></Link>
                        </div>
                        <div className={hClasses.sectionDesktop}>

                            <LinkMaterial href={URL_AUTOCOMPRA} target={'_blank'}>
                                <Button className={`${bClasses.button} ${bClasses.orangeButton} ${hClasses.mr21}`}>Simula tu plan</Button>
                            </LinkMaterial>
                            <Button onClick={handleClick} className={`${bClasses.buttonTypeDropDown}`}>Ingresa a tu cuenta</Button>

                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} className={`${hClasses.clickLink}`} >
                                    <LinkMaterial href={URL_AUTOATENCION} className={`${hClasses.clickLink} ${bClasses.clickLinkInsideDropDown}`} color="inherit">
                                        <Typography className={`${hClasses.clickLinkInsideDropDown}`} variant={'body1'}>Clientes</Typography>
                                    </LinkMaterial>
                                </MenuItem>
                                <MenuItem onClick={handleClose} className={`${hClasses.clickLink}`} >
                                    <LinkMaterial href={URL_ALIADOS} className={`${hClasses.clickLink} ${bClasses.clickLinkInsideDropDown}`} color="inherit">
                                        <Typography className={`${hClasses.clickLinkInsideDropDown}`} variant={'body1'}>Aliados</Typography>
                                    </LinkMaterial>
                                </MenuItem>
                            </StyledMenu>

                        </div>
                        <div className={hClasses.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MenuIcon style={{ fontSize: 40 }} />
                            </IconButton>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            {renderMobileMenu}
        </Box>
    )
}
