const Images = {
    logoHeader: require('@Assets-Project/images/logo-vigilado.svg'),
    logo: require('@Assets-Project/images/logo.svg'),
    ArrowUp: require('@Assets-Project/images/arrowUp.png'),
    ArrowDown: require('@Assets-Project/images/arrowDown.png'),
    IconSupport: require('@Assets-Project/images/chatA.svg'),
    IconChat: require('@Assets-Project/images/iconChat.svg'),
    IconPhone: require('@Assets-Project/images/iconPhone.svg'),
    ImagesCarousel: {
        image_0: require('@Assets-Project/images/carousel/bhome-polen-5.jpg'),
        image_1: require('@Assets-Project/images/carousel/banner-carronuevo-d.jpg'),
        image_0_mobile: require('@Assets-Project/images/carousel/bhome-moible-polen-5.jpg'),
        image_1_mobile: require('@Assets-Project/images/carousel/banner-carronuevo-m.jpg'),
        image_2: require('@Assets-Project/images/carousel/bhome-polen-3.jpg'),
        image_2_mobile: require('@Assets-Project/images/carousel/bhome-moible-polen-3.jpg')
    },
    ImagesFondosProducto: {
        // 2000x1100
        //nuevo_close_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/N-1.jpg'),
        //nuevo_close_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/N-2.jpg'),
        nuevo_close_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/fondo_auto_nuevo_show.jpg'),
        nuevo_open_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/N-2.jpg'),
        //seminuevo_close_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/SN-1.jpg'),
        seminuevo_close_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/fondo_carro_usado_desktop.jpg'),
        seminuevo_open_desk: require('@Assets-Project/images/fondos_producto/2000x1100 px/SN-2.jpg'),
        // 800x620-tablet
        nuevo_close_tablet: require('@Assets-Project/images/fondos_producto/800x620-tablet/N1.jpg'),
        nuevo_open_tablet: require('@Assets-Project/images/fondos_producto/800x620-tablet/N1.jpg'),
        seminuevo_close_tablet: require('@Assets-Project/images/fondos_producto/800x620-tablet/SN1.jpg'),
        seminuevo_open_tablet: require('@Assets-Project/images/fondos_producto/800x620-tablet/SN1.jpg'),
        // Mobile
        //nuevo_close_mobile: require('@Assets-Project/images/fondos_producto/700x600px/carro-nuevo-short.jpg'),
        nuevo_close_mobile: require('@Assets-Project/images/fondos_producto/700x600px/background_test_autonuevo_m.jpg'),
        nuevo_open_mobile: require('@Assets-Project/images/fondos_producto/700x600px/background_test_autonuevo_m.jpg'),
        //seminuevo_close_mobile: require('@Assets-Project/images/fondos_producto/700x600px/carro-usado-short.jpg'),
        seminuevo_close_mobile: require('@Assets-Project/images/fondos_producto/700x600px/background_carro_usado_m.jpg'),
        seminuevo_open_mobile: require('@Assets-Project/images/fondos_producto/700x600px/background_carro_usado_m.jpg'),
    },
    ImagesBeneficios: {
        desktop: require('@Assets-Project/images/beneficios/beneficios_desktop.png'),
        tablet: require('@Assets-Project/images/beneficios/beneficios_tablet.png'),
        mobile: require('@Assets-Project/images/beneficios/beneficios_mobile.png')
    },
    ImagesMetahoy: {
        desktop: require('@Assets-Project/images/metas/cars_desktop.png'),
        tablet: require('@Assets-Project/images/metas/cars_tablet.png'),
        mobile1: require('@Assets-Project/images/metas/cars_mobile.png'),
        //mobile: require('@Assets-Project/images/metas/cars_mobilev2.png'),
        mobile: require('@Assets-Project/images/metas/icono-autos-m.png'),
        icon: require('@Assets-Project/images/metas/icon.png'),
        fondo: require('@Assets-Project/images/metas/fondo.png'),
        carro_nuevo: require('@Assets-Project/images/metas/carro_nuevo.jpg'),
        carro_usado: require('@Assets-Project/images/metas/carro_usado.jpg'),
    },
    InComunityYouAchieveParallax: {
        // oneBall: require('@Assets-Project/images/oneBall.svg'),
        oneBall: require('@Assets-Project/images/oneBall.png'),
        // twoBall: require('@Assets-Project/images/twoBall.svg'),
        twoBall: require('@Assets-Project/images/twoBall.png'),
        threeBall: require('@Assets-Project/images/threeBall.svg'),
        fourBall: require('@Assets-Project/images/fourBall.svg'),
        firstPerson: require('@Assets-Project/images/firstPerson.png'),
        secondPerson: require('@Assets-Project/images/secondPerson.png'),
        thirdPerson: require('@Assets-Project/images/thirdPerson.png'),
        quarterPerson: require('@Assets-Project/images/quarterPerson.png'),
        isSmallImage: require('@Assets-Project/images/people-up.png'),
    },
    vehiculo: require('@Assets-Project/images/car.svg'),
    trip: require('@Assets-Project/images/travel.svg'),
    motorBike: require('@Assets-Project/images/motorcycle.svg'),
    celPhone: require('@Assets-Project/images/phone.svg'),
    house: require('@Assets-Project/images/house.svg'),
    iconCircleCar: {
        active: require('@Assets-Project/images/carActive.svg'),
        inactive: require('@Assets-Project/images/car.svg'),
    },
    iconCircleTravel: {
        active: require('@Assets-Project/images/travelActive.svg'),
        inactive: require('@Assets-Project/images/travel.svg'),
    },
    iconCircleBike: {
        active: require('@Assets-Project/images/motorcycleActive.svg'),
        inactive: require('@Assets-Project/images/motorcycle.svg'),
    },
    iconCircleMobile: {
        active: require('@Assets-Project/images/phoneActive.svg'),
        inactive: require('@Assets-Project/images/phone.svg'),
    },
    iconCircleHouse: {
        active: require('@Assets-Project/images/houseActive.svg'),
        inactive: require('@Assets-Project/images/house.svg'),
    },
    imageHomecenter: require('@Assets-Project/images/homecenter.jpeg'),
    iconVehiculo: require('@Assets-Project/images/iconVehiculo.svg'),
    iconTrip: require('@Assets-Project/images/iconTrip.svg'),
    iconMotorBike: require('@Assets-Project/images/iconMotorBike.svg'),
    iconCelPhone: require('@Assets-Project/images/iconCelPhone.svg'),
    iconHouse: require('@Assets-Project/images/iconHouse.svg'),
    downloadIos: require('@Assets-Project/images/downloadIos.svg'),
    downloadAndroid: require('@Assets-Project/images/downloadAndroid.svg'),
    arrowDownWithDot: require('@Assets-Project/images/arrowDownWithDot.svg'),
    iconPolen: require('@Assets-Project/images/iconPolen.svg'),
    profileCirculeSantiago: require('@Assets-Project/images/profileCirculeSantiago.png'),
    profileCirculeDaniela: require('@Assets-Project/images/profileCirculeDaniela.png'),
    profileCirculeCamilo: require('@Assets-Project/images/profileCirculeCamilo.png'),
    profileCirculeSantiago2: require('@Assets-Project/images/profileCirculeSantiago2.png'),
    profileCirculeCarolina: require('@Assets-Project/images/profileCirculeCarolina.png'),
    profileCirculeEstefania: require('@Assets-Project/images/profileCirculeEstefania.png'),
    whatIsPolenFirstImage: require('@Assets-Project/images/whatIsPolenFirstImage.png'),
    differenceImage1: require('@Assets-Project/images/differenceImage1.png'),
    differenceImage2: require('@Assets-Project/images/differenceImage2.png'),
    differenceImage3: require('@Assets-Project/images/differenceImage3.png'),
    differenceImage4: require('@Assets-Project/images/differenceImage4.png'),
    iconProfileWithDot: require('@Assets-Project/images/iconProfileWithDot.svg'),
    iconHeartWithDot: require('@Assets-Project/images/iconHeartWithDot.svg'),
    paperWithPen: require('@Assets-Project/images/paperWithPen.png'),
    phoneBig: require('@Assets-Project/images/phoneBig.png'),
    logoPolenFlip: require('@Assets-Project/images/logoPolenFlip.png'),
    arrowRightWithDot: require('@Assets-Project/images/arrowRightWithDot.svg'),
    iconPolenWhite: require('@Assets-Project/images/iconPolenWhite.svg'),
    closeBlack: require('@Assets-Project/images/closeBlack.svg'),
    bannerExapainTripView: {
        short: require('@Assets-Project/images/bannerExapainTripViewShort.png'),
        large: require('@Assets-Project/images/bannerExapainTripView.png'),
    },
    bannerExapainHome: {
        short: require('@Assets-Project/images/bannerExapainHomeShort.png'),
        large: require('@Assets-Project/images/bannerExapainHome.png'),
    },
    bannerExapainPeople: {
        short: require('@Assets-Project/images/bannerExapainPeopleShort.png'),
        large: require('@Assets-Project/images/bannerExapainPeople.png'),
        people: require('@Assets-Project/images/people.png'),
    },
    bannerExapainGirlOnCar: {
        short: require('@Assets-Project/images/bannerExapainGirlOnCarShort.png'),
        large: require('@Assets-Project/images/bannerExapainGirlOnCar.png'),
    },
    bannerExapainMenDriveBike: {
        short: require('@Assets-Project/images/bannerExapainMenDriveBikeShort.png'),
        large: require('@Assets-Project/images/bannerExapainMenDriveBike.png'),
    },
    bannerExapainGirlListeningMusic: {
        short: require('@Assets-Project/images/bannerExapainGirlListeningMusicShort.png'),
        large: require('@Assets-Project/images/bannerExapainGirlListeningMusic.png'),
    },

    // **************
    pc_window: require('@Assets-Project/images/pc_window.png'),
    pc_purchasePlan: require('@Assets-Project/images/pc_purchasePlan.png'),
    pc_participle: require('@Assets-Project/images/pc_participle.png'),
    pc_enjoy: require('@Assets-Project/images/pc_enjoy.png'),
    // **************

    bubbleSeparator: require('@Assets-Project/images/separator.png'),
    womanA: require('@Assets-Project/images/womanA.png'),
    imgHeaderSchedule: require('@Assets-Project/images/img_header_schedule.png'),
    imgHeaderScheduleNew: require('@Assets-Project/images/new_images/banner-eventos.jpg'),
    notch: require('@Assets-Project/images/nocth.svg'),
    group1073: require('@Assets-Project/images/group-1073.png'),
    imageInComunityAchive: require('@Assets-Project/images/imageInComunityAchive.png'),
    arrowAnimation: require('@Assets-Project/images/arrowAnimation.svg'),
    vectorOnPhone: require('@Assets-Project/images/vectorOnPhone.svg'),
    celPhoneMoving: require('@Assets-Project/images/celPhoneMoving.gif'),
    vectorBackPersons: require('@Assets-Project/images/vectorBackPersons.png'),
    vectorCutOnText: require('@Assets-Project/images/vectorCutOnText.png'),
    vectorMiniOnText: require('@Assets-Project/images/vectorMiniOnText.png'),
    vectorMiniBackText: require('@Assets-Project/images/vectorMiniBackText.png'),
    rowDown: require('@Assets-Project/images/rowDown.png'),
    superintendenciadesociedades: require('@Assets-Project/images/superintendenciadesociedades.svg'),
    superintendenciadesociedadesHeader: require('@Assets-Project/images/logo-superintendencia-header.svg'),
    logoFooter: require('@Assets-Project/images/logoFooter.svg'),
    instagram: require('@Assets-Project/images/instagram.svg'),
    facebook: require('@Assets-Project/images/facebook.svg'),
    youtube: require('@Assets-Project/images/youtube.svg'),
    whatsapp: require('@Assets-Project/images/icon-whatsapp.svg'),
    titktok: require('@Assets-Project/images/titktok.svg'),
    iconClose: require('@Assets-Project/images/iconClose.svg'),
    iconCloseBlack: require('@Assets-Project/images/iconCloseBlack.png'),
    doingDoforYouImage1: require('@Assets-Project/images/doingDoforYouImage1.png'),
    doingDoforYouImage2: require('@Assets-Project/images/doingDoforYouImage2.png'),
    doingDoforYouImage3: require('@Assets-Project/images/doingDoforYouImage3.png'),
    doingDoforYouImage4: require('@Assets-Project/images/doingDoforYouImage4.png'),
    groupFriends: require('@Assets-Project/images/groupFriends.png'),
    phoneStatic: require('@Assets-Project/images/phoneStatic.png'),
    correctSent: require('@Assets-Project/images/correctSent.svg'),
    errorSent: require('@Assets-Project/images/errorSent.svg'),
    loader: require('@Assets-Project/images/loader.gif'),
}

export default Images;
