import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import ReactTypingEffect from 'react-typing-effect';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import convert from "htmr";
import Marquee from 'react-double-marquee';

export default function PolenIs({ open, handleClose }) {

    const wipStyles = whatIsPolenStyles();
    const tClasses = typographiesStyles();
    // const arryWords = ["Comunidad", "Planes", "Metas", "Ahorros", "Inversion"];
    const arryWords = ["Compra programada", "Comunidad", "Metas", "Confianza", "Acceso", "Flexibilidad", "Digital", "Seguro"];
    const [wordChange, setWordChange] = React.useState('Comunidad');

    return (
        <Box className={wipStyles.h800}>
            <Box className={wipStyles.bgGray}>
                <ContainerComponent className={'divContainer'} style={{}}>
                    <Typography className={`${tClasses.textAlignLeft}`} variant={'h1'}>Polen es <ReactTypingEffect className={tClasses.body1Primary} speed={60} eraseDelay={800} text={arryWords} /> </Typography>
                </ContainerComponent>
            </Box>
            <ContainerComponent className={wipStyles.headerImage}>
                <Box className={'divText'}>
                    <Box className={'lineText'}>
                        <Marquee direction={'left'} speed={'0.03'} delay={'0'}>Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro </Marquee>
                    </Box>
                    <Box className={'lineText'}>
                        <Marquee direction={'right'} speed={'0.03'} delay={'0'}>Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada </Marquee>
                    </Box>
                    <Box className={'lineText'}>
                        <Marquee direction={'left'} speed={'0.03'} delay={'0'}>Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro </Marquee>
                    </Box>
                    <Box className={'lineText'}>
                        <Marquee direction={'right'} speed={'0.03'} delay={'0'}>Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada Confianza Acceso Metas Flexibilidad Digital Seguro Comunidad Compra programada </Marquee>
                    </Box>
                    <Box className={'lineText'}>
                        <Marquee direction={'left'} speed={'0.03'} delay={'0'}>Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro Compra programada Comunidad Metas Confianza Acceso Flexibilidad Digital Seguro </Marquee>
                    </Box>

                    {/* <Typography className={'textMoveToLeft'} variant="body1">Familia  Metas Ahorro Comunidad Planes  Familia  Metas Compra Programada Comunidad Inversion Planes Familia  Metas Ahorro Comunidad Planes  Familia  Metas Compra Programada Comunidad Inversion Planes</Typography>
                    <Typography className={'textMoveToLeft positionAbsolute'} variant="body1">Familia  Metas Ahorro Comunidad Planes  Familia  Metas Compra Programada Comunidad Inversion Planes Familia  Metas Ahorro Comunidad Planes  Familia  Metas Compra Programada Comunidad Inversion Planes</Typography> */}
                </Box>
                <img className={'animationFirstGirl'} alt={Images.whatIsPolenFirstImage} src={Images.whatIsPolenFirstImage} />

            </ContainerComponent>

        </Box>
    );
}
