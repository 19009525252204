import React from 'react';
import { Box } from '@material-ui/core';
import Header from "@Components-Project/scheduleComponents/header";
import EventTimer from "@Components-Project/scheduleComponents/eventTimer";
import ScheduleComp from "@Components-Project/scheduleComponents/scheduleComp";
import { Help } from "@Components-Project/homeComponents";
import { useSelector } from 'react-redux';

export default function ScheduleComponent({ open, handleClose }) {
    const { Meeting } = useSelector(state => state.meeting)
    const [formDateTarget, setFormDateTarget] = React.useState(false);
    const findMoreNear = async () => {
        if (Meeting) {
            let tempMoreNear = await Meeting.filter((item) => item.moreNear);
            setFormDateTarget(tempMoreNear[0])
        }
    }
    React.useEffect(() => {
        findMoreNear();
    }, [Meeting]);

    return (
        <Box style={{ position: 'relative', paddingTop: '85px' }} >
            <Help open={open} handleClose={handleClose} />
            <Header />
            <EventTimer formDateTarget={formDateTarget} />
            <ScheduleComp setDateTarget={(data) => setFormDateTarget(data)} />
        </Box>
    );
}
