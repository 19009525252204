// https://codepen.io/ettrics/pen/ZYqKGb
// https://codepen.io/tommydunn/pen/WEZNLq
import React from 'react';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { Box, Grid, Button, Typography, TextField, FormControlLabel, Checkbox, Select, MenuItem, Link } from '@material-ui/core';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import { inputStyles } from '@Styles-Project/theme/components/inputs';
import Images from '@Constants-Project/images';
import './index.scss';
import ContainerComponent from '@Components-Project/containerComponent';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { useMediaQuery } from "@material-ui/core";
import { stripStyles } from '@Styles-Project/theme/components/strips';
import $ from 'jquery';
import ScriptTag from 'react-script-tag';
import {
    FORM_BITRIX24,
    URL_AUTOCOMPRA,
    DATA_PLANS,
    LEGALES_TYC,
    LEGALES_INFO,
    LEGALES_CONDITIONS
} from '@Constants-Project/constants';
import api from "@Constants-Project/api";
import { Alert } from "@Components-Project/alert";


export function CarroNuevoSeminuevo({ scroll }) {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const isSmallScreenMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const homeClasses = homeStyles();
    const bStyles = buttonsStyles();
    const inputsClasses = inputStyles();
    const stStyles = stripStyles();
    const tStyles = typographiesStyles();

    const [animationsBox, setanimationsBox] = React.useState({});
    const { box1, box2, box3, box4, box5, box6 } = animationsBox;
    const [formState, SetFormState] = React.useState({ names: '', lastNames: '', email: '', celPhone: '', plan: '' });
    const { names, lastNames, email, celPhone, plan } = formState;
    const [formValid, setFormValid] = React.useState(false);
    const [dataAlert, setDataAlert] = React.useState({ type: '', title: '', message: '', showInput: false })
    const { type, title, message, showInput } = dataAlert;

    const isFormValid = React.useCallback(() => {
        if (!names) return false;
        if (!lastNames) return false;
        if (!email) return false;
        if (!celPhone) return false;
        if (!plan) return false;
        return true;
    }, [names, lastNames, email, celPhone, plan]);

    React.useEffect(() => {
        (isFormValid()) ? setFormValid(true) : setFormValid(false);
    }, [isFormValid]);

    const InputProps = {
        className: inputsClasses.input,
        disableUnderline: true,
    };

    const openBox = (position) => {
        setanimationsBox({
            ...animationsBox,
            [`box${position}`]: !animationsBox[`box${position}`]
        })
    };

    const closeBox = (event) => {
        setanimationsBox({})
    };

    const isAnyOneVisible = () => box1 || box2 || box3 || box4 || box5 || box6;
    const closeBoxM1 = () => {
        console.log('111');
        let src_img_nuevo = !isSmallScreen ? Images.ImagesFondosProducto.nuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.nuevo_close_mobile : Images.ImagesFondosProducto.nuevo_close_tablet;
        $('#img_nuevo').prop('src', src_img_nuevo);

        $('.option.2.show').addClass('hide');
        $('.option_close.1').removeClass('show');
        $('.option.1').removeClass('show');
        setTimeout(() => {
            $('.option.1.hide').removeClass('hide');
            $('.options.1').removeClass('extends');
        }, 800);
    };
    const closeBoxM2 = () => {
        console.log('222');
        let src_img_usado = !isSmallScreen ? Images.ImagesFondosProducto.seminuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.seminuevo_close_mobile : Images.ImagesFondosProducto.seminuevo_close_tablet;
        $('#img_usado').prop('src', src_img_usado);

        $('.option.1.show').addClass('hide');
        $('.option_close.2').removeClass('show');
        $('.option.2').removeClass('show');
        setTimeout(() => {
            $('.option.2.hide').removeClass('hide');
            $('.options.2').removeClass('extends');
        }, 800);
    };
    {/* const closeBoxM = () => {
        $('.option.show').addClass('hide');
        $('.option_close').removeClass('show');
        $('.option').removeClass('show');
        setTimeout(() => {
            $('.option.hide').removeClass('hide');
            $('.options').removeClass('extends');
        }, 800);
    }; */}
    const openBoxM = (id) => {
        /*if(id == 1){
            console.log('111');
            let src_img_nuevo = !isSmallScreen ? Images.ImagesFondosProducto.nuevo_open_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.nuevo_open_mobile : Images.ImagesFondosProducto.nuevo_open_tablet;
            $('#img_nuevo').prop('src', src_img_nuevo);
        } else {
            console.log('222');
            let src_img_usado = !isSmallScreen ? Images.ImagesFondosProducto.seminuevo_open_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.seminuevo_open_mobile : Images.ImagesFondosProducto.seminuevo_open_tablet;
            $('#img_usado').prop('src', src_img_usado);
        }*/
        $('.option.' + id).removeClass('show');
        $('#' + id + '.option').addClass('show');
        $('.option_close.' + id).addClass('show');
        /*if (id === 6) {
            $('.options').addClass('extends');
        }*/
    };

    const handleInputChange = ({ target }) => {
        SetFormState({
            ...formState,
            [target.name]: target.value
        });
        isFormValid();
    };

    const handleInputChangePhone = ({ target }) => {
        if (target.value.length <= 10 && (Math.sign(target.value) != -1 || target.value == 0)) SetFormState({
            ...formState,
            [target.name]: target.value
        });
        isFormValid();
    };

    const handleChange = (event) => {
        SetFormState({
            ...formState,
            [event.target.name]: event.target.value
        })
        isFormValid();
    };

    const handleSendData = async (e) => {
        e.preventDefault();
        setDataAlert({ type: 'Loader', title: 'Estamos registrando tu información', message: '', showInput: true });
        let similares = {
            'select[1]': 'ID',
            'select[2]': 'UF_CRM_1594982869',
            'select[3]': 'UF_CRM_1600013645',
            'select[4]': 'UF_CRM_1599842474'
        };
        //parametros de consulta por celular
        const paramsPHONE = { 'filter[PHONE]': celPhone, 'select[0]': 'PHONE', ...similares };
        //parametros de consulta por email
        const paramsEMAIL = { 'filter[EMAIL]': email, 'select[0]': 'EMAIL', ...similares };
        //ambas consultas por PHONE y EMAIL
        const responsePHONE = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.list'}`, (e) => { console.log('e =>', e) }, paramsPHONE);
        const responseEMAIL = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.list'}`, (e) => { console.log('e =>', e) }, paramsEMAIL);
        // este if es un capturador de error
        if (responsePHONE.success === false || responseEMAIL.success === false) alert(responsePHONE.message + ' Code: ' + responsePHONE.status);
        // inicio de proceso
        else {
            // Este if valida si las dos consultas tanto como EMAIL y PHONE no traigan nada
            // en caso de que entre aqui, es que no existe ningun contacto y se inicia el proceso de creacion
            if (
                (responsePHONE.total === 0 || responsePHONE.result.length === 0)
                &&
                (responseEMAIL.total === 0 || responseEMAIL.result.length === 0)
            ) createUser();  //No existe el usuario ---> proceso de insertar
            else {
                // Busqueda del campo email y la posicion si existe concidencia en la busqueda
                let isEmail = false
                let emailPosition = await responseEMAIL.result.map(async (item, index) => {
                    let tempLength = item.EMAIL.length;
                    await item.EMAIL.some((item2, index2) => {
                        item2.VALUE === email ? isEmail = { positionResult: index, postitionEmail: index2, length: tempLength } : isEmail = false
                    })
                }); Promise.all(emailPosition);
                // Busqueda del campo phone y la posicion si existe concidencia en la busqueda
                let isPhone = false
                let phonePosition = await responsePHONE.result.map(async (item, index) => {
                    let tempLength = item.PHONE.length;
                    await item.PHONE.some((item2, index2) => {
                        item2.VALUE === celPhone ? isPhone = { positionResult: index, postitionPhone: index2, length: tempLength } : isPhone = false
                    })
                }); Promise.all(phonePosition);
                if (!isEmail && !isPhone) createUser();//No existe el usuario ---> proceso de insertar
                else {
                    // base del objeto para el envio
                    var ParamsUpdateContant = { 'fields[UF_CRM_1600013645]': "Si", 'fields[UF_CRM_1599842474]': getDateFormated() }
                    // validacion de los campos email y phone para saber cual agregar
                    let TEMP_EMAIL = !isEmail ? null : responseEMAIL.result[isEmail.positionResult].EMAIL[isEmail.postitionEmail];
                    let TEMP_PHONE = !isPhone ? null : responsePHONE.result[isPhone.positionResult].PHONE[isPhone.postitionPhone];
                    let ALL_DATA = !isEmail ? responsePHONE.result[isPhone.positionResult] : responseEMAIL.result[isEmail.positionResult];
                    //creacion de objeto para guardar los campos y valores y acceder mas facil
                    let totalArray = { ...ALL_DATA, EMAIL: TEMP_EMAIL, PHONE: TEMP_PHONE, };
                    // elfe if  para agregar el ID ya sea de los datos encontrados en el objeto Email o Phone
                    if (TEMP_EMAIL) ParamsUpdateContant['ID'] = responseEMAIL.result[isEmail.positionResult].ID;
                    else ParamsUpdateContant['ID'] = responsePHONE.result[isPhone.positionResult].ID;
                    //validacion si se actualiza el nombre y apellido segun el campo UF_CRM_1594982869, si es nulo o vacio se actualizan los nombre
                    if (!totalArray.UF_CRM_1594982869) {
                        ParamsUpdateContant['fields[NAME]'] = names;
                        ParamsUpdateContant['fields[LAST_NAME]'] = lastNames;
                    }
                    // if para agregar el nuevo email o phone segun corresponda
                    if (!totalArray.PHONE || (totalArray.PHONE && totalArray.PHONE.VALUE != celPhone)) ParamsUpdateContant[`fields[PHONE][0][VALUE]`] = celPhone;
                    if (!totalArray.EMAIL || (totalArray.EMAIL && totalArray.EMAIL.VALUE != email)) ParamsUpdateContant[`fields[EMAIL][0][VALUE]`] = email;
                    // constante y metodo de actualizacion seguido de la creacion del Deals
                    const responseUpdateContant = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.update'}`, (e) => { console.log('e =>', e) }, ParamsUpdateContant);
                    if (responseUpdateContant.success === false) setDataAlert({ type: 'InCorrect', title: 'Error', message: responseUpdateContant.message + ' Code: ' + responseUpdateContant.status, showInput: true })
                    else createDeals(totalArray.ID);
                }
            }
        }
    };

    const getDateFormated = () => {
        let date = new Date()
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    const createUser = async () => {
        //No existe el usuario ---> proceso de insertar
        const ParamsCreateContant = {
            'fields[NAME]': names,
            'fields[LAST_NAME]': lastNames,
            'fields[ASSIGNED_BY_ID]': "26",
            'fields[UF_CRM_1600013645]': "Si",
            'fields[UF_CRM_1599842474]': getDateFormated(),
            'fields[UF_CRM_1600015332]': getDateFormated(),
            'fields[PHONE][0][VALUE]': celPhone,
            'fields[PHONE][0][VALUE_TYPE]': 'CELULAR',
            'fields[EMAIL][0][VALUE]': email,
            'fields[EMAIL][0][VALUE_TYPE]': 'PERSONAL',
        }
        const responseCreateContant = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.add'}`, (e) => { console.log('e =>', e) }, ParamsCreateContant);
        if (responseCreateContant.success === false) setDataAlert({ type: 'InCorrect', title: 'Error', message: responseCreateContant.message + ' Code: ' + responseCreateContant.status, showInput: true })
        else createDeals(responseCreateContant.result);
    };

    const createDeals = async (contact_id) => {
        //  Crear el deal despues de una creacion de usuario exitoso
        const ParamsCreateDeals = {
            "fields[STAGE_ID]": "C8:NEW",
            "fields[CATEGORY_ID]": "8",
            'fields[ASSIGNED_BY_ID]': "26",
            "fields[DATE_CREATE]": getDateFormated(),
            "fields[CONTACT_ID]": contact_id,
            "fields[SOURCE_ID]": 4,
            "fields[UF_CRM_5F1175DF7CD29]": plan,
            "fields[TYPE_ID]": "SALE",
            "fields[SOURCE_DESCRIPTION]": "Invierte en tu futuro – Formulario Home",
        }
        const responseCreateDeals = await api.GET_BT(`${FORM_BITRIX24}${'crm.deal.add'}`, (e) => { console.log('e =>', e) }, ParamsCreateDeals);
        if (responseCreateDeals.success === false) {
            setDataAlert({ type: 'InCorrect', title: 'Error', message: responseCreateDeals.message + ' Code: ' + responseCreateDeals.status, showInput: true })
        } else {
            window.gtag('event', 'conversion', { 'send_to': 'AW-525577729/LuESCPj4wPkBEIHczvoB' });
            setDataAlert({ type: 'Correct', title: 'Gracias por iniciar tu meta con Polen.', message: 'Pronto nos contactaremos contigo.', showInput: true })
            SetFormState({ names: '', lastNames: '', email: '', celPhone: '', plan: '' });
        }
    };

    return <section className={`${stStyles.quarterParallaxMovil} ${'options'} mt-0`}>
        <div className='option 1' id={1}>
            <div className={'option__content'}>
                <img id='img_nuevo'
                    src={!isSmallScreen ? Images.ImagesFondosProducto.nuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.nuevo_close_mobile : Images.ImagesFondosProducto.nuevo_close_tablet}
                    alt={!isSmallScreen ? Images.ImagesFondosProducto.nuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.nuevo_close_mobile : Images.ImagesFondosProducto.nuevo_close_tablet}
                />
                <div className={'short'} onClick={() => openBoxM(1)}>
                    <Typography variant={'h2'} className='new-h2'>
                        <b>Carro nue<span className='border-botom-text-optionsR position-relative'>vo</span></b>
                    </Typography>
                </div>
                <div className={'large'}>
                    <div>
                        <Typography variant={'h2'}>
                            <span className='border-botom-text-contentL position-relative'></span>
                            <b className='mltexto_line'>
                                ¿Soñando con estrenar vehículo nuevo?
                            </b>
                        </Typography>
                        <Typography variant={'body1'}>¡La forma más fácil de tener ese vehículo que sueñas es con Polen! Deja de soñar, ponle un plan a tu meta y decídete por la opción financiera que te permite construir patrimonio de manera inteligente.</Typography>
                        <Typography variant={'h2'} className={`${tStyles.h2W24} mb-0`}>Cuotas menuales desde</Typography>
                        <Typography variant={'h2'} className='mt-0 mb-0'><b>$ 748.165</b></Typography>
                        <Button onClick={() => { window.open(URL_AUTOCOMPRA) }} className={`button-left ${bStyles.orangeButton} border-radius-40px`} variant="contained" >
                            <img className={homeClasses.iconPolen} alt={Images.iconPolenWhite} src={Images.iconPolenWhite} /> Simula tu plan
                        </Button>
                        <div className='contenedor-terminos'> 
                            <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*Plan Vehículo Nuevo</p>
                            <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*Plan de $45.000.000 a 72 meses, con administración del 0.23% mensual + IVA de administración.</p>
                            <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*No incluye cuota de ingreso, seguros ni otros conceptos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='option 2' id={2}>
            <div className={'option__content'}>
                <img id='img_usado'
                    src={!isSmallScreen ? Images.ImagesFondosProducto.seminuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.seminuevo_close_mobile : Images.ImagesFondosProducto.seminuevo_close_tablet}
                    alt={!isSmallScreen ? Images.ImagesFondosProducto.seminuevo_close_desk : isSmallScreenMobile ? Images.ImagesFondosProducto.seminuevo_close_mobile : Images.ImagesFondosProducto.seminuevo_close_tablet}
                />
                <div className={'short option-nth-child2-short'} onClick={() => openBoxM(2)}>
                    <Typography variant={'h2'} className='new-h2 text-left p-13'>
                        <b><span className='border-botom-text-optionsL position-relative'>Ca</span>rro usado</b>
                    </Typography>
                </div>
                <div className={'large'}>
                    <div className='text-right'>
                        <div>
                            <Typography variant={'h2'} className='text-white'>
                                <span className='border-botom-text-contentR position-relative'></span>
                                <b className='mltexto_line'>
                                    ¿Interesado en adquirir un vehículo usado?
                                </b>
                            </Typography>
                            <Typography variant={'body1'} className='text-white'>¡El ahorro programado te conducirá a tener ese vehículo usado que tanto sueñas! Deja de soñar y decídete por la opción financiera que te permite construir patrimonio de manera inteligente.</Typography>
                            <Typography variant={'h2'} className={`${tStyles.h2W24} mb-0 text-white`}>Cuotas menuales desde</Typography>
                            <Typography variant={'h2'} className='mt-0 text-white  mb-0'><b>$ 748,165</b></Typography>
                            <Button onClick={() => { window.open(URL_AUTOCOMPRA) }} className={`button-left ${bStyles.orangeButton} border-radius-40px`} variant="contained" >
                                <img className={homeClasses.iconPolen} alt={Images.iconPolenWhite} src={Images.iconPolenWhite} /> Simula tu plan
                            </Button>
                            <div className='contenedor-terminos'> 
                                <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*Plan Vehículo Usado</p>
                                <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*Plan de $45.000.000 a 72 meses, con administración del 0.23% mensual + IVA de administración.</p>
                                <p className={`${tStyles.pw14b} text-white position-unset text-p-theme`}>*No incluye cuota de ingreso, seguros ni otros conceptos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="option_close 1" onClick={() => closeBoxM1()}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
        </div>
        <div className="option_close 2" onClick={() => closeBoxM2()}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
        </div>
        {/* <div className="option_close" onClick={() => closeBoxM()}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
        </div> */}
        <Alert showInput={showInput} type={type} title={title} message={message} onPressClose={() => setDataAlert({ ...dataAlert, showInput: false })} />
    </section>
}




const Form = () => <ScriptTag id="bx24_form_inline" data-skip-moving="true">{`
(function(w,d,u,b){w['Bitrix24FormObject'] = b;w[b] = w[b] || function(){arguments[0].ref = u;
    (w[b].forms=w[b].forms||[]).push(arguments[0])};
    if(w[b]['forms']) return;
    var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
    var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://polen.bitrix24.es/bitrix/js/crm/form_loader.js','b24form');

b24form({"id":"3","lang":"la","sec":"a35p0c","type":"inline"});`}
</ScriptTag>
