import {makeStyles} from "@material-ui/core/styles";

export const carouselBrandsStyles = makeStyles((theme) => ({
    containerCB: {
        marginTop: '30px',
        maxHeight: '100px',
        maxWidth: '610px',
        margin: 'auto',
        '& img': {
            width: '100%',
            maxWidth: '100px',
        },
        '& .swiper-container': {
            padding: '0px 22px'
        },
        '& .swiper-button-prev': {
            maxWidth: '22px',
            marginLeft: '-10px',
        },
        '& .swiper-button-next': {
            maxWidth: '22px',
            marginRight: '-10px',
        },
        '& .swiper-button-prev:after, .swiper-button-next:after': {
            fontSize: '22px',
            color: theme.palette.gray.gray_A7,
            fontWeight: 'bold',
        },
        '& .swiper-slide': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
        }
    },
}));
