import { createStore, combineReducers } from 'redux';
import { authReducer } from '../reducers/authReducer';
import { globalReducer } from "../reducers/globalReducer";
import { parallaxReducer } from "../reducers/parallaxReducer";
import { meetingReducer } from "../reducers/meetingReducer";
import { talkUsReducer } from "../reducers/talkUsReducer";
import { whatIsPolenReducer } from "../reducers/whatIsPolenReducer";

const reducers = combineReducers({
    auth: authReducer,
    global: globalReducer,
    parallax: parallaxReducer,
    meeting: meetingReducer,
    talkUs: talkUsReducer,
    whatIsPolen: whatIsPolenReducer
});

export const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
