import React from 'react';
import { Box, Typography, Link } from "@material-ui/core";
import { carouselNewsStyles } from '../../../styles/theme/components/carouselNews';
// import Swiper core and required components
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// install Swiper components
SwiperCore.use([Navigation, A11y]);

export const CarouselNews = ({ data, onClick }) => {
    const cnStyles = carouselNewsStyles();
    return (
        <Box className={cnStyles.overflowCN}>
            <Box className={cnStyles.containerCN}>
                {!data ? null :
                    <Swiper
                        spaceBetween={66}
                        slidesPerView={1}
                        navigation
                        onSwiper={(swiper) => {/*console.log(swiper)*/ }}
                        onSlideChange={() => {console.log('slide change', data) }}
                        /*breakpoints={{
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            960: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            }
                        }}*/
                    >
                        {data.map((val, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <Box className={'circleWhite'}>
                                        <Typography variant={"h1"} className={'title'}>{val.by}</Typography>
                                        <Typography variant={"h1"} className={'quotes'}>“</Typography>
                                        <Typography variant="body1" className={'description'}>{val.summary}</Typography>
                                        <Box className={'circleOrange'}>
                                            <Typography variant={"h1"}>
                                                <Link href={val.link} target={'_blank'}>Ver más</Link>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                }
            </Box>
        </Box>
    )
};
