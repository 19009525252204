// https://codepen.io/ettrics/pen/ZYqKGb
// https://codepen.io/tommydunn/pen/WEZNLq
import React, { useState, useEffect } from 'react';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { Box, Grid, Button, Typography, TextField, FormControlLabel, Checkbox, Select, MenuItem, Link } from '@material-ui/core';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import { inputStyles } from '@Styles-Project/theme/components/inputs';
import Images from '@Constants-Project/images';
import './index.scss';
import ContainerComponent from '@Components-Project/containerComponent';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { useMediaQuery } from "@material-ui/core";
import { stripStyles } from '@Styles-Project/theme/components/strips';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import ScriptTag from 'react-script-tag';
import Swal from 'sweetalert2';
import {
    FORM_BITRIX24,
    URL_AUTOCOMPRA,
    DATA_PLANS,
    LEGALES_TYC,
    LEGALES_INFO,
    LEGALES_CONDITIONS
} from '@Constants-Project/constants';
import api from "@Constants-Project/api";
import { Alert } from "@Components-Project/alert";


export function FormularioNewHome({ scroll }) {

    const ObtenerFechaHoraActual = () => {
        const [formatoNewDate, setFormatoNewDate] = useState('');

        useEffect(() => {
            const obtenerFechaHoraActual = () => {
            const ahora = new Date();
            const anio = ahora.getFullYear();
            const mes = (ahora.getMonth() + 1).toString().padStart(2, '0');
            const dia = ahora.getDate().toString().padStart(2, '0');
            const horas = ahora.getHours().toString().padStart(2, '0');
            const minutos = ahora.getMinutes().toString().padStart(2, '0');
            const segundos = ahora.getSeconds().toString().padStart(2, '0');

            const formatoNewDate = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;
            setFormatoNewDate(formatoNewDate);
            };

            obtenerFechaHoraActual();

            const intervalo = setInterval(obtenerFechaHoraActual, 1000);

            return () => clearInterval(intervalo);
        }, []);

        return formatoNewDate;
    };
    let newFormatDate = ObtenerFechaHoraActual();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const homeClasses = homeStyles();
    const bStyles = buttonsStyles();
    const inputsClasses = inputStyles();
    const stStyles = stripStyles();
    const tStyles = typographiesStyles();

    const [animationsBox, setanimationsBox] = React.useState({});
    const { box1, box2, box3, box4, box5, box6 } = animationsBox;
    const [formState, SetFormState] = React.useState({ names: '', lastNames: '', email: '', celPhone: '', plan: '' });
    const { names, lastNames, email, celPhone, plan } = formState;
    const [formValid, setFormValid] = React.useState(false);
    const [dataAlert, setDataAlert] = React.useState({ type: '', title: '', message: '', showInput: false })
    const { type, title, message, showInput } = dataAlert;

    const isFormValid = React.useCallback(() => {
        if (!names) return false;
        if (!lastNames) return false;
        if (!email) return false;
        if (!celPhone) return false;
        if (!plan) return false;
        return true;
    }, [names, lastNames, email, celPhone, plan]);

    React.useEffect(() => {
        (isFormValid()) ? setFormValid(true) : setFormValid(false);
    }, [isFormValid]);

    const InputProps = {
        className: inputsClasses.input,
        disableUnderline: true,
    };

    const openBox = (position) => {
        setanimationsBox({
            ...animationsBox,
            [`box${position}`]: !animationsBox[`box${position}`]
        })
    };

    const closeBox = (event) => {
        setanimationsBox({})
    };

    const isAnyOneVisible = () => box1 || box2 || box3 || box4 || box5 || box6;

    const closeBoxM = () => {
        $('#6.option.show').addClass('hide');
        $('#6.option_close').removeClass('show');
        $('#6.option').removeClass('show');
        $('#form-show').hide();
        setTimeout(() => {
            $('#6.option.hide').removeClass('hide');
            $('#6.options').removeClass('extends');
        }, 800);
    };

    const openBoxM = (id) => {
        $('.option').removeClass('show');
        $('#' + id + '.option').addClass('show');
        $('.option_close').addClass('show');
        if (id === 6) {
            $('.options').addClass('extends');
        }
    };

    const handleInputChange = ({ target }) => {
        SetFormState({
            ...formState,
            [target.name]: target.value
        });
        isFormValid();
    };

    const handleInputChangePhone = ({ target }) => {
        if (target.value.length <= 10 && (Math.sign(target.value) != -1 || target.value == 0)) SetFormState({
            ...formState,
            [target.name]: target.value
        });
        isFormValid();
    };

    const handleChange = (event) => {
        SetFormState({
            ...formState,
            [event.target.name]: event.target.value
        })
        isFormValid();
    };

    const handleSendData = async (e) => {
        e.preventDefault();
        
        /*Swal.fire({
            title: "Espere",
            text: "Por favor espere un momento",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "rgba(254,91,2,0.9)",
            cancelButtonColor: "#ccc",
            confirmButtonText: "OK"
        }).then((result) => {
        });*/
        setDataAlert({ type: 'Loader', title: 'Estamos registrando tu información', message: '', showInput: true });
        let similares = {
            'select[1]': 'ID',
            'select[2]': 'UF_CRM_1594982869',
            'select[3]': 'UF_CRM_1600013645',
            'select[4]': 'UF_CRM_1599842474'
        };

        //[names, lastNames, email, celPhone, plan]
        let url = 'https://apis-prod-polen-fondos-app-mobile.azurewebsites.net/api/Prospecto/RedesSociales';
        let newParams = {
            "fecha": newFormatDate,
            "anuncio": "FORMULARIO_HOME_WEB",
            "plataforma": "META",
            "nombre": names,
            "apellido": lastNames,
            "telefono": celPhone,
            "email": email
        };
        console.log('newParams ___', newParams)
        //parametros de consulta por celular
        const paramsPHONE = { 'filter[PHONE]': celPhone, 'select[0]': 'PHONE', ...similares };
        //parametros de consulta por email
        const paramsEMAIL = { 'filter[EMAIL]': email, 'select[0]': 'EMAIL', ...similares };

        try {
            const respuesta = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(newParams),
            });
            console.log('respuesta', respuesta);
            
            window.gtag('event', 'conversion', {'send_to': 'AW-525577729/LuESCPj4wPkBEIHczvoB'});
            setDataAlert({ type: 'Correct', title: 'Gracias por iniciar tu meta con Polen.', message: 'Pronto nos contactaremos contigo.', showInput: true })
            SetFormState({ names: '', lastNames: '', email: '', celPhone: '', plan: '' });
            
            /*Swal.fire({
                title: "¡Éxito!",
                text: "Su mensaje se envió correctamente",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "rgba(254,91,2,0.9)",
                cancelButtonColor: "#ccc",
                confirmButtonText: "OK"
            }).then((result) => {
            });*/
          } catch (error) {
            console.error('Error -----------', error);
        }
        /*const responseNewURL = await api.POSTv2(url, (e) => { console.log('e =>', e) }, newParams, paramsPHONE );
        console.log('responseNewURL ---------', responseNewURL);*/

        /*//ambas consultas por PHONE y EMAIL
        const responsePHONE = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.list'}`, (e) => { console.log('e =>', e) }, paramsPHONE);
        const responseEMAIL = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.list'}`, (e) => { console.log('e =>', e) }, paramsEMAIL);
        // este if es un capturador de error
        if (responsePHONE.success === false || responseEMAIL.success === false) alert(responsePHONE.message + ' Code: ' + responsePHONE.status);
        // inicio de proceso
        else {
            // Este if valida si las dos consultas tanto como EMAIL y PHONE no traigan nada
            // en caso de que entre aqui, es que no existe ningun contacto y se inicia el proceso de creacion
            if (
                (responsePHONE.total === 0 || responsePHONE.result.length === 0)
                &&
                (responseEMAIL.total === 0 || responseEMAIL.result.length === 0)
            ) createUser();  //No existe el usuario ---> proceso de insertar
            else {
                // Busqueda del campo email y la posicion si existe concidencia en la busqueda
                let isEmail = false
                let emailPosition = await responseEMAIL.result.map(async (item, index) => {
                    let tempLength = item.EMAIL.length;
                    await item.EMAIL.some((item2, index2) => {
                        item2.VALUE === email ? isEmail = { positionResult: index, postitionEmail: index2, length: tempLength } : isEmail = false
                    })
                }); Promise.all(emailPosition);
                // Busqueda del campo phone y la posicion si existe concidencia en la busqueda
                let isPhone = false
                let phonePosition = await responsePHONE.result.map(async (item, index) => {
                    let tempLength = item.PHONE.length;
                    await item.PHONE.some((item2, index2) => {
                        item2.VALUE === celPhone ? isPhone = { positionResult: index, postitionPhone: index2, length: tempLength } : isPhone = false
                    })
                }); Promise.all(phonePosition);
                if (!isEmail && !isPhone) createUser();//No existe el usuario ---> proceso de insertar
                else {
                    // base del objeto para el envio
                    var ParamsUpdateContant = { 'fields[UF_CRM_1600013645]': "Si", 'fields[UF_CRM_1599842474]': getDateFormated() }
                    // validacion de los campos email y phone para saber cual agregar
                    let TEMP_EMAIL = !isEmail ? null : responseEMAIL.result[isEmail.positionResult].EMAIL[isEmail.postitionEmail];
                    let TEMP_PHONE = !isPhone ? null : responsePHONE.result[isPhone.positionResult].PHONE[isPhone.postitionPhone];
                    let ALL_DATA = !isEmail ? responsePHONE.result[isPhone.positionResult] : responseEMAIL.result[isEmail.positionResult];
                    //creacion de objeto para guardar los campos y valores y acceder mas facil
                    let totalArray = { ...ALL_DATA, EMAIL: TEMP_EMAIL, PHONE: TEMP_PHONE, };
                    // elfe if  para agregar el ID ya sea de los datos encontrados en el objeto Email o Phone
                    if (TEMP_EMAIL) ParamsUpdateContant['ID'] = responseEMAIL.result[isEmail.positionResult].ID;
                    else ParamsUpdateContant['ID'] = responsePHONE.result[isPhone.positionResult].ID;
                    //validacion si se actualiza el nombre y apellido segun el campo UF_CRM_1594982869, si es nulo o vacio se actualizan los nombre
                    if (!totalArray.UF_CRM_1594982869) {
                        ParamsUpdateContant['fields[NAME]'] = names;
                        ParamsUpdateContant['fields[LAST_NAME]'] = lastNames;
                    }
                    // if para agregar el nuevo email o phone segun corresponda
                    if (!totalArray.PHONE || (totalArray.PHONE && totalArray.PHONE.VALUE != celPhone)) ParamsUpdateContant[`fields[PHONE][0][VALUE]`] = celPhone;
                    if (!totalArray.EMAIL || (totalArray.EMAIL && totalArray.EMAIL.VALUE != email)) ParamsUpdateContant[`fields[EMAIL][0][VALUE]`] = email;
                    // constante y metodo de actualizacion seguido de la creacion del Deals
                    const responseUpdateContant = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.update'}`, (e) => { console.log('e =>', e) }, ParamsUpdateContant);
                    if (responseUpdateContant.success === false) setDataAlert({ type: 'InCorrect', title: 'Error', message: responseUpdateContant.message + ' Code: ' + responseUpdateContant.status, showInput: true })
                    else createDeals(totalArray.ID);
                }
            }
        }*/
    };

    const getDateFormated = () => {
        let date = new Date()
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    const createUser = async () => {
        //No existe el usuario ---> proceso de insertar
        const ParamsCreateContant = {
            'fields[NAME]': names,
            'fields[LAST_NAME]': lastNames,
            'fields[ASSIGNED_BY_ID]': "26",
            'fields[UF_CRM_1600013645]': "Si",
            'fields[UF_CRM_1599842474]': getDateFormated(),
            'fields[UF_CRM_1600015332]': getDateFormated(),
            'fields[PHONE][0][VALUE]': celPhone,
            'fields[PHONE][0][VALUE_TYPE]': 'CELULAR',
            'fields[EMAIL][0][VALUE]': email,
            'fields[EMAIL][0][VALUE_TYPE]': 'PERSONAL',
        }
        const responseCreateContant = await api.GET_BT(`${FORM_BITRIX24}${'crm.contact.add'}`, (e) => { console.log('e =>', e) }, ParamsCreateContant);
        if (responseCreateContant.success === false) setDataAlert({ type: 'InCorrect', title: 'Error', message: responseCreateContant.message + ' Code: ' + responseCreateContant.status, showInput: true })
        else createDeals(responseCreateContant.result);
    };

    const createDeals = async (contact_id) => {
        //  Crear el deal despues de una creacion de usuario exitoso
        const ParamsCreateDeals = {
            "fields[STAGE_ID]": "C8:NEW",
            "fields[CATEGORY_ID]": "8",
            'fields[ASSIGNED_BY_ID]': "26",
            "fields[DATE_CREATE]": getDateFormated(),
            "fields[CONTACT_ID]": contact_id,
            "fields[SOURCE_ID]": 4,
            "fields[UF_CRM_5F1175DF7CD29]": plan,
            "fields[TYPE_ID]": "SALE",
            "fields[SOURCE_DESCRIPTION]": "Invierte en tu futuro – Formulario Home",
        }
        const responseCreateDeals = await api.GET_BT(`${FORM_BITRIX24}${'crm.deal.add'}`, (e) => { console.log('e =>', e) }, ParamsCreateDeals);
        if (responseCreateDeals.success === false) {
            setDataAlert({ type: 'InCorrect', title: 'Error', message: responseCreateDeals.message + ' Code: ' + responseCreateDeals.status, showInput: true })
        } else {
            window.gtag('event', 'conversion', {'send_to': 'AW-525577729/LuESCPj4wPkBEIHczvoB'});
            setDataAlert({ type: 'Correct', title: 'Gracias por iniciar tu meta con Polen.', message: 'Pronto nos contactaremos contigo.', showInput: true })
            SetFormState({ names: '', lastNames: '', email: '', celPhone: '', plan: '' });
        }
    };

    return <section className={`mt-0 ${stStyles.quarterParallaxMovilForm} ${'options'}`} id='form-show'>
        <div className='option show' id={6}>
            <div className={'option__content contenedor-imagen-form'}>
                {/*<img className='col-lg-12' src={ !isSmallScreen ? Images.bannerExapainPeople.large : Images.bannerExapainPeople.short } alt={Images.bannerExapainPeople.large} />*/}
                {/* <div className={'short'} onClick={() => openBoxM(6)}>
                    <div className='contenText up'>
                    <Typography variant={'h1'} className='whiteTitle'>Regístrate aquí</Typography>
                    </div>
                </div> */}
                <div className={'large form'}>
                    <Grid container className={'hAuto'}>
                        <Grid item xs={12} md={6}>
                            <p variant={'h6'}>Regístrate aquí</p>
                            <Typography variant={'h2'} className='mt-0 mb-4'><b>Conoce nuestros planes de compra programada</b></Typography>
                            <Typography className={'textContent text-left'} variant="body1">Hoy tienes la gran oportunidad de comenzar a cumplir todas tus metas. Te enviaremos la información que necesitas para que te decidas por Polen, la alternativa financiera que se ajusta a tu bolsillo.</Typography>
                        </Grid>
                        <Grid container xs={12} md={6} className={`${homeClasses.divFormHome}`}>
                            <form onSubmit={handleSendData} noValidate autoComplete="off">
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={6} xl={4}>
                                        <TextField
                                            value={names}
                                            name="names"
                                            label="Nombres"
                                            onChange={handleInputChange}
                                            required
                                            focused={false}
                                            placeholder="Escribe tu(s) nombre(s)"
                                            fullWidth
                                            InputProps={InputProps}
                                            className={inputsClasses.inputContainerHome}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: inputsClasses.labelHome
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} xl={4}>
                                        <TextField
                                            value={lastNames}
                                            name="lastNames"
                                            label="Apellidos"
                                            onChange={handleInputChange}
                                            required
                                            focused={false}
                                            placeholder="Escribe tu(s) apellido(s)"
                                            fullWidth
                                            InputProps={InputProps}
                                            className={inputsClasses.inputContainerHome}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: inputsClasses.labelHome
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} xl={4}>
                                        <TextField
                                            value={email}
                                            name="email"
                                            label="Email"
                                            onChange={handleInputChange}
                                            required
                                            focused={false}
                                            placeholder="Escribe tu email"
                                            fullWidth
                                            InputProps={InputProps}
                                            className={inputsClasses.inputContainerHome}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: inputsClasses.labelHome
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            value={celPhone}
                                            name="celPhone"
                                            label="Celular"
                                            onChange={handleInputChangePhone}
                                            required
                                            focused={false}
                                            placeholder="Escribe tu celular"
                                            fullWidth
                                            InputProps={InputProps}
                                            className={inputsClasses.inputContainerHome}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: inputsClasses.labelHome
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <div className={'MuiFormControl-fullWidth divSelect'}>
                                            <Select
                                                value={plan}
                                                onChange={handleChange}
                                                name='plan'
                                                label="Producto del plan:"
                                                displayEmpty
                                                InputProps={InputProps}
                                                className={inputsClasses.selectContainerTwo}
                                            >
                                                <MenuItem value="" disabled>Seleccionar</MenuItem>
                                                {DATA_PLANS.map((item) => <MenuItem value={item.id}>{item.type}</MenuItem>)}
                                            </Select>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className='terms' variant="body1">
                                            Al hacer click en el botón “Inicia tu meta”, aceptas las <Link href={LEGALES_CONDITIONS} target="_blank"><b>Términos y Condiciones</b></Link> y las <Link href={LEGALES_INFO} target="_blank"><b>Políticas de Tratamiento de Datos de Polen</b></Link>.
                                        </Typography>
                                        <div className={'divButton'}>
                                            <Button type={'submit'} className={`${bStyles.whiteButton} `} variant="contained" disabled={formValid === false} >
                                                <img className={homeClasses.iconPolen} alt={Images.iconPolen} src={Images.iconPolen} />Inicia tu meta
                                                {formValid === false ? <div className={'grayT'}></div> : null }
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                {/*<Helmet>
                                    <script type="text/javascript" data-b24-form="click/3/a35p0c" data-skip-moving="true">
                                    {`
                                        (function(w,d,u){
                                        var s=d.createElement('script');
                                        s.async=true;
                                        s.src=u+'?'+(Date.now()/180000|0);
                                        var h=d.getElementsByTagName('script')[0];
                                        h.parentNode.insertBefore(s,h);
                                        })(window,document,'https://cdn.bitrix24.es/b13406759/crm/form/loader_3.js');
                                    `}
                                    </script>
                                </Helmet>*/}
                            </form>
                        </Grid>
                    </Grid>
                </div>
                <img className={'people'} src={Images.bannerExapainPeople.people} alt={Images.bannerExapainPeople.people} />
            </div>
        </div>{/*
        <div className="option_close show" onClick={() => closeBoxM()}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
        </div> */}
        <Alert showInput={showInput} type={type} title={title} message={message} onPressClose={() => setDataAlert({ ...dataAlert, showInput: false })} />
    </section>
}




const Form = () => <ScriptTag id="bx24_form_inline" data-skip-moving="true">{`
(function(w,d,u,b){w['Bitrix24FormObject'] = b;w[b] = w[b] || function(){arguments[0].ref = u;
    (w[b].forms=w[b].forms||[]).push(arguments[0])};
    if(w[b]['forms']) return;
    var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
    var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://polen.bitrix24.es/bitrix/js/crm/form_loader.js','b24form');

b24form({"id":"3","lang":"la","sec":"a35p0c","type":"inline"});`}
</ScriptTag>
