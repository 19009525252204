import { types } from '../types/types';

export const whatIsPolenReducer = (state = {}, action) => {
    switch (action.type) {
        case types.setOurGoals:
            return {
                ...state,
                OurGoals: action.payload.OurGoals
            };
        default:
            return state;
    }
};
