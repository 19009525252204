export const types = {
    login: '[AUTH] LOGIN',
    logout: '[AUTH] Logout',

    setGoal: '[GLOBAL] Set Goal',

    setPost: '[Parallax] SetPost',
    setBrands: '[Parallax] setBrands',
    setTestimony: '[Parallax] setTestimony',

    setMeeting: '[Schedule] setMeeting',
    setDateTarget: '[Schedule] setDateTarget',
    saveIntervalToClear: '[Schedule] saveIntervalToClear',

    setAllFAQsActive: '[TalkUs] setAllFAQsActive',
    setPQRTypes: '[TalkUs] setPQRTypes',

    setOurGoals: '[WhatIsPolen] setOurGoals',
}
