import {makeStyles} from "@material-ui/core/styles";

export const imageGoalStyles = makeStyles((theme) => ({
    '@keyframes animationTheGoal': {
        '0%': {
            opacity: '0',
            transform: 'translateY(100px)'
        },
        '100%': { opacity: '1' }
    },
    rootIG: {
        width: '140px',
        cursor: 'pointer',
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            width: '96px',
        },
        [theme.breakpoints.down('md')]: {
            width: '96px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '70px',
        },
    },
    containerIG: {
        padding: '0px',
        textAlign: 'center',
        display:'flex',
        maxWidth: '65vw',
        margin: '50px auto 0px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '90vw',
        },
        '& >div': {
            margin: 'auto !important',
            display: 'flex',
            justifyContent: 'center',
        },
        '& .animationTheGoal': {
            display: 'flex !important',
            animation: 'ease-in $animationTheGoal',
            '&:nth-child(1)': {
                animationDuration: '800ms',
            },
            '&:nth-child(2)': {
                animationDuration: '1000ms',
            },
            '&:nth-child(3)': {
                animationDuration: '1200ms',
            },
            '&:nth-child(4)': {
                animationDuration: '1400ms',
            },
            '&:nth-child(5)': {
                animationDuration: '1600ms',
            }
        },

    },
    active:{
        fontWeight:'bold',
        marginTop:'4px',
        fontSize: '18px',
        lineHeight: '18.6px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            lineHeight: '14.6px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '12.6px'
        },
    },
    inactive:{
        fontSize: '18px',
        lineHeight: '18.6px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px',
            lineHeight: '14.6px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '12.6px'
        },
    },
}));
