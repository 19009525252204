import React from 'react'
import {
    Switch,
    Route,
    useHistory
} from 'react-router-dom';
import { BodyRoutes } from './bodyRoutes';
import { PriveRoute } from './PriveRoute';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { _login } from '@Redux-Project/actions/authActions';

export default () => {
    const { logged } = useSelector(state => state.auth);
    return (
        <Switch>
            <Route exact path="/login" component={LoginComponent} />
            <PriveRoute path="/" component={BodyRoutes} isAuthenticated={logged} />
        </Switch>
    )
}

function LoginComponent() {
    console.log('logincomponent')
    const dispatch = useDispatch();
    const history = useHistory();
    const login = () => {
        dispatch(_login())
        history.replace('/inicio');
    }
    return <Button onClick={() => login()} variant="contained" color="primary"> Primary </Button>
}
