import { makeStyles } from "@material-ui/core/styles";
import Colors from '@Constants-Project/colors';


export const typographiesStyles = makeStyles((theme) => ({
    /** H1 */
    h1Black: {
        color: theme.palette.black.main,
    },
    h196: {
        fontSize: '96px',
        lineHeight: '100.4px',
        color: theme.palette.primary.main
    },
    h160Black: {
        fontSize: '60px',
        lineHeight: '62.8px',
        color: theme.palette.black.main,
    },
    h136Black: {
        fontSize: '36px',
        lineHeight: '37.7px',
        color: theme.palette.black.main,
    },
    h136BlackH: {
        fontSize: '36px',
        lineHeight: '37.7px',
        color: theme.palette.black.main,
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            lineHeight: '22.4px',
        },
    },
    h136White: {
        fontSize: '36px',
        lineHeight: '37.7px',
        color: theme.palette.white.main,
    },
    textWhite:{
        color: theme.palette.white.main,
    },
    h132: {
        fontSize: '32px',
        marginBottom: '15px',
    },
    h136: {
        fontSize: '36px !important',
    },
    h120: {
        fontSize: '20px',
        lineHeight: '20.9px',
    },
    h118White: {
        fontSize: '18px',
        lineHeight: '18.8px',
        color: theme.palette.white.main,
    },
    h1Black18: {
        fontSize: '18px',
        marginBottom: '6px',
        color: theme.palette.black.main,
    },
    h1BlackLight: {
        color: theme.palette.black.main,
        fontFamily: 'BeVietnam-Regular',
        fontWeight: 'normal',
    },
    h114Black: {
        fontSize: '14px',
        lineHeight: '14.4px',
        color: theme.palette.black.main,
    },
    /** H2 */
    h218: {
        fontSize: '18px',
        lineHeight: '18.8px',
    },
    h6Required: {
        '&:after': {
            content: '*',
            color: theme.palette.red.main,
        }
    },
    h2W24: {
        fontSize: '16px !important',
        lineHeight: '16.8px !important',
        color: theme.palette.white.main,
    },
    h2B24: {
        fontSize: '16px !important',
        lineHeight: '16.8px !important',
        color: theme.palette.black.main,
    },
    /** body1 */
    body1Primary: {
        color: theme.palette.primary.main,
    },
    body1Italic: {
        fontStyle: 'italic',
    },
    body1Bold: {
        fontWeight: 'bold',
    },
    body124Bold: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '26.8px',
    },
    body110: {
        fontSize: '10px',
        lineHeight: '11.2px',
    },
    body110Center: {
        fontSize: '10px',
        lineHeight: '11.2px',
        textAlign: 'center',
    },
    body112: {
        fontSize: '12px',
        lineHeight: '14.4px',
        '& a': {
            cursor: 'pointer',
            fontWeight: 'bold',
            '&:hover': {
                textDecoration: 'none',
            }
        }
    },
    body115: {
        fontSize: '15px',
        lineHeight: '16.7px',
    },
    body116: {
        fontSize: '16px',
        lineHeight: '18.6px',
    },
    body116White: {
        fontSize: '16px',
        lineHeight: '18.6px',
        color: theme.palette.white.main,
    },
    body118: {
        fontSize: '18px',
        lineHeight: '20.8px',
    },
    body120: {
        fontSize: '20px',
        lineHeight: '22.4px',
    },
    pw14b: {
        fontSize: '14px !important',
        lineHeight: '16.6px !important',
        position: 'absolute',
        bottom: '0px',
        maxWidth: '500px !important',
        textShadow: '1px 1px #000000',
    },
    pb14b: {
        fontSize: '14px !important',
        lineHeight: '16.6px !important',
        position: 'absolute',
        bottom: '0px',
        maxWidth: '500px !important',
        textShadow: '1px 1px #FFFFFF',
        color: theme.palette.black.main,
    },
    colorWhite: {
        color: theme.palette.white.main,
    },
    colorBlack: {
        color: theme.palette.black.main
    },
    hColorWhite: {
        color: Colors.primary
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    textAlignRight: {
        textAlign: 'right',
    },
    textWidth100P: {
        width: '100%'
    },
    textWidth50P: {
        width: '50%'
    },
    textWidth40P: {
        width: '40%'
    },
    marginTop20: {
        marginTop: "60px"
    },
    marginTop25: {
        marginTop: '25px'
    },
    marginTop46: {
        marginTop: '46px'
    },
    marginTop156: {
        marginTop: '156px',
    },
    mb40: {
        marginBottom: '40px',
    },
    hauto: {
        height: 'auto',
    },
}));

