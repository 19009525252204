import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import ReactTypingEffect from 'react-typing-effect';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import { Link } from "react-router-dom";


export default function Doubts() {
    const wipStyles = whatIsPolenStyles();
    const bStyles = buttonsStyles();
    const FancyLink = React.forwardRef((props, ref) => {
        return <Button variant="contained" {...props} onClick={() => props.ref} className={`${bStyles.button} ${bStyles.whiteButton}`}>Pregúntanos</Button>
    })
    return <Box className={wipStyles.divDoubts}>
        <ContainerComponent>
            <Grid container direction='row' >
                <Grid item xs={12} sm={0} md={1}></Grid>
                <Grid item xs={12} sm={6} md={7} className={"center-xs centerContent"}>
                    <Box className={'divText'}>
                        <Typography variant={'h1'}>¿Dudas?</Typography>
                        <Typography variant="body1">Sabemos lo importante que es cumplir una meta, por eso resolvemos tus dudas para que logres tu objetivo con confianza.</Typography>
                        <Link to="/hablanos/1" component={FancyLink}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={"center-xs img-sm"}>
                    <img alt={Images.womanA} src={Images.womanA} />
                </Grid>
                <Grid item xs={12} sm={0} md={1}></Grid>
            </Grid>
        </ContainerComponent>
    </Box>
}
