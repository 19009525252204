import { types } from "../types/types";

export const setAllFAQsActive = (AllFAQsActive) => ({
    type: types.setAllFAQsActive,
    payload: {
        AllFAQsActive
    }
});

export const setPQRTypes = (PQRTypes) => ({
    type: types.setPQRTypes,
    payload: {
        PQRTypes
    }
});
