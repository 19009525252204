import React from 'react';
import { homeStyles } from '@Styles-Project/theme/layout/home';
import { Box, Typography } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { useMediaQuery } from "@material-ui/core";
import $ from "jquery";

export function MetasHoy({ scroll, onPress }) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm")); // Desde 600 px en adelante
    const isMobileScreen = useMediaQuery(theme => theme.breakpoints.down("xs")); // Menor a 600 px
    const homeClasses = homeStyles();

    const verticalMove = (idElement, top, minTop, start) => {
        let secPosition = $('#' + idElement).position();
        let secHeight = $('#' + idElement).height();
        let a = { top: top, opacity: '0' };
        if (secPosition !== undefined && secPosition.hasOwnProperty('top')) {
            let startPoint = secPosition.top - (secHeight * start);
            if (scroll > startPoint) {
                a.top = minTop;
                a.opacity = 1;
                let rest = top - (scroll - startPoint);
                if (rest > minTop) {
                    a.top = rest;
                    a.opacity = 1 - rest/top;
                }
            }
        }
        return a;
    };

    const centerBall = () => {
        return verticalMove('section2', 300, 20, 0.70);
    };

            {/*<div className="que-meta-tienes-hoy contenedor-imagen">
                <img className="imagen-fondo col-lg-12" src={Images.ImagesMetahoy.fondo} alt="Imagen para fondo" />
                <div className="contenido-imagen text-center">
                    <a href='/#'>
                        <img className="imagen-desktop col-lg-12" src={Images.ImagesMetahoy.desktop} alt="Imagen para desktop" />
                        <img className="imagen-tablet col-lg-12" src={Images.ImagesMetahoy.tablet} alt="Imagen para tablet" />
                        <img className="imagen-mobile col-lg-12" src={Images.ImagesMetahoy.mobile} alt="Imagen para mobile" />
                    </a>
                </div>
                <MetasHoy scroll={scrollY}/>
            </div>*/}

    return <section id={'section2'} className={`${homeClasses.secondParallax}`}>
        <Box className={'two'} >
            <Typography variant="h1">¿Qué meta</Typography>
            <Typography variant="h3">tienes hoy?</Typography>
        </Box>
        { !isSmallScreen ?
            <Box className={'animationBox'}>
                <img onClick={() => { window.location.href = 'https://autocompra.polen.com.co'; }} className="col-lg-8 mt-6" src={Images.ImagesMetahoy.desktop} alt="Imagen para desktop" />
            </Box>
            :
            <img onClick={() => { window.location.href = 'https://autocompra.polen.com.co'; }} className={'imageSmall'} alt={Images.ImagesMetahoy.mobile}
                 src={Images.ImagesMetahoy.mobile}/>
        }
    </section >
}
