import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import TalkToUsComponent from '@Components-Project/talkToUsComponent';
import api from '@Constants-Project/api';
import { useDispatch } from 'react-redux';
import { setAllFAQsActive, setPQRTypes } from '@Redux-Project/actions/talkUsActions';
import { _formatGetAllFAQsActive } from '@Functions-Project/formatData';

import { Helmet } from "react-helmet";

export default function TalkToUs() {

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(!open);
    const dispatch = useDispatch();
    /**conumo del api para hablanos/1 */
    const getAllFAQsActive = async () => {
        let response = await api.GET('api/FAQs/GetAllFAQsActive');
        if (response.success) dispatch(setAllFAQsActive(await _formatGetAllFAQsActive(response.data)));
        else alert(response.message + ' Code: ' + response.status)
    }

    const getPQRTypes = async () => {
        let response = await api.GET('api/Contacts/PQRSType');
        if (response.success) dispatch(setPQRTypes(response.data));
        else alert(response.message + ' Code: ' + response.status)
    }

    //document.title = "Polen | Háblanos";

    React.useEffect(() => {
        getAllFAQsActive();
        getPQRTypes();
    }, [])

    return (
        <>
            <Helmet
                title={"Polen | Háblanos"}
                meta={[
                    {
                        name: 'description',
                        content: "Háblanos, resolvemos todas tus preguntas e inquietudes",
                    }
                ]}
            />

            <Box style={{ display: 'block' }}>
                <TalkToUsComponent open={open} handleClose={handleClose} />
            </Box>
        </>
    );
}
