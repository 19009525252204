import React, {Component, useState} from 'react';
import Header from './main/header/header';
import Footer from './main/footer/footer';
import './App.css';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { themePolen } from '@Styles-Project/theme/themePolen'
import { GlobalStyles } from '@Styles-Project/globalStyles'
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import MainRouter from './routes/mainRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import api from "@Constants-Project/api";
import Images from '@Constants-Project/images'
import {ENV, reloadData, reloadDataBase} from "@Constants-Project/constants";
import {Typography} from "@material-ui/core";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga-gtm';

ReactGA.initialize('UA-181456409-1');

const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};

export default function App() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('959036211535683', options);
    ReactPixel.pageView();
    const [ifConfigs, setIfConfigs] = useState(JSON.parse(localStorage.getItem('vars')) !== null ? true : false);
    const [ifError, setIfError] = useState(false);

    const getConfigs = async () => {
        await api.GET('api/Configuraciones')
            .then((response) => {
                if (response.success) {
                    let vars = [];
                    for(const envs of response.data) {
                        vars = envs;
                    }
                    localStorage.setItem('vars', JSON.stringify(vars));
                    reloadData();
                    setIfConfigs(true);
                } else {
                    setIfError(true);
                }
            }).catch((e) => {
                setIfConfigs(false);
            });
    }

    React.useEffect(() => {
        reloadDataBase();
        getConfigs();
    }, [])

    return (
        <>
            {
                ifConfigs ?
                    <Provider store={store}>
                        <CssBaseline />
                        <GlobalStyles />
                        <MuiThemeProvider theme={themePolen}>
                            <Router>
                                <Header />
                                <main>
                                    <MainRouter />
                                </main>
                                <Footer />
                            </Router>
                        </MuiThemeProvider>
                    </Provider>
                    : <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <img alt={Images.loader} src={Images.loader} />
                        {
                            ifError ?
                                <Typography variant={'h2'} style={{ textAlign: 'center', fontSize: '24px' }}>No se han cargado las variables de entorno correctamente, por favor comunícate con el administrador Web</Typography>
                                : null
                        }
                    </div>
            }
        </>
    );
}
