import React from 'react';
import { Grid, Typography, Fade, Box, Link as Link2 } from '@material-ui/core';
import { stepsStyles } from '@Styles-Project/theme/layout/steps/steps';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import Images from '@Constants-Project/images';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";

const FancyLink = React.forwardRef((props) => {
    return <Link2 color="inherit" href={props.ref} {...props} >{props.children}</Link2>
})

const ChatScript = () => {
    window.sagicc_chat_config = {
        back_url: 'https://polen.jelow.co',
        chat_token: '30a349aa18ead7cfbcfd7c7a9d07a7cf',
        chat_type: 'widget',
        client_first_name: '',
        client_last_name: '',
        client_email: '',
        auto_initialize: false
    };
    var sagicc_chat_isSecured = (window.location && window.location.protocol == 'https:');
    var sagicc_chat_css = document.createElement('link');
    sagicc_chat_css.setAttribute('rel', 'stylesheet');
    sagicc_chat_css.setAttribute('href',
        sagicc_chat_isSecured ?
            'https://polen.jelow.co/chat/assets/css/sagicc-chat.css' :
            'https://polen.jelow.co/chat/assets/css/sagicc-chat.css'
    );
    document.head.appendChild(sagicc_chat_css);
    var sagicc_chat_js = document.createElement('script');
    sagicc_chat_js.setAttribute('src',
        sagicc_chat_isSecured ?
            'https://polen.jelow.co/chat/assets/js/sagicc-chat.js' :
            'https://polen.jelow.co/chat/assets/js/sagicc-chat.js'
    );
    document.body.appendChild(sagicc_chat_js);
    abrir_widget();
}

const abrir_widget = () => {
    setTimeout(() => {
        const sagicc_chat_div = document.getElementById('sagicc-chat-div');
        try {
            var sagicc_chat_widget = sagicc_chat_div.getElementsByTagName('button');
            sagicc_chat_widget[2].click();
        } catch (e) { }
    }, 2000);
}

const HelpMobile = ({ open, handleClose }) => {

    const stepClasses = stepsStyles();
    const tClasses = typographiesStyles();

    return (
        <Grid item xs={12} className={`${stepClasses.showMobile} ${/*'pInheriit'*/''}`}>
            <Grid container className={stepClasses.mobileHelpButton} onClick={handleClose}>
                <Grid item xs={4} className={stepClasses.mobileHelpButtonIcon}>
                    <img alt="Icon chat" src={Images.IconChat} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body1" className={`${tClasses.textWhite} ${stepClasses.alignCenter}`}>¿Tienes dudas?</Typography>
                </Grid>
                <Grid item xs={2} className={stepClasses.mobileHelpButtonArrow}>
                    <img alt="Icon chat" src={!open ? Images.ArrowDown : Images.ArrowUp} />
                </Grid>
            </Grid>
            {open &&
                <Fade in={true} timeout={800}>
                    <Box className={stepClasses.mobileHelpContainer}>
                        <Typography variant="body1" className={`${tClasses.h118White} ${stepClasses.mt70}`}>¿Necesitas ayuda?</Typography>
                        <Typography variant="body1" className={`${tClasses.body116White} ${stepClasses.mobileHelpBody1}`}>Te apoyamos para que unidos alcancemos tus metas. Escoge tu medio de contacto favorito:</Typography>
                        <Grid container>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4} className={`${stepClasses.mobileHelpContainerButton}`}>
                                <p
                                    style={{ cursor: 'pointer', marginTop: '0' }}
                                    onClick={() => {
                                        handleClose()
                                        ChatScript()
                                    }}>
                                    <img alt="Icon chat" src={Images.IconChat} />
                                    <Typography variant="body1" className={`${tClasses.body116White}`}>Chat</Typography>
                                </p>
                            </Grid>
                            <Grid item xs={4} className={`${stepClasses.mobileHelpContainerButton}`}>
                                <Link to="/talkToUs/2" component={FancyLink} >
                                    <img alt="Icon chat" src={Images.IconPhone} />
                                    <Typography variant="body1" className={`${tClasses.body116White}`}>Te llámamos</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Box>
                </Fade>
            }
        </Grid>
    )
}

const HelpDesktop = ({ open, handleClose }) => {
    const stepClasses = stepsStyles();
    return (
        <Grid className='btn_help_polen' item md={1} style={{ position: 'relative' }}>
            <img alt="" src={Images.IconSupport} style={{ cursor: 'pointer' }} onClick={handleClose} className={stepClasses.iconSupport} />
            {open &&
                <Fade in={true} timeout={800}>
                    <div className='btn_help_polen_content' style={{ position: "absolute", textAlign: 'center', right: '0px', top: '0px', backgroundColor: '#fe5b02', borderRadius: '100%', width: '214px', height: '214px', color: '#fff' }}>
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', position: "absolute", borderRadius: '100%', backgroundColor: '#fff', color: '#000', border: "1px solid #fe5b02", width: '32px', height: '32px', right: '30px' }} />
                        <div style={{ marginTop: '30px', fontSize: '18px', fontWeight: 'bold' }} ><p>¿Tienes dudas?</p></div>
                        <div style={{ fontSize: '12px', textAlign: 'center', padding: '5px', marginTop: '-30px' }}><p>Te apoyamos para que unidos alcancemos tus metas. Escoge tu medio de contacto favorito:</p></div>
                        <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ fontSize: '12px' }}>
                                <p
                                    style={{ cursor: 'pointer', marginTop: '0' }}
                                    onClick={() => {
                                        handleClose()
                                        ChatScript()
                                    }} >
                                    <img style={{ height: '24px' }} alt="Icon chat" src={Images.IconChat} />
                                    <div style={{ fontSize: '12px', textAlign: 'center' }}>Chat</div>
                                </p>
                            </div>
                            <div style={{ fontSize: '12px', textAlign: 'center', marginLeft: '20px' }}>
                                <Link to="/hablanos/2" component={FancyLink} >
                                    <img style={{ height: '24px' }} alt="Icon chat" src={Images.IconPhone} />
                                    <div style={{ fontSize: '12px', textAlign: 'center' }}>Te llamamos</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Fade>
            }
        </Grid>
    )
}

export {
    HelpDesktop,
    HelpMobile
}
