export async function _formatMeeting(data) {
    let dataStructure = [];
    let selected = false;
    let a = data.map((item) => {
        let date = new Date(item.initDate);
        let dateNow = new Date();
        let diference = date - dateNow;
        if (diference > 0 && !selected) selected = true;
        dataStructure.push({
            ...item,
            startDate: item.initDate,
            endDate: new Date(item.initDate).setMinutes(60),
            ColorId: diference < 0 ? 1 : 2, /* 1 gris  2 naranja */
            moreNear: selected
        });
    })
    await Promise.all(a);
    return dataStructure;
}

export async function _formatGetAllFAQsActive(data) {
    let dataStructure = []
    let a = data.sort((a, b) => {
        if (a.order > b.order) { return 1; }
        if (a.order < b.order) { return -1; }
        return 0;
    }).map(async (item) => {
        let faQs = [];
        let b = item.faQs.sort((a, b) => {
            if (a.order > b.order) { return 1; }
            if (a.order < b.order) { return -1; }
            return 0;
        }).map((itemB) => {
            if (itemB.isActive) faQs.push({ ...itemB, show: false })
        })
        dataStructure.push({ ...item, faQs: faQs })
        await Promise.all(b);
    })
    await Promise.all(a);
    return dataStructure;
}
