import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";

export const stripStyles = makeStyles((theme) => ({
    '@keyframes stripSlideRigth': {
        '0%': { transform: 'translate3d(100%, 0, 0)' },
        '100%': { transform: 'translate3d(0, 0, 0)' }
    },
    // OPEN AND CLOSE STRIPS
    // OPTION 2
    '@keyframes option2': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 6) * -1))' },
    },
    '@keyframes option2H': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -1))' },
    },
    '@keyframes option2Close': {
        '0%': { transform: 'translateY(calc((100% / 6) * -1))' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes option2HClose': {
        '0%': { transform: 'translateX(calc((100% / 6) * -1))' },
        '100%': { transform: 'translateX(0)' },
    },
    // OPTION 3
    '@keyframes option3ShowUsado': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 4) * -2))' },
    },
    '@keyframes option3': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 6) * -2))' },
    },
    '@keyframes option3HShowUsado': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -2))' },
    },
    '@keyframes option3H': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -2))' },
    },
    '@keyframes option3Close': {
        '0%': { transform: 'translateY(calc((100% / 6) * -2))' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes option3HClose': {
        '0%': { transform: 'translateX(calc((100% / 6) * -2))' },
        '100%': { transform: 'translateX(0)' },
    },
    // OPTION 4
    '@keyframes option4': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 6) * -3))' },
    },
    '@keyframes option4H': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -3))' },
    },
    '@keyframes option4Close': {
        '0%': { transform: 'translateY(calc((100% / 6) * -3))' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes option4HClose': {
        '0%': { transform: 'translateX(calc((100% / 6) * -3))' },
        '100%': { transform: 'translateX(0)' },
    },
    // OPTION 5
    '@keyframes option5': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 6) * -4))' },
    },
    '@keyframes option5H': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -4))' },
    },
    '@keyframes option5Close': {
        '0%': { transform: 'translateY(calc((100% / 6) * -4))' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes option5HClose': {
        '0%': { transform: 'translateX(calc((100% / 6) * -4))' },
        '100%': { transform: 'translateX(0)' },
    },
    // OPTION 6
    '@keyframes option6': {
        '0%': { transform: 'translateY(0px)' },
        '100%': { transform: 'translateY(calc((100% / 6) * -5))' },
    },
    '@keyframes option6H': {
        '0%': { transform: 'translateX(0px)' },
        '100%': { transform: 'translateX(calc((100% / 6) * -5))' },
    },
    '@keyframes option6Close': {
        '0%': { transform: 'translateY(calc((100% / 6) * -5))' },
        '100%': { transform: 'translateY(0)' },
    },
    '@keyframes option6HClose': {
        '0%': { transform: 'translateX(calc((100% / 6) * -5))' },
        '100%': { transform: 'translateX(0)' },
    },
    quarterParallaxMovil: {
        '&.options': {
            height: '550px',
            //height: '700px',
            textAlign: 'left',
            color: theme.palette.white.main,
            backgroundColor: theme.palette.black.main,
            position: 'relative',
            marginTop: '50px',
            [theme.breakpoints.down('sm')]: {
                height: '700px',
                textAlign: 'left',
                color: theme.palette.white.main,
                position: 'relative',
                marginTop: '50px',
                '&.extends': {
                    minHeight: '900px !important',
                },
            },
            [theme.breakpoints.down('xs')]: {
                height: '600px', /** Altura del form y de los productos */
                textAlign: 'left',
                color: theme.palette.white.main,
                position: 'relative',
                marginTop: '50px',
            },
            '&.extends': {
                minHeight: '100%',
                [theme.breakpoints.down('sm')]: {
                    minHeight: '900px',
                },
                [theme.breakpoints.down('xs')]: {
                    minHeight: '1000px',
                },
            },
            '& .option': {
                position: 'absolute',
                width: 'calc(100% / 2)',
                height: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
                zIndex: '1',
                [theme.breakpoints.down('sm')]: {
                    width: '100vw',
                    height: 'calc(100% / 2)', /* MODIFICADO */
                },
                '&.show': {
                    zIndex: '2',
                    height: '100%',
                    width: '100%',
                    animationDelay: '0s',
                    animationDuration: '800ms',
                    transition: 'width 0.8s',
                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
                    [theme.breakpoints.down('sm')]: {
                        transition: 'height 0.8s',
                    },
                    '& .option__content': {
                        height: '100%',
                        overflow: 'hidden',
                        '& .short': {
                            display: 'none',
                        },
                        '& .large': {
                            display: 'block',
                        }
                    },
                },
                '&.hide': {
                    zIndex: '2',
                    transition: 'width 0.8s',
                    height: '100%',
                    width: 'calc(100% / 6)',
                    animationDelay: '0s',
                    animationDuration: '800ms',
                    transitionTimingFunction: 'cubic-bezier(1, 1, 0, 0)',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: 'calc(100% / 6)',
                        transition: 'height 0.8s',
                    },
                    '& .option__content': {
                        '& .short': {
                            display: 'none',
                        },
                        '& .large': {
                            display: 'block',
                        }
                    },
                },
                '& .option__content': {
                    height: '100%',
                    animationDelay: '0s',
                    animationName: '$stripSlideRigth',
                    transition: 'width 0.8s',
                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
                    animationDuration: '1000ms',
                    overflow: 'hidden',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        transition: 'height 0.8s',
                    },
                    '& .short': {
                        cursor: 'pointer',
                        padding: '0px 30px',
                        width: '100%',
                        display: 'block',
                        alignItems: 'center',
                        height: '100%',
                        zIndex: '1',
                        position: 'relative',
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                            alignItems: 'center'
                        },
                        [theme.breakpoints.down('xs')]: {
                            display: 'flex',
                            paddingBottom: '6vh',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                        },
                        '& h2': {
                            [theme.breakpoints.down('xs')]: {
                                float: 'left',
                                display: 'contents',
                            },
                        },
                        '&:hover': {
                            backgroundImage: 'linear-gradient(to bottom, rgba(254, 91, 2, 0.7), rgba(254, 91, 2, 0.7))',
                            '& .divText': {
                                backgroundImage: 'none',
                            }
                        },
                        '& a': {
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        },
                        '& .divText': {
                            display: 'block',
                            position: 'absolute',
                            bottom: '0',
                            width: '100%',
                            height: '300px',
                            left: '0',
                            padding: '10%',
                            backgroundImage: 'linear-gradient(to top, #000000, rgba(0, 0, 0, 0))',
                            [theme.breakpoints.down('sm')]: {
                                backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                                bottom: 'inherit',
                                width: '70vw',
                                margin: '0',
                                padding: '0',
                                height: '100%',
                            },
                            [theme.breakpoints.down('xs')]: {
                                width: '90vw',
                            },
                            '& .divIcon': {
                                width: '70px',
                                height: '70px',
                                position: 'absolute',
                                bottom: '170px',
                                verticalAlign: 'middle',
                                [theme.breakpoints.down('lg')]: {
                                    width: '50px',
                                    height: '50px',
                                    position: 'absolute',
                                    bottom: '140px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '30px',
                                    height: '100%',
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                },
                                '& img': {
                                    height: '100%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    [theme.breakpoints.down('sm')]: {
                                        maxHeight: '50px',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        maxHeight: '40px',
                                    },
                                }
                            },
                            '& .contenText': {
                                color: theme.palette.white.main,
                                width: 'inherit',
                                position: 'absolute',
                                bottom: '60px',
                                verticalAlign: 'middle',
                                [theme.breakpoints.down('lg')]: {
                                    bottom: '30px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '20px',
                                    display: 'inline-block',
                                    width: '70%',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '20px',
                                    display: 'inline-block',
                                    width: '60%',
                                },
                                '& p': {
                                    fontSize: '14px',
                                    lineHeight: '16.4px',
                                    margin: '0px',
                                    display: 'none',
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'block',
                                    },
                                },
                                '& h1': {
                                    fontSize: '28px',
                                    lineHeight: '28.7px',
                                    margin: '5px 0px',
                                    fontweight: 'bolder',
                                    textAlign: 'left',
                                    width: '80%',
                                    height: '80px',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '18px',
                                        lineHeight: '18.2px',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        lineHeight: '16.8px',
                                    },
                                    '&.whiteTitle': {
                                        color: theme.palette.white.main,
                                        textAlign: 'center',
                                        marginRight: '20px',
                                    }
                                }
                            }
                        },
                    },
                    '& .large': {
                        display: 'none',
                        height: '100%',
                        textAlign: 'center',
                        zIndex: '1',
                        position: 'relative',
                        '&.form': {
                            '& >div': {
                                top: '6%',
                                '& h2': {
                                    fontSize: '28px',
                                    lineHeight: '28.2px',
                                }
                            }
                        },
                        '& >div': {
                            position: 'absolute',
                            minHeight: '336px',
                            height: '80%',
                            width: '80%',
                            top: '10%',
                            left: '10%',
                            [theme.breakpoints.down('xs')]: {
                                paddingTop: '135px',
                            },
                            '& h6': {
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '18px',
                                lineHeight: '18.8px',
                                marginBottom: '30px',
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                            },
                            '& h2': {
                                marginTop: '20px',
                                textAlign: 'left',
                                fontSize: '36px',
                                lineHeight: '36.8px',
                                width: '100%',
                                marginBottom: '30px',
                                maxWidth: '600px',
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '32px',
                                    lineHeight: '32.2px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                    fontSize: '18px',
                                    lineHeight: '18.8px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '18px',
                                    lineHeight: '18.8px',
                                },
                            },
                            '& p': {
                                fontSize: '16px',
                                lineHeight: '16.8px',
                                width: '70%',
                                textAlign: 'left',
                                maxWidth: '360px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '14px',
                                    lineHeight: '14.6px',
                                },
                                '&.terms': {
                                    width: '100%',
                                    fontSize: '16px',
                                    lineHeight: '16.8px',
                                    maxWidth: 'inherit',
                                    margin: '0 10px',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '12px',
                                        lineHeight: '12.4px',
                                    },
                                }
                            },
                            '& button': {
                                position: 'relative',
                                float: 'left',
                                top: '20px',
                                minWidth: '250px',
                                fontSize: '20px',
                                lineHeight: '20.9px',
                                textTransform: 'none',
                                padding: '15px 0px',
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    top: '50px',
                                    float: 'left',
                                    minWidth: '250px'
                                },
                                [theme.breakpoints.down('xs')]: {
                                    position: 'relative',
                                    top: '30px',
                                    width: '100%',
                                    maxWidth: 'inherit',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                },
                                '& .MuiButton-label': {
                                    '& img': {
                                        marginRight: '20px'
                                    }
                                },
                            }
                        },
                    },
                    '&>img': {
                        position: 'absolute',
                        width: '100vw',
                        height: 'auto',
                        bottom: '0',
                        zIndex: '0',
                        [theme.breakpoints.down('md')]: {
                            height: '700px',
                            width: 'auto',
                            right: '0'
                        },
                        [theme.breakpoints.down('sm')]: {
                            height: '700px',
                            width: 'auto',
                        },
                        [theme.breakpoints.down('xs')]: {
                            height: '600px',
                            width: 'auto',
                        },
                        '&.people': {
                            position: 'absolute',
                            bottom: '0',
                            top: 'inherit',
                            left: '50%',
                            right: 'inherit',
                            height: 'auto',
                            width: '1000px',
                            marginLeft: '-480px !important',
                            [theme.breakpoints.down('lg')]: {
                                width: '850px',
                                maxWidth: 'inherit',
                                marginLeft: '-405px !important',
                            },
                            [theme.breakpoints.down('md')]: {
                                width: '780px',
                                maxWidth: 'inherit',
                                marginLeft: '-370px !important',
                            },
                            [theme.breakpoints.down('sm')]: {
                                //display: 'none', // moiicado
                            },
                        }
                    },
                },
                '&:nth-child(1)': {
                    top: '0',
                    left: 'calc((100% / 6) * 0)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 0)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7,
                        '&>img': {
                            right: '-250px',
                            bottom: '0',
                            transform: 'translateX(0%) translateY(17%)',
                            [theme.breakpoints.down('lg')]: {
                                //transform: 'translateX(-7%) translateY(7%)',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-200px',
                                transform: 'translateX(-0%) translateY(8%)',
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-300px',
                                right: '0',
                                height: '700px',
                                transform: 'none',
                                width: '100%',
                            },
                            [theme.breakpoints.down('xs')]: {
                                top: '0',
                                right: '-107px',
                                height: 'auto',
                                width: '160%',
                                left: 'inherit',
                                transform: 'none',
                            },
                        }
                    },
                    '&.show': {
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    left: 'inherit',
                                    right: '-39%',
                                    top: '0px',
                                    height: 'auto',
                                    width: '170%'
                                },
                            }
                        },
                    },
                    '&.hide': {
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    height: 'auto',
                                    bottom: '0px',
                                    left: 'inherit',
                                    right: '-0'
                                },
                            }
                        },
                    }
                },
                /*'&:nth-child(2)': {
                    top: '0',
                    left: 'calc((100% / 2) * 1)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 2) * 1)',
                        left: '0',
                    },
                    // height: '750px !important',
                    '& .option__content': {
                        [theme.breakpoints.down('sm')]: {
                            background: theme.palette.primary.c,
                        },
                        '&>img': {
                            right: '-500px',
                            bottom: '0',
                            [theme.breakpoints.down('xl')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('lg')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '0'
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: '960px',
                                height: 'auto',
                                bottom: '100%',
                                right: 'inherit'
                            },
                        },
                        '& .short': {
                            '& .contenText': {
                                top: '50px',
                                left: '50px',
                                bottom: 'inherit',
                                paddingTop: '50px',
                                [theme.breakpoints.down('sm')]: {
                                    top: '0',
                                    paddingTop: '0',
                                    width: '100%',
                                    textAlign: 'center'
                                },
                                '& h1': {
                                    textAlign: 'center !important',
                                    maxWidth: '100%',
                                    fontSize: '36px',
                                    lineHeight: '37.7px',
                                    margin: '5px 0',
                                    fontweight: 'bolder',
                                    width: '100%',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '24px',
                                        lineHeight: '24.2px',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        lineHeight: '16.8px',
                                    },
                                }
                            }
                        },
                        '& .large': {
                            '&>img': {
                                bottom: '0',
                                [theme.breakpoints.down('md')]: {
                                    height: '100%',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    right: '-200px',
                                },
                            },
                            '&.form': {
                                height: '100%',
                                marginTop: '30px',
                                '&>div': {
                                    height: 'auto',
                                    [theme.breakpoints.down('sm')]: {
                                        top: '50px',
                                    },
                                    '& h6': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '16px',
                                            lineHeight: '16px',
                                            paddingBottom: '10px',
                                            marginBottom: '0'
                                        },
                                    },
                                    '& h2': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '20px',
                                            lineHeight: '20px',
                                            paddingBottom: '20px',
                                            margin: '0'
                                        },
                                    },
                                    '& p.textContent': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit',
                                            marginBottom: '50px',
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '14px',
                                            lineHeight: '14.6px',
                                            paddingBottom: '20px',
                                            margin: '0',
                                            textAlign: 'left',
                                        },
                                    },
                                    '& .divSelect': {
                                        position: 'relative',
                                        paddingTop: '10px',
                                        '&>div': {
                                            marginTop: '12px',
                                        }
                                    },
                                    '& .divButton': {
                                        marginRight: '10px',
                                        '& button': {
                                            position: 'relative',
                                            float: 'left',
                                            minWidth: '250px',
                                            lineHeight: '20.9px',
                                            textTransform: 'none',
                                            height: '60px',
                                            marginRight: '0',
                                            top: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                height: '50px',
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                minWidth: '250px'
                                            },
                                            [theme.breakpoints.down('xs')]: {
                                                position: 'relative',
                                                top: '20px',
                                                width: '100%',
                                                maxWidth: 'inherit',
                                            },
                                            '& .MuiButton-label': {
                                                '& img': {
                                                    marginRight: '20px'
                                                }
                                            },
                                            '& .grayT': {
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0,0,0,0.25)',
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option6H',
                        transform: 'translateX(calc((100% / 2) * -1))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option6',
                            transform: 'translateY(calc((100% / 2) * -1))',
                        },
                        '& .option__content': {
                            [theme.breakpoints.down('sm')]: {
                                textAlign: 'center'
                            },
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('md')]: {
                                    bottom: '0',
                                    left: '50%',
                                    right: 'inherit',
                                    marginLeft: '-650px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '50%',
                                    marginLeft: '-480px',
                                },
                                '&.people': {
                                    position: 'absolute',
                                    bottom: '0',
                                    top: 'inherit',
                                    left: '50%',
                                    right: 'inherit',
                                    height: 'auto',
                                    width: '1000px',
                                    marginLeft: '-500px !important',
                                    [theme.breakpoints.down('lg')]: {
                                        width: '850px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-425px !important',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        width: '780px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-390px !important',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '70vw',
                                        maxWidth: '672px',
                                        marginLeft: '-35vw !important',
                                        display: 'block',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: '80vw',
                                        marginLeft: '-40vw !important',
                                        display: 'block',
                                    },
                                }
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option6HClose',
                        transform: 'translateX(calc((100% / 6) * 5))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option6Close',
                            transform: 'translateY(calc((100% / 6) * 5))',
                        },
                        '& .option__content': {
                            '&>img': {
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '0',
                                },
                            }
                        },
                    }
                },*/
                '&:nth-child(2)': {
                    top: '0',
                    left: 'calc((100% / 4) * 2)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 2) * 1)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_60,
                        '&>img': {
                            right: '-106px',
                            top: '-405px',
                            [theme.breakpoints.down('lg')]: {
                                right: '-50px',
                                top: '-129px',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-400px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                height: '700px',
                                bottom: '0',
                                right: 'inherit',
                                left: '0',
                                top: '0',
                                marginLeft: '0',
                                width: '105%',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '0',
                                top: '0',
                                right: 'inherit',
                                left: '0',
                                marginLeft: '0',
                                height: 'auto'
                            },
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option3HShowUsado',
                        transform: 'translateX(calc((100% / 4) * -2))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option3ShowUsado',
                            transform: 'translateY(calc((100% / 4) * -2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                    width: '100%',
                                    left: '0',
                                    marginLeft: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: '0',
                                    left: '0',
                                    marginLeft: '0',
                                    width: '100%',
                                    height: '100%'
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option3HClose',
                        transform: 'translateX(calc((100% / 6) * 2))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option3Close',
                            transform: 'translateY(calc((100% / 6) * 2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(3)': {
                    top: '0',
                    left: 'calc((100% / 6) * 2)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 2)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_60,
                        '&>img': {
                            right: '-450px',
                            [theme.breakpoints.down('lg')]: {
                                right: '-420px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-400px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-250px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-220px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-400px',
                                height: '600px'
                            },
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option3H',
                        transform: 'translateX(calc((100% / 6) * -2))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option3',
                            transform: 'translateY(calc((100% / 6) * -2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option3HClose',
                        transform: 'translateX(calc((100% / 6) * 2))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option3Close',
                            transform: 'translateY(calc((100% / 6) * 2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(4)': {
                    top: '0',
                    left: 'calc((100% / 6) * 3)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 3)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_40,
                        '&>img': {
                            right: '-380px',
                            top: '0',
                            [theme.breakpoints.down('md')]: {
                                right: '-300px',
                                top: '0',
                            },
                            [theme.breakpoints.down('sm')]: {
                                top: 'inherit',
                                bottom: '-200px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-200px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-400px',
                                height: '600px'
                            },
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option4H',
                        transform: 'translateX(calc((100% / 6) * -3))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option4',
                            transform: 'translateY(calc((100% / 6) * -3))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option4HClose',
                        transform: 'translateX(calc((100% / 6) * 3))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option4Close',
                            transform: 'translateY(calc((100% / 6) * 3))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(5)': {
                    top: '0',
                    left: 'calc((100% / 6) * 4)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 4)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_20,
                        '&>img': {
                            right: '-400px',
                            top: '-5%',
                            [theme.breakpoints.down('xl')]: {
                                top: '0',
                                bottom: 'inherit',
                            },
                            [theme.breakpoints.down('lg')]: {
                                top: '0',
                                bottom: 'inherit',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '0',
                                right: '-350px',
                                marginBottom: '0'
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-300px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                                top: 'inherit',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-250px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-300px',
                                height: '600px',
                                top: 'inherit',
                            },
                        },
                        '& .large': {
                            '& p, h2, h6': {
                                color: theme.palette.black.main
                            }
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option5H',
                        transform: 'translateX(calc((100% / 6) * -4))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option5',
                            transform: 'translateY(calc((100% / 6) * -4))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-300px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option5HClose',
                        transform: 'translateX(calc((100% / 6) * 4))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option5Close',
                            transform: 'translateY(calc((100% / 6) * 4))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-300px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(6)': {
                    top: '0',
                    left: 'calc((100% / 6) * 5)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 5)',
                        left: '0',
                    },
                    // height: '750px !important',
                    '& .option__content': {
                        [theme.breakpoints.down('sm')]: {
                            background: theme.palette.primary.c,
                        },
                        '&>img': {
                            right: '-500px',
                            bottom: '0',
                            [theme.breakpoints.down('xl')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('lg')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '0'
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: '960px',
                                height: 'auto',
                                bottom: '100%',
                                right: 'inherit'
                            },
                        },
                        '& .short': {
                            '& .contenText': {
                                top: '50px',
                                left: '50px',
                                bottom: 'inherit',
                                paddingTop: '50px',
                                [theme.breakpoints.down('sm')]: {
                                    top: '0',
                                    paddingTop: '0',
                                    width: '100%',
                                    textAlign: 'center'
                                },
                                '& h1': {
                                    textAlign: 'center !important',
                                    maxWidth: '100%',
                                    fontSize: '36px',
                                    lineHeight: '37.7px',
                                    margin: '5px 0',
                                    fontweight: 'bolder',
                                    width: '100%',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '24px',
                                        lineHeight: '24.2px',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        lineHeight: '16.8px',
                                    },
                                }
                            }
                        },
                        '& .large': {
                            '&>img': {
                                bottom: '0',
                                [theme.breakpoints.down('md')]: {
                                    height: '100%',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    right: '-200px',
                                },
                            },
                            '&.form': {
                                height: '100%',
                                marginTop: '30px',
                                '&>div': {
                                    height: 'auto',
                                    [theme.breakpoints.down('sm')]: {
                                        top: '50px',
                                    },
                                    '& h6': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '16px',
                                            lineHeight: '16px',
                                            paddingBottom: '10px',
                                            marginBottom: '0'
                                        },
                                    },
                                    '& h2': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '20px',
                                            lineHeight: '20px',
                                            paddingBottom: '20px',
                                            margin: '0'
                                        },
                                    },
                                    '& p.textContent': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit',
                                            marginBottom: '50px',
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '14px',
                                            lineHeight: '14.6px',
                                            paddingBottom: '20px',
                                            margin: '0',
                                            textAlign: 'left',
                                        },
                                    },
                                    '& .divSelect': {
                                        position: 'relative',
                                        paddingTop: '10px',
                                        '&>div': {
                                            marginTop: '12px',
                                        }
                                    },
                                    '& .divButton': {
                                        marginRight: '10px',
                                        '& button': {
                                            position: 'relative',
                                            float: 'left',
                                            minWidth: '250px',
                                            lineHeight: '20.9px',
                                            textTransform: 'none',
                                            height: '60px',
                                            marginRight: '0',
                                            top: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                height: '50px',
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                minWidth: '250px'
                                            },
                                            [theme.breakpoints.down('xs')]: {
                                                position: 'relative',
                                                top: '20px',
                                                width: '100%',
                                                maxWidth: 'inherit',
                                            },
                                            '& .MuiButton-label': {
                                                '& img': {
                                                    marginRight: '20px'
                                                }
                                            },
                                            '& .grayT': {
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0,0,0,0.25)',
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option6H',
                        transform: 'translateX(calc((100% / 6) * -5))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option6',
                            transform: 'translateY(calc((100% / 6) * -5))',
                        },
                        '& .option__content': {
                            [theme.breakpoints.down('sm')]: {
                                textAlign: 'center'
                            },
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('md')]: {
                                    bottom: '0',
                                    left: '50%',
                                    right: 'inherit',
                                    marginLeft: '-650px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '50%',
                                    marginLeft: '-480px',
                                },
                                '&.people': {
                                    position: 'absolute',
                                    bottom: '0',
                                    top: 'inherit',
                                    left: '50%',
                                    right: 'inherit',
                                    height: 'auto',
                                    width: '1000px',
                                    marginLeft: '-500px !important',
                                    [theme.breakpoints.down('lg')]: {
                                        width: '850px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-425px !important',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        width: '780px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-390px !important',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '70vw',
                                        maxWidth: '672px',
                                        marginLeft: '-35vw !important',
                                        display: 'block',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: '80vw',
                                        marginLeft: '-40vw !important',
                                        display: 'block',
                                    },
                                }
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option6HClose',
                        transform: 'translateX(calc((100% / 6) * 5))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option6Close',
                            transform: 'translateY(calc((100% / 6) * 5))',
                        },
                        '& .option__content': {
                            '&>img': {
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '0',
                                },
                            }
                        },
                    }
                },
            },
            /*'& .option_close': {
                position: 'absolute',
                top: '20px',
                right: '10px',
                display: 'none',
                zIndex: '3',
                '&.show': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            },*/
            '& .option_close': {
                position: 'absolute',
                margin: 'auto',
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                top: '30px',
                right: '30px',
                zIndex: '3',
                display: 'none',
                backgroundColor: theme.palette.black.t_24,
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                border: '0.5px solid',
                borderColor: 'rgba(255, 255, 255, 0.15)',
                '&.show': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                '& .leftright': {
                    height: '2px',
                    width: '20px',
                    position: 'absolute',
                    marginTop: '0',
                    borderRadius: '2px',
                    transform: 'rotate(45deg)',
                    backgroundColor: theme.palette.white.main,

                },
                '& .rightleft': {
                    height: '2px',
                    width: '20px',
                    position: 'absolute',
                    marginTop: '0',
                    borderRadius: '2px',
                    transform: 'rotate(-45deg)',
                    backgroundColor: theme.palette.white.main,
                }
            }
        },
    },
    quarterParallaxMovilForm: {
        '&.options': {
            height: '550px',
            //height: '700px',
            textAlign: 'left',
            color: theme.palette.white.main,
            backgroundColor: theme.palette.black.main,
            position: 'relative',
            marginTop: '50px',
            [theme.breakpoints.down('sm')]: {
                height: '1000px',
                textAlign: 'left',
                color: theme.palette.white.main,
                position: 'relative',
                marginTop: '50px',
                '&.extends': {
                    minHeight: '900px !important',
                },
            },
            [theme.breakpoints.down('xs')]: {
                height: '1000px', /** Altura del form y de los productos */
                textAlign: 'left',
                color: theme.palette.white.main,
                position: 'relative',
                marginTop: '50px',
            },
            '&.extends': {
                minHeight: '100%',
                [theme.breakpoints.down('sm')]: {
                    minHeight: '900px',
                },
                [theme.breakpoints.down('xs')]: {
                    minHeight: '1000px',
                },
            },
            '& .option': {
                position: 'absolute',
                width: 'calc(100% / 2)',
                height: '100%',
                overflow: 'hidden',
                cursor: 'pointer',
                zIndex: '1',
                [theme.breakpoints.down('sm')]: {
                    width: '100vw',
                    height: 'calc(100% / 2)', /* MODIFICADO */
                },
                '&.show': {
                    zIndex: '2',
                    height: '100%',
                    width: '100%',
                    animationDelay: '0s',
                    animationDuration: '800ms',
                    transition: 'width 0.8s',
                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
                    [theme.breakpoints.down('sm')]: {
                        transition: 'height 0.8s',
                    },
                    '& .option__content': {
                        height: '100%',
                        overflow: 'hidden',
                        '& .short': {
                            display: 'none',
                        },
                        '& .large': {
                            display: 'block',
                        }
                    },
                },
                '&.hide': {
                    zIndex: '2',
                    transition: 'width 0.8s',
                    height: '100%',
                    width: 'calc(100% / 6)',
                    animationDelay: '0s',
                    animationDuration: '800ms',
                    transitionTimingFunction: 'cubic-bezier(1, 1, 0, 0)',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        height: 'calc(100% / 6)',
                        transition: 'height 0.8s',
                    },
                    '& .option__content': {
                        '& .short': {
                            display: 'none',
                        },
                        '& .large': {
                            display: 'block',
                        }
                    },
                },
                '& .option__content': {
                    height: '100%',
                    animationDelay: '0s',
                    animationName: '$stripSlideRigth',
                    transition: 'width 0.8s',
                    transitionTimingFunction: 'cubic-bezier(0, 0, 1, 1)',
                    animationDuration: '1000ms',
                    overflow: 'hidden',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        transition: 'height 0.8s',
                    },
                    '& .short': {
                        cursor: 'pointer',
                        padding: '0px 30px',
                        width: '100%',
                        display: 'block',
                        alignItems: 'center',
                        height: '100%',
                        zIndex: '1',
                        position: 'relative',
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                            alignItems: 'center'
                        },
                        [theme.breakpoints.down('xs')]: {
                            display: 'flex',
                            alignItems: 'center'
                        },
                        '&:hover': {
                            backgroundImage: 'linear-gradient(to bottom, rgba(254, 91, 2, 0.7), rgba(254, 91, 2, 0.7))',
                            '& .divText': {
                                backgroundImage: 'none',
                            }
                        },
                        '& a': {
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        },
                        '& .divText': {
                            display: 'block',
                            position: 'absolute',
                            bottom: '0',
                            width: '100%',
                            height: '300px',
                            left: '0',
                            padding: '10%',
                            backgroundImage: 'linear-gradient(to top, #000000, rgba(0, 0, 0, 0))',
                            [theme.breakpoints.down('sm')]: {
                                backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
                                bottom: 'inherit',
                                width: '70vw',
                                margin: '0',
                                padding: '0',
                                height: '100%',
                            },
                            [theme.breakpoints.down('xs')]: {
                                width: '90vw',
                            },
                            '& .divIcon': {
                                width: '70px',
                                height: '70px',
                                position: 'absolute',
                                bottom: '170px',
                                verticalAlign: 'middle',
                                [theme.breakpoints.down('lg')]: {
                                    width: '50px',
                                    height: '50px',
                                    position: 'absolute',
                                    bottom: '140px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '30px',
                                    height: '100%',
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                },
                                '& img': {
                                    height: '100%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    [theme.breakpoints.down('sm')]: {
                                        maxHeight: '50px',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        maxHeight: '40px',
                                    },
                                }
                            },
                            '& .contenText': {
                                color: theme.palette.white.main,
                                width: 'inherit',
                                position: 'absolute',
                                bottom: '60px',
                                verticalAlign: 'middle',
                                [theme.breakpoints.down('lg')]: {
                                    bottom: '30px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '20px',
                                    display: 'inline-block',
                                    width: '70%',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    position: 'relative',
                                    bottom: 'inherit',
                                    marginLeft: '20px',
                                    display: 'inline-block',
                                    width: '60%',
                                },
                                '& p': {
                                    fontSize: '14px',
                                    lineHeight: '16.4px',
                                    margin: '0px',
                                    display: 'none',
                                    [theme.breakpoints.down('sm')]: {
                                        display: 'block',
                                    },
                                },
                                '& h1': {
                                    fontSize: '28px',
                                    lineHeight: '28.7px',
                                    margin: '5px 0px',
                                    fontweight: 'bolder',
                                    textAlign: 'left',
                                    width: '80%',
                                    height: '80px',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '18px',
                                        lineHeight: '18.2px',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        lineHeight: '16.8px',
                                    },
                                    '&.whiteTitle': {
                                        color: theme.palette.white.main,
                                        textAlign: 'center',
                                        marginRight: '20px',
                                    }
                                }
                            }
                        },
                    },
                    '& .large': {
                        display: 'none',
                        height: '100%',
                        textAlign: 'center',
                        zIndex: '1',
                        position: 'relative',
                        '&.form': {
                            '& >div': {
                                top: '6%',
                                '& h2': {
                                    fontSize: '28px',
                                    lineHeight: '28.2px',
                                }
                            }
                        },
                        '& >div': {
                            position: 'absolute',
                            minHeight: '336px',
                            height: '80%',
                            width: '80%',
                            top: '10%',
                            left: '10%',
                            '& h6': {
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '18px',
                                lineHeight: '18.8px',
                                marginBottom: '30px',
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '16px',
                                    lineHeight: '16.7px',
                                },
                            },
                            '& h2': {
                                marginTop: '20px',
                                textAlign: 'left',
                                fontSize: '36px',
                                lineHeight: '36.8px',
                                width: '100%',
                                marginBottom: '30px',
                                maxWidth: '600px',
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '32px',
                                    lineHeight: '32.2px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                    fontSize: '18px',
                                    lineHeight: '18.8px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '18px',
                                    lineHeight: '18.8px',
                                },
                            },
                            '& p': {
                                fontSize: '16px',
                                lineHeight: '16.8px',
                                width: '70%',
                                textAlign: 'left',
                                maxWidth: '360px',
                                [theme.breakpoints.down('sm')]: {
                                    maxWidth: '300px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    width: '100%',
                                    maxWidth: 'inherit',
                                    fontSize: '14px',
                                    lineHeight: '14.6px',
                                },
                                '&.terms': {
                                    width: '100%',
                                    fontSize: '16px',
                                    lineHeight: '16.8px',
                                    maxWidth: 'inherit',
                                    margin: '0 10px',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '12px',
                                        lineHeight: '12.4px',
                                    },
                                }
                            },
                            '& button': {
                                position: 'relative',
                                float: 'left',
                                top: '0',
                                minWidth: '250px',
                                fontSize: '20px',
                                lineHeight: '20.9px',
                                textTransform: 'none',
                                padding: '15px 0px',
                                [theme.breakpoints.down('sm')]: {
                                    position: 'relative',
                                    top: '50px',
                                    float: 'left',
                                    minWidth: '250px'
                                },
                                [theme.breakpoints.down('xs')]: {
                                    position: 'relative',
                                    top: '50px',
                                    width: '100%',
                                    maxWidth: 'inherit',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                },
                                '& .MuiButton-label': {
                                    '& img': {
                                        marginRight: '20px'
                                    }
                                },
                            }
                        },
                    },
                    '&>img': {
                        position: 'absolute',
                        width: '100vw',
                        height: 'auto',
                        bottom: '0',
                        zIndex: '0',
                        [theme.breakpoints.down('md')]: {
                            height: '700px',
                            width: 'auto',
                            right: '0'
                        },
                        [theme.breakpoints.down('sm')]: {
                            height: '700px',
                            width: 'auto',
                        },
                        [theme.breakpoints.down('xs')]: {
                            height: '600px',
                            width: 'auto',
                        },
                        '&.people': {
                            position: 'absolute',
                            bottom: '0',
                            top: 'inherit',
                            left: '50%',
                            right: 'inherit',
                            height: 'auto',
                            width: '1000px',
                            marginLeft: '-480px !important',
                            [theme.breakpoints.down('lg')]: {
                                width: '850px',
                                maxWidth: 'inherit',
                                marginLeft: '-405px !important',
                            },
                            [theme.breakpoints.down('md')]: {
                                width: '780px',
                                maxWidth: 'inherit',
                                marginLeft: '-370px !important',
                            },
                            [theme.breakpoints.down('sm')]: {
                                //display: 'none', // moiicado
                            },
                        }
                    },
                },
                '&:nth-child(1)': {
                    top: '0',
                    left: 'calc((100% / 6) * 0)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 0)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7,
                        '&>img': {
                            right: '-250px',
                            bottom: '0',
                            transform: 'translateX(0%) translateY(23%)',
                            [theme.breakpoints.down('lg')]: {
                                transform: 'translateX(-7%) translateY(7%)',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-200px',
                                transform: 'translateX(-0%) translateY(8%)',
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-300px',
                                right: '0',
                                height: '700px',
                                transform: 'none',
                            },
                            [theme.breakpoints.down('xs')]: {
                                height: '600px',
                                bottom: '-250px',
                                left: 'inherit',
                                right: '-120px',
                                transform: 'none',
                            },
                        }
                    },
                    '&.show': {
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    height: 'auto',
                                    bottom: '0px',
                                    left: 'inherit',
                                    right: '0'
                                },
                            }
                        },
                    },
                    '&.hide': {
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    height: 'auto',
                                    bottom: '0px',
                                    left: 'inherit',
                                    right: '-0'
                                },
                            }
                        },
                    }
                },
                '&:nth-child(2)': {
                    top: '0',
                    left: 'calc((100% / 2) * 1)', /* MODIFICADO calc((100% / 6) * 1)*/
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 2) * 1)', /* MODIFICADO  calc((100% / 6) * 1)*/
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_80,
                        '&>img': {
                            right: '-400px',
                            transform: 'translateX(-15%) translateY(22%)',
                            [theme.breakpoints.down('lg')]: {
                                right: '-350px',
                                bottom: '0px',
                                transform: 'translateX(0%) translateY(8%)',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-300px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-200px',
                                right: '0px',
                                height: '700px',
                                left: '50%',
                                marginLeft: '-480px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-200px',
                                right: '-200px',
                                height: '600px',
                                left: 'inherit',
                                marginLeft: 'inherit'
                            },
                        },
                        '& .large': {
                            color: theme.palette.black.main,
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option2H',
                        transform: 'translateX(calc((100% / 2) * -1))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option2',
                            transform: 'translateY(calc((100% / 6) * -1))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-480px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0px',
                                    right: '-200px',
                                    left: 'inherit',
                                    marginLeft: 'inherit'
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option2HClose',
                        transform: 'translateX(calc((100% / 6) * 1))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option2Close',
                            transform: 'translateY(calc((100% / 6) * 1))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0px',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-480px',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: '-200px',
                                    left: 'inherit',
                                    marginLeft: 'inherit'
                                },
                            }
                        },
                    }
                },
                '&:nth-child(3)': {
                    top: '0',
                    left: 'calc((100% / 6) * 2)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 2)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_60,
                        '&>img': {
                            right: '-450px',
                            [theme.breakpoints.down('lg')]: {
                                right: '-420px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('md')]: {
                                right: '-400px',
                                bottom: '0px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-250px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-220px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-400px',
                                height: '600px'
                            },
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option3H',
                        transform: 'translateX(calc((100% / 6) * -2))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option3',
                            transform: 'translateY(calc((100% / 6) * -2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option3HClose',
                        transform: 'translateX(calc((100% / 6) * 2))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option3Close',
                            transform: 'translateY(calc((100% / 6) * 2))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(4)': {
                    top: '0',
                    left: 'calc((100% / 6) * 3)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 3)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_40,
                        '&>img': {
                            right: '-380px',
                            top: '0',
                            [theme.breakpoints.down('md')]: {
                                right: '-300px',
                                top: '0',
                            },
                            [theme.breakpoints.down('sm')]: {
                                top: 'inherit',
                                bottom: '-200px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-200px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-400px',
                                height: '600px'
                            },
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option4H',
                        transform: 'translateX(calc((100% / 6) * -3))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option4',
                            transform: 'translateY(calc((100% / 6) * -3))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option4HClose',
                        transform: 'translateX(calc((100% / 6) * 3))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option4Close',
                            transform: 'translateY(calc((100% / 6) * 3))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-400px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(5)': {
                    top: '0',
                    left: 'calc((100% / 6) * 4)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 4)',
                        left: '0',
                    },
                    '& .option__content': {
                        background: theme.palette.gray.gray_A7_20,
                        '&>img': {
                            right: '-400px',
                            top: '-5%',
                            [theme.breakpoints.down('xl')]: {
                                top: '0',
                                bottom: 'inherit',
                            },
                            [theme.breakpoints.down('lg')]: {
                                top: '0',
                                bottom: 'inherit',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '0',
                                right: '-350px',
                                marginBottom: '0'
                            },
                            [theme.breakpoints.down('sm')]: {
                                bottom: '-300px',
                                height: '700px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-480px',
                                top: 'inherit',
                            },
                            [theme.breakpoints.down('xs')]: {
                                bottom: '-250px',
                                right: 'inherit',
                                left: '50%',
                                marginLeft: '-300px',
                                height: '600px',
                                top: 'inherit',
                            },
                        },
                        '& .large': {
                            '& p, h2, h6': {
                                color: theme.palette.black.main
                            }
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option5H',
                        transform: 'translateX(calc((100% / 6) * -4))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option5',
                            transform: 'translateY(calc((100% / 6) * -4))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-300px',
                                },
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option5HClose',
                        transform: 'translateX(calc((100% / 6) * 4))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option5Close',
                            transform: 'translateY(calc((100% / 6) * 4))',
                        },
                        '& .option__content': {
                            '&>img': {
                                right: '0px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    right: '0',
                                },
                                [theme.breakpoints.down('xs')]: {
                                    bottom: '0',
                                    right: 'inherit',
                                    left: '50%',
                                    marginLeft: '-300px',
                                },
                            }
                        },
                    }
                },
                '&:nth-child(6)': {
                    top: '0',
                    left: 'calc((100% / 6) * 5)',
                    [theme.breakpoints.down('sm')]: {
                        top: 'calc((100% / 6) * 5)',
                        left: '0',
                    },
                    // height: '750px !important',
                    '& .option__content': {
                        [theme.breakpoints.down('sm')]: {
                            background: theme.palette.primary.c,
                        },
                        '&>img': {
                            right: '-500px',
                            bottom: '0',
                            [theme.breakpoints.down('xl')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('lg')]: {
                                bottom: '0',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '0'
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: '960px',
                                height: 'auto',
                                bottom: '100%',
                                right: 'inherit'
                            },
                        },
                        '& .short': {
                            '& .contenText': {
                                top: '50px',
                                left: '50px',
                                bottom: 'inherit',
                                paddingTop: '50px',
                                [theme.breakpoints.down('sm')]: {
                                    top: '0',
                                    paddingTop: '0',
                                    width: '100%',
                                    textAlign: 'center'
                                },
                                '& h1': {
                                    textAlign: 'center !important',
                                    maxWidth: '100%',
                                    fontSize: '36px',
                                    lineHeight: '37.7px',
                                    margin: '5px 0',
                                    fontweight: 'bolder',
                                    width: '100%',
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: '24px',
                                        lineHeight: '24.2px',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: 'auto',
                                        height: 'auto',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: 'auto',
                                        height: 'auto',
                                        fontSize: '16px',
                                        lineHeight: '16.8px',
                                    },
                                }
                            }
                        },
                        '& .large': {
                            '&>img': {
                                bottom: '0',
                                [theme.breakpoints.down('md')]: {
                                    height: '100%',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    right: '-200px',
                                },
                            },
                            '&.form': {
                                height: '100%',
                                marginTop: '30px',
                                '&>div': {
                                    height: 'auto',
                                    [theme.breakpoints.down('sm')]: {
                                        top: '50px',
                                    },
                                    '& h6': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '16px',
                                            lineHeight: '16px',
                                            paddingBottom: '10px',
                                            marginBottom: '0'
                                        },
                                    },
                                    '& h2': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit'
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '20px',
                                            lineHeight: '20px',
                                            paddingBottom: '20px',
                                            margin: '0'
                                        },
                                    },
                                    '& p.textContent': {
                                        [theme.breakpoints.down('sm')]: {
                                            textAlign: 'center',
                                            width: '100%',
                                            maxWidth: 'inherit',
                                            marginBottom: '50px',
                                        },
                                        [theme.breakpoints.down('xs')]: {
                                            fontSize: '14px',
                                            lineHeight: '14.6px',
                                            paddingBottom: '20px',
                                            margin: '0',
                                            textAlign: 'left',
                                        },
                                    },
                                    '& .divSelect': {
                                        position: 'relative',
                                        paddingTop: '10px',
                                        '&>div': {
                                            marginTop: '12px',
                                        }
                                    },
                                    '& .divButton': {
                                        marginRight: '10px',
                                        '& button': {
                                            position: 'relative',
                                            float: 'left',
                                            minWidth: '250px',
                                            lineHeight: '20.9px',
                                            textTransform: 'none',
                                            height: '60px',
                                            marginRight: '0',
                                            top: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                height: '50px',
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                position: 'relative',
                                                float: 'inherit',
                                                top: '20px',
                                                minWidth: '250px'
                                            },
                                            [theme.breakpoints.down('xs')]: {
                                                position: 'relative',
                                                top: '20px',
                                                width: '100%',
                                                maxWidth: 'inherit',
                                            },
                                            '& .MuiButton-label': {
                                                '& img': {
                                                    marginRight: '20px'
                                                }
                                            },
                                            '& .grayT': {
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0,0,0,0.25)',
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '&.show': {
                        animationDuration: '800ms',
                        animationName: '$option6H',
                        transform: 'translateX(calc((100% / 6) * -5))',
                        [theme.breakpoints.down('sm')]: {
                            animationName: '$option6',
                            transform: 'translateY(calc((100% / 6) * -5))',
                        },
                        '& .option__content': {
                            [theme.breakpoints.down('sm')]: {
                                textAlign: 'center'
                            },
                            '&>img': {
                                right: '0',
                                [theme.breakpoints.down('md')]: {
                                    bottom: '0',
                                    left: '50%',
                                    right: 'inherit',
                                    marginLeft: '-650px',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '50%',
                                    marginLeft: '-480px',
                                },
                                '&.people': {
                                    position: 'absolute',
                                    bottom: '0',
                                    top: 'inherit',
                                    left: '50%',
                                    right: 'inherit',
                                    height: 'auto',
                                    width: '1000px',
                                    marginLeft: '-500px !important',
                                    [theme.breakpoints.down('lg')]: {
                                        width: '850px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-425px !important',
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        width: '780px',
                                        maxWidth: 'inherit',
                                        marginLeft: '-390px !important',
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '70vw',
                                        maxWidth: '672px',
                                        marginLeft: '-35vw !important',
                                        display: 'block',
                                    },
                                    [theme.breakpoints.down('xs')]: {
                                        width: '80vw',
                                        marginLeft: '-40vw !important',
                                        display: 'block',
                                    },
                                }
                            }
                        },
                    },
                    '&.hide': {
                        animationDuration: '800ms',
                        animationName: '$option6HClose',
                        transform: 'translateX(calc((100% / 6) * 5))',
                        [theme.breakpoints.down('sm')]: {
                            animationDuration: '800ms',
                            animationName: '$option6Close',
                            transform: 'translateY(calc((100% / 6) * 5))',
                        },
                        '& .option__content': {
                            '&>img': {
                                [theme.breakpoints.down('sm')]: {
                                    bottom: '0',
                                    left: '0',
                                },
                            }
                        },
                    }
                },
            },
            /*'& .option_close': {
                position: 'absolute',
                top: '20px',
                right: '10px',
                display: 'none',
                zIndex: '3',
                '&.show': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            },*/
            '& .option_close': {
                position: 'absolute',
                margin: 'auto',
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                top: '30px',
                right: '30px',
                zIndex: '3',
                display: 'none',
                backgroundColor: theme.palette.black.t_24,
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                border: '0.5px solid',
                borderColor: 'rgba(255, 255, 255, 0.15)',
                '&.show': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                '& .leftright': {
                    height: '2px',
                    width: '20px',
                    position: 'absolute',
                    marginTop: '0',
                    borderRadius: '2px',
                    transform: 'rotate(45deg)',
                    backgroundColor: theme.palette.white.main,

                },
                '& .rightleft': {
                    height: '2px',
                    width: '20px',
                    position: 'absolute',
                    marginTop: '0',
                    borderRadius: '2px',
                    transform: 'rotate(-45deg)',
                    backgroundColor: theme.palette.white.main,
                }
            }
        },
    }
}));
