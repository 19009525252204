import React from "react";
import { Grid } from '@material-ui/core';

const defaultStyle = {
    height: "auto",
    width: "100vw",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: '100%',
};

export default function ContainerComponent({ reff, style = defaultStyle, className, children }) {
    return <Grid ref={reff} container={false} style={{ ...style }} className={className}>
        {children}
    </Grid>
}   
