import { makeStyles } from "@material-ui/core/styles";
import Colors from '@Constants-Project/colors';

export const testimoniesStyles = makeStyles((theme) => ({
    divContainer: {
        alignItems: 'center !important',
        width: '80vw !important',
        height: 'auto !important',
        padding:'20px !important',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding:'20px 10px !important',
            width: '95vw !important',
        },
    },
    containerT: {
        padding: '50px 0 20px',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            padding: '40px 0',
        },
        '& .containterTexts': {
            display: 'inline-flex',
            paddingLeft: '10%',
            width: '90%',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0',
                width: '100%',
            },
            '& h1': {
                width: '100%',
                display: 'block',
                textAlign: 'left',
                fontSize: '48px',
                lineHeight: '50.2px',
                maxWidth: '700px',
                marginBottom: '0px',
                height: 'auto',
                marginTop: '-20px',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '36px',
                    lineHeight: '37.7px',
                },
                [theme.breakpoints.down('md')]: {
                    fontSize: '30px',
                    lineHeight: '31.4px',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '30px',
                    lineHeight: '31.4px',
                },
                [theme.breakpoints.down('xs')]: {
                    textAlign: 'center',
                    fontSize: '20px',
                    lineHeight: '22.9px',
                    marginBottom: '25px',
                    marginTop: '0px',
                    padding: '0px 10px',
                },
            },
            '& p': {
                width: '100%',
                display: 'block',
                textAlign: 'left',
                fontSize: '18px',
                lineHeight: '20.8px',
                maxWidth: '400px',
                height: 'auto',
                [theme.breakpoints.down('md')]: {
                    fontSize: '16px',
                    lineHeight: '18.6px',
                },
                [theme.breakpoints.down('xs')]: {
                    textAlign: 'center',
                    fontSize: '20px',
                    lineHeight: '22.9px',
                    marginBottom: '25px',
                    marginTop: '0px',
                    padding: '0px 10px',
                },
            }
        },
        '& h1': {
            textAlign: 'left',
            marginTop: '20px',
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center !important',
                marginBottom: '25px !important',
                marginTop: '0px',
                padding: '0px 10px',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontSize: '20px',
                lineHeight: '22.9px',
                marginBottom: '25px',
                marginTop: '0px',
                padding: '0px 10px',
            },
        },
        '& p': {
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '40px',
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'justify',
                fontSize: '14px',
                lineHeight: '16.6px',
                padding: '0px 15px',
                marginBottom: '40px',
            },
        },
        '& p.mb': {
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '20px',
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: '20px',
            },
        },
        '& .mobileTestimony': {
            textAlign: 'center',
            padding: '0px -20px',
            '& .swiper-button-next': {
                color: theme.palette.gray.gray_A7,
                right: '-5px',
                [theme.breakpoints.down('xs')]: {
                    right: '-8px',
                },
                '&:after': {
                    fontSize: '20px',
                    fontWeight: 'bolder'
                }
            },
            '& .swiper-button-prev': {
                color: theme.palette.gray.gray_A7,
                left: '-5px',
                [theme.breakpoints.down('xs')]: {
                    left: '-8px',
                },
                '&:after': {
                    fontSize: '20px',
                    fontWeight: 'bolder'
                }
            },
            '& .showTestimony': {
                margin: '10px auto',
                height: '300px',
                width: '300px',
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                padding: '60px 55px 55px 55px',
                position: 'relative',
                '& a': {
                    width: '20px',
                    height: '20px',
                    position: 'absolute',
                    top: '40px',
                    right: '60px',
                    cursor: 'pointer',
                    '& img': {
                        width: '20px',
                        height: '20px',
                        position: 'relative',
                    },
                },
                '& h1': {
                    textAlign: 'left !important',
                    fontSize: '18px',
                    lineHeight: '18.8px',
                    height: 'auto',
                    marginBottom: '0px !important',
                    color: theme.palette.white.main + '!important',
                    padding: '0',
                    [theme.breakpoints.down('sm')]: {
                        marginTop: '0px',
                    },
                    [theme.breakpoints.down('xs')]: {
                        padding: '0px',
                    },
                },
                '& h2': {
                    textAlign: 'left',
                    fontSize: '16px',
                    marginTop: '0px',
                    marginBottom: '15px !important',
                    color: theme.palette.white.main + '!important',
                    [theme.breakpoints.down('xs')]: {
                        padding: '0px',
                    },
                },
                '& p': {
                    textAlign: 'left',
                    fontSize: '14px',
                    lineHeight: '14,8px',
                    color: theme.palette.white.main + '!important',
                    [theme.breakpoints.down('xs')]: {
                        padding: '0px',
                    },
                }
            }
        }
    },
    imageContainer: {
        position: 'relative',
    },
    imageContainerT: {
        height: '250px',
        maxWidth: '500px',
        margin: '0px auto 30px',
        [theme.breakpoints.down('md')]: {
            height: '200px',
            maxWidth: '420px',
        },
        '& div.imageContainerDivT': {
            height: '230px',
            position: 'relative',
            textAlign: 'center',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                height: '180px',
            },
            '& img': {
                width: '145px',
                height: '145px',
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                [theme.breakpoints.down('md')]: {
                    width: '115px',
                    height: '115px',
                },
                [theme.breakpoints.down('xs')]: {
                    padding: '0px',
                },
            },
        },
        '& img': {
            maxWidth: '145px',
            margin: 'auto',
            [theme.breakpoints.down('md')]: {
                maxWidth: '115px',
            },
        }
    },
    imageBottom: {
        bottom: '-10px',
        position: 'absolute'
    },
    imageTop: {
        top: '0px',
        position: 'absolute'
    },
    circleSepia: {
        borderRadius: '50%',
        webkitBorderRadius: '50%',
        mozBorderRadius: '50%',
        margin: 'auto',
        width: '100%',
        maxWidth: '147px',
        height: '100%',
        maxHeight: '147px',
        position: 'absolute',
        backgroundColor: theme.palette.sepia.main,
        bottom: '4px',
        cursor: 'pointer',
        mixBlendMode: 'color',
        [theme.breakpoints.down('md')]: {
            maxWidth: '117px',
            maxHeight: '117px',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            opacity: '1',
        },
    },
    click: {
        cursor: 'pointer',
    },
    title: {
        position: 'absolute',
        bottom: '0px',
        textAlign: 'center !important',
        width: '120px',
        top: '50%',
        left: '50%',
        marginLeft: '-60px',
        marginTop: '-9px !important',
        marginBottom: 'inherit !important',
        height: '0px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-68px',
        },
        '&.black': {
            color: theme.palette.black.main + '!important',
        }
    },
    showCircleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 19,
    },
    showCircle: {
        transition: "1s",
        display: "flex",
        flexDirection: "column",
        padding: "35px 65px 35px 65px",
        justifyContent: 'center',
        // alignItems: 'center',
        width: "320px",
        height: "320px",
        borderRadius: "50%",
        webkitBorderRadius: "50%",
        mozBorderRadius: "50%",
        backgroundColor: Colors.primary,
        position: "absolute",
        marginLeft: "80px",
        marginTop: "95px",
        [theme.breakpoints.down('md')]: {
            padding: "35px 45px 35px 45px",
            width: "260px",
            height: "260px",
            marginLeft: "70px",
            marginTop: "86px",
        },
        '& .name': {
            fontSize: '16px'
        },
        '& .title': {
            fontSize: '15px'
        },
        '& .body': {
            fontSize: '13px',
            [theme.breakpoints.down('md')]: {
                maxHeight: '100px',
                fontSize: '13px',
                overflow: 'hidden',
            },
        },
        '& a': {
            paddingRight: '15px',
            position: 'absolute',
            width: '20px',
            height: '20px',
            top: '60px',
            right: '70px',
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                top: '55px',
                right: '50px',
            },
            '& img': {
                width: '14px',
                height: '13.9px'
            }
        }

    },
    zIndex20: {
        "z-index": "20"
    },
    displayNone: {
        display: "none"
    },
    imageCarrousel: {
        height: '125px',
        textAlign: 'center',
        '& img': {
            width: '117px',
            height: '117px',
            borderRadius: '50%',
            webkitBorderRadius: '50%',
            mozBorderRadius: '50%',
            marginTop: '4px',
            marginLeft: '-2px',
        },
        '& div': {
            width: '121px',
            height: '121px',
            borderRadius: '50%',
            webkitBorderRadius: '50%',
            mozBorderRadius: '50%',
            left: '50%',
            marginLeft: '-59px',
            mixBlendMode: 'color',
        },
        '& h1': {
            [theme.breakpoints.down('sm')]: {
                width: '80%',
                margin: 'auto 10%',
                left: '0',
                textAlign: 'center',
                padding: '0',
            },
        }
    }
}));
