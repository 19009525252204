import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import $ from "jquery";

export default class ImageSequence extends Component {
    render() {
        const { scroll } = this.props;
        const verticalMove = (id) => {
            let secPosition = $('#section7').position();
            let secHeight = $('#section7').height();
            let secWidth = $('#section7').width();
            let top = 0;
            if (secPosition !== undefined && secPosition.hasOwnProperty('top')) {
                if (!isMobile || window.screen.width < window.screen.height) {
                    if (secWidth > 1280) {
                        top = (secPosition.top - (secHeight * (1.1)) + (10*id));
                    } else if (secWidth > 960) {
                        top = (secPosition.top - (secHeight * (0.95)) + (10*id));
                    } else if (secWidth > 600) {
                        top = (secPosition.top - (secHeight * (1.4)) + (10*id));
                    } else {
                        top = (secPosition.top - (secHeight * (0.35)) + (10*id));
                    }
                } else {
                    if (secWidth > 1280) {
                        top = (secPosition.top - (secHeight * (0.5)) + (10*id));
                    } else if (secWidth > 960) {
                        top = (secPosition.top - (secHeight * (0.5)) + (10*id));
                    } else if (secWidth > 600) {
                        top = (secPosition.top - (window.screen.height) + (10*id));
                    } else {
                        top = (secPosition.top - (window.screen.height) + (10*id));
                    }
                }
            }
            return top;
        };
        return (
            <>
                <img className={`phoneBig ${(scroll < verticalMove(0)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 201.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(0)) && (scroll < verticalMove(1)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 201.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(1)) && (scroll < verticalMove(2)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 202.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(2)) && (scroll < verticalMove(3)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 203.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(3)) && (scroll < verticalMove(4)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 204.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(4)) && (scroll < verticalMove(5)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 205.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(5)) && (scroll < verticalMove(6)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 206.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(6)) && (scroll < verticalMove(7)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 207.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(7)) && (scroll < verticalMove(8)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 208.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(8)) && (scroll < verticalMove(9)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 209.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(9)) && (scroll < verticalMove(10)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 201.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(10)) && (scroll < verticalMove(11)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 211.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(11)) && (scroll < verticalMove(12)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 212.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(12)) && (scroll < verticalMove(13)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 213.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(13)) && (scroll < verticalMove(14)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 214.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(14)) && (scroll < verticalMove(15)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 215.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(15)) && (scroll < verticalMove(16)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 216.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(16)) && (scroll < verticalMove(17)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 217.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(17)) && (scroll < verticalMove(18)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 218.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(18)) && (scroll < verticalMove(19)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 219.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(19)) && (scroll < verticalMove(20)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 220.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(20)) && (scroll < verticalMove(21)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 221.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(21)) && (scroll < verticalMove(22)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 222.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(22)) && (scroll < verticalMove(23)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 223.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(23)) && (scroll < verticalMove(24)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 224.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(24)) && (scroll < verticalMove(25)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 225.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(25)) && (scroll < verticalMove(26)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 226.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(26)) && (scroll < verticalMove(27)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 227.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(27)) && (scroll < verticalMove(28)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 228.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(28)) && (scroll < verticalMove(29)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 229.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(29)) && (scroll < verticalMove(30)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 230.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(30)) && (scroll < verticalMove(31)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 231.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(31)) && (scroll < verticalMove(32)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 232.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(32)) && (scroll < verticalMove(33)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 233.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(33)) && (scroll < verticalMove(34)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 234.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(34)) && (scroll < verticalMove(35)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 235.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(35)) && (scroll < verticalMove(36)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 236.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(36)) && (scroll < verticalMove(37)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 237.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(37)) && (scroll < verticalMove(38)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 238.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(38)) && (scroll < verticalMove(39)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 239.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(39)) && (scroll < verticalMove(40)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 240.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(40)) && (scroll < verticalMove(41)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 241.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(41)) && (scroll < verticalMove(42)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 242.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(42)) && (scroll < verticalMove(43)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 243.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(43)) && (scroll < verticalMove(44)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 244.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(44)) && (scroll < verticalMove(45)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 245.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(45)) && (scroll < verticalMove(46)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 246.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(46)) && (scroll < verticalMove(47)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 247.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(47)) && (scroll < verticalMove(48)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 248.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(48)) && (scroll < verticalMove(49)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 249.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(49)) && (scroll < verticalMove(50)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 250.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(50)) && (scroll < verticalMove(51)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 251.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(51)) && (scroll < verticalMove(52)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 252.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(52)) && (scroll < verticalMove(53)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 253.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(53)) && (scroll < verticalMove(54)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 254.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(54)) && (scroll < verticalMove(55)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 255.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(55)) && (scroll < verticalMove(56)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 256.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(56)) && (scroll < verticalMove(57)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 257.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(57)) && (scroll < verticalMove(58)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 258.png`)} />
                <img className={`phoneBig ${(scroll >= verticalMove(58)) ? 'showPhone' : 'hidePhone'}`} alt={'Images.celPhoneMoving'} src={require(`@Assets-Project/images/PhoneSequence/Comp 259.png`)} />
            </>
        )
    }
}
