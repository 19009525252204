import React from "react";
import Images from '@Constants-Project/images';
import { Container, Link as Link2, Grid, Box, Typography } from "@material-ui/core";
import { FooterStyles } from '@Styles-Project/theme/layout/footer';
import { Link } from "react-router-dom";
import {
    URL_AUTOCOMPRA,
    LEGALES_CONDITIONS,
    LEGALES_TYC,
    LEGALES_INFO,
    LEGALES_DEBIT,
    LEGALES_IMAGE,
    LEGALES_DOCS_CREDITO
} from '@Constants-Project/constants';

export default () => {
    const date = new Date();
    const fullYear = date.getFullYear();
    const fClasses = FooterStyles();

    const FancyLink = React.forwardRef((props) => {
        return <Link2 color="inherit" href={props.ref} {...props} >{props.children}</Link2>
    })

    return (
        <Box className={`${fClasses.footer}`}>
            <Container maxWidth={"md"}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} md={4} className={`${fClasses.fColumn}`}>
                        <Box>
                            <Typography variant={'h5'} className='orange'>Comunícate con nosotros</Typography>
                            <Grid container direction={"column"}>
                                <Link2 href="/hablanos/1" component={FancyLink} style={{cursor: 'pointer'}} ><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Contáctanos</Typography></Link2>
                                <Link2 href="/que-es-polen" component={FancyLink} style={{cursor: 'pointer'}} ><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Acerca de nosotros</Typography></Link2>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4} className={`${fClasses.fColumn} ${fClasses.column2}`}>
                        <Box>
                            <Typography variant={'h5'} className='orange'>Accesos Rápidos</Typography>
                            <Grid container direction={"column"}>
                                <Link2 href="/hablanos/1" component={FancyLink} style={{cursor: 'pointer'}} ><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Preguntas frecuentes</Typography></Link2>
                                <Link2 href="/eventos" component={FancyLink} style={{cursor: 'pointer'}} ><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Eventos</Typography></Link2>
                                <Link2 href={URL_AUTOCOMPRA} target={'_blank'} component={FancyLink} ><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Simulador</Typography></Link2>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} className={`${fClasses.fColumn} ${fClasses.column3}`}>
                        <Box>
                            <Typography variant={'h5'} className='orange'>Legales</Typography>
                            <Grid container direction={"column"}>
                                <Link2 href={'https://media.polen.com.co/documentos/Clausulado_Contrato_Autofinanciamiento_Comercial_Polen.pdf'} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Condiciones generales</Typography></Link2>
                                <Link2 href={LEGALES_CONDITIONS} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Condiciones de uso</Typography></Link2>
                                <Link2 href={LEGALES_INFO} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Tratamiento de datos</Typography></Link2>
                                <Link2 href={LEGALES_TYC} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Consulta de información</Typography></Link2>
                                <Link2 href={LEGALES_DEBIT} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Débito automático</Typography></Link2>
                                <Link2 href={LEGALES_IMAGE} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Uso de imagen</Typography></Link2>
                                <Link2 href={LEGALES_DOCS_CREDITO} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Documentos de crédito</Typography></Link2>
                                <Link2 href={'https://media.polen.com.co/documentos/Exclusiones_Seguro_Vida.pdf'} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Exclusiones seg. Vida</Typography></Link2>
                                <Link2 href={'https://media.polen.com.co/documentos/Cartilla_SAPAC.pdf'} target={'_blank'}><Typography variant={'h5'} className={`${fClasses.h5Link}`}>Cartilla SAPAC</Typography></Link2>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={fClasses.borderTop} >
                        <Grid container>
                            <Grid className={'hiden logo'} item xs={12} md={4}>
                                <Link to="/"><img alt={Images.logoFooter} src={Images.logoFooter} /></Link>
                                <Typography variant={'body1'}>Sociedad Administradora de Planes<br></br>de Autofinanciamiento Comercial.</Typography>
                            </Grid>
                            <Grid className='divRowReverse' item xs={12} md={4}>
                                <Box className={`${fClasses.flexBottomContainer} subDivRowReverse`}>
                                    <img className='superintendencia' alt='Logo Super' src={Images.superintendenciadesociedades} />
                                </Box>
                            </Grid>
                            <Grid className='contentSocialNetworks' item xs={12} md={4}>
                                <div>
                                    <a href="https://www.facebook.com/PolenColombia/" target="_blank">
                                        <img alt='Facebook' src={Images.facebook} />
                                    </a>
                                    <a href="https://www.instagram.com/polen.colombia/" target="_blank">
                                        <img alt='Instagram' src={Images.instagram} />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCTQeAOftTMq3nwleeOFC_DA?view_as=subscriber" target="_blank">
                                        <img alt='Yooutube' src={Images.youtube} />
                                    </a>
                                    <a href="https://wa.me/+573213095057" target="_blank">
                                        <img alt='WhatsApp' src={Images.whatsapp} />
                                    </a>
                                    <a href="https://www.tiktok.com/@polencolombia" target="_blank">
                                        <img alt='WhatsApp' src={Images.titktok} />
                                    </a>
                                </div>
                                <Typography variant={'h5'} className={`${fClasses.h5Link} ${fClasses.alignLeftBottom} deleteAbsolute`}>&copy; Copyright {fullYear} Polen Colombia.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
