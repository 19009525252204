import { types } from '../types/types';

export const globalReducer = (state = { }, action ) => {
    switch (action.type) {
        case types.setGoal:
            return{
                ...state,
                goal: action.payload.goalId
            };
        default:
            return state;
    }
};
