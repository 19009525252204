import { types } from "../types/types";

export const _login = ( userName, password ) => ({
    type: types.login,
    payload: {
        userName,
        password
    }
});

export const _logout = ( ) => ({
    type: types.logout,
    payload: {
        logged: false
    }
});