import React from 'react';
import { Box } from '@material-ui/core';
import PolenIs from '@Components-Project/whatIsPolenComponents/polenIs';
import WhatPollenDoingDoforYou from '@Components-Project/whatIsPolenComponents/whatPollenDoingDoforYou';
import MakeYourGoalOurGoal from '@Components-Project/whatIsPolenComponents/makeYourGoalOurGoal';
import HowWorking from '@Components-Project/whatIsPolenComponents/howWorking';
import NewAchievements from '@Components-Project/whatIsPolenComponents/newAchievements';
import Doubts from '@Components-Project/whatIsPolenComponents/doubts';
import WhatAreYouWaitingForToStartAchievingYourGoal from '@Components-Project/whatIsPolenComponents/whatAreYouWaitingForToStartAchievingYourGoal';
import { Help } from "@Components-Project/homeComponents";
import { stepsStyles } from "@Styles-Project/theme/layout/steps/steps";
import { useSelector } from "react-redux";
import {WithPollenAchievesGoals} from "@Components-Project/homeComponents/withPollenAchievesGoals";
import {ENV} from "@Constants-Project/constants";

export default function WhatIsPolenComponents({ open, handleClose }) {
    const stepClasses = stepsStyles();
    const { OurGoals } = useSelector(state => state.whatIsPolen)

    return (
        <Box style={{ position: 'relative', paddingTop: '85px' }} >
            <Help open={open} handleClose={handleClose} />
            <PolenIs open={open} handleClose={handleClose} />
            <WhatPollenDoingDoforYou />
            <MakeYourGoalOurGoal />
            <HowWorking />
            { OurGoals && OurGoals.length !== 0 && (OurGoals.clientesActivos !== 0 || OurGoals.metasCumplidas !== 0 || OurGoals.adjudicaciones !== 0) ? <NewAchievements /> : null }
            <Doubts />
            <WhatAreYouWaitingForToStartAchievingYourGoal />
        </Box>
    );
}
