import Colors from "@Constants-Project/colors";
import { BeVietnamBold, BeVietnamRegular, BeVietnamMedium, BeVietnamLight, MyriadProRegular } from '../../constants/fonts';
// import { BeVietnamBold, BeVietnamRegular, BeVietnamMedium, BeVietnamLight, MyriadProRegular } from "@Constants-Project/fonts";
import { createMuiTheme } from "@material-ui/core";

export const themePolen = createMuiTheme({
    palette: {
        primary: {
            main: Colors.primary,
            t_9: Colors.primary_9,
            t_15: Colors.primary_15,
            t_70: Colors.primary_70,
            c: Colors.primary_c,
        },
        white: {
            main: Colors.white,
        },
        black: {
            main: Colors.black,
            t_12: Colors.black_12,
            t_24: Colors.black_24,
        },
        gray: {
            gray_F2: Colors.grayF2,
            gray_A7: Colors.grayA7,
            gray_A7_80: Colors.grayA7_80,
            gray_A7_60: Colors.grayA7_60,
            gray_A7_40: Colors.grayA7_40,
            gray_A7_30: Colors.grayA7_30,
            gray_A7_20: Colors.grayA7_20,
            gray_F6: Colors.grayF6,
        },
        sepia: {
            main: Colors.sepia,
        },
        red: {
            main: Colors.red,
        },
        text: {
            disabled: Colors.black_24
        },
        action: {
            disabled: Colors.black_12
        }
    },
    // body: Colors.primary,
    // text: '#363537',
    // toggleBorder: '#FFF',
    // gradient: 'linear-gradient(#39598A, #79D7ED)',
    button: {
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
        color: Colors.white
    },
    typography: {
        fontFamily: [
            'MyriadPro-Regular',
            'BeVietnam-Medium',
            'BeVietnam-Bold',
            'BeVietnam-Regular',
            'BeVietnam-Light',

            'MyriadProRegular',
            'BeVietnamRegular',
            'BeVietnamBold',
            'Arial',
        ].join(','),
        h1: {
            fontSize: '24px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: '25.1px',
            letterSpacing: 'normal',
            textAlign: 'center',
            fontFamily: 'BeVietnam-Bold',
            width: "100%",
            height: "40px"
        },
        h2: {
            fontFamily: 'BeVietnam-Medium',
            fontSize: '24px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '26.4px',
            letterSpacing: 'normal',
            textAlign: 'center',
            width: "100%"
        },
        h3: {
            fontFamily: 'BeVietnam-Bold',
            fontSize: '36px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            fontStyle: 'normal',
            textAlign: 'center',
            width: "100%"
        },
        h4: {
            fontFamily: 'BeVietnam-Bold',
            fontSize: '96px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            width: "100%"
        },
        h5: {
            fontFamily: 'BeVietnam-Bold',
            fontSize: '12px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: '14.4px',
            letterSpacing: 'normal',
            width: '100%'
        },
        h6: {
            fontFamily: 'BeVietnam-Regular',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '18.4px',
            letterSpacing: 'normal',
            marginBottom: '16px',
            width: '100%'
        },
        body1: {
            fontFamily: 'BeVietnam-Regular',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '16.6px',
            letterSpacing: 'normal',
            marginTop: '5px',
            marginBottom: '5px',
            width: "100%"
        },
        body2: {
            fontFamily: 'BeVietnam-Regular',
            fontSize: '20px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            lineHeight: '22.8px',
            letterSpacing: 'normal',
            marginTop: '5px',
            marginBottom: '5px',
            width: "100%"
        },
        subtitle1: {
            fontFamily: 'BeVietnam-Light',
            fontSize: '18px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '20.4px',
            letterSpacing: 'normal',
            marginBottom: '16px',
            width: "100%"
        },
        overline: {
            fontFamily: 'BeVietnam-Regular',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '18.8px',
            letterSpacing: 'normal',
            textTransform: 'none',
            width: "100%"
        },
        TBeVietnamBold: 'BeVietnam-Bold',
        TBeVietnamRegular: 'BeVietnam-Regular',
        TBeVietnamLight: 'BeVietnam-Light',
        TBeVietnamMedium: 'BeVietnam-Medium',
        TMyriadProRegular: 'MyriadPro-Regular'
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [MyriadProRegular, BeVietnamBold, BeVietnamRegular, BeVietnamLight, BeVietnamMedium],
            },
        },
    },
});
