import { makeStyles } from "@material-ui/core/styles";

export const FooterStyles = makeStyles((theme) => ({
    footer: {
        background: theme.palette.black.main,
        color: theme.palette.white.main,
        margin: '0',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            padding: '30px 10px 10px 10px',
        },
    },
    fColumn: {
        marginTop: '31px',
        padding: '15px',
        display: 'flex',
        [theme.breakpoints.up('md')]: {

        },
        '& h5': {
            fontSize: '12px',
            marginBottom: '12px',
            lineHeight: '12.6px',
            '&.orange': {
                color: theme.palette.primary.main,
            }
        },
        '& a': {
            fontFamily: theme.typography.TBeVietnamRegular,
            fontSize: '12px'
        },
    },
    h5Link: {
        fontSize: '10px !important',
        color: theme.palette.white.main,
        lineHeight: '10.5px',
        marginBottom: '7px !important',
        fontFamily: theme.typography.TBeVietnamRegular,
    },
    column2: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center'
        }
    },
    column3: {
        marginTop: '0px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            marginTop: '31px'
        }
    },
    borderTop: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        paddingTop: '15px',
        borderTop: '2px #fe5b02 solid !important',
        margin: '15px auto 5px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
        '& .divRowReverse': {
            [theme.breakpoints.down('sm')]: {
                width: '100% !important',
                maxWidth: '100% !important',
                height: 'auto',
                '& .subDivRowReverse': {
                    flexDirection: 'row-reverse',
                    display: 'flex',
                    alignItems: 'center',
                    height: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                    '& .deleteAbsolute': {
                        position: 'static !important'
                    }
                }
            },
        },
        '& .hiden': {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            },
        },
        '& .contentSocialNetworks': {
            justifyContent: 'center',
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                alignItems: 'center',
            },
            '& div': {
                width: '140px',
            },
            '& img': {
                width: '24px',
                margin: '0px 2px',
            },
            '& h5': {
                maxWidth: '140px',
                [theme.breakpoints.down('sm')]: {
                    display: 'block',
                    position: 'relative',
                    bottom: '0'
                },
            }
        },
        '& .orangeText': {
            color: theme.palette.primary.main,
            width: 'auto'
        },
        '& .logo': {
            alignItems: 'center',
            display: 'inline-flex',
            '& p': {
                display: 'inline-block',
                fontSize: '9px',
                lineHeight: '11px',
                width: 'auto',
                marginLeft: '10px',
            }
        }
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'right',
    },
    flexBottomContainer: {
        display: 'block',
        height: '80px',
        position: 'relative',
        '& .superintendencia': {
            width: 'auto',
            height: '50px',
        },
        [theme.breakpoints.up('md')]: {
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    alignLeftBottom: {
        position: 'absolute',
        width: '100%',
        bottom: '10px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            bottom: '0',
            width: '200px',
        }
    }
}));
