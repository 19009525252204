'use strict'
import { SERVER } from '@Constants-Project/constants';
const defaultHeaders = { "Accept": "application/json", "Content-Type": "application/json" };

async function GET_BT(url, CallBackAuthorized, params = {}) {
    let Url = url + '?' + new URLSearchParams({ ...params });
    if (!url) return console.warn('Param url not found');
    let General = { method: 'GET', headers: defaultHeaders };
    return await sendGenericRequest(Url, General, CallBackAuthorized);
}

async function GET(url, CallBackAuthorized, params = {}) {
    let varParams = {
        isActive: true,
        Active: true,
        ...params
    }
    let Url = url + '?' + new URLSearchParams(varParams);
    if (!url) return console.warn('Param url not found');
    let General = { method: 'GET', headers: defaultHeaders };
    return await sendRequest(Url, General, CallBackAuthorized);

}

async function POSTv2(url, body, CallBackAuthorized) {

    if (!url) return console.warn('Param url not found');
    else if (!body) return console.warn('Param body not found');
    else if (body.length <= 0) return console.warn('Param body is empty');
    let General = { method: 'POST', headers: defaultHeaders, body: JSON.stringify(body) };
    console.log('General -----', General)
    return await sendRequestV2(url, General, CallBackAuthorized);

}
async function sendRequestV2(url, features, CallBackAuthorized) {

    try {
        return sendGenericRequest(url, features, CallBackAuthorized);
    } catch (error) {
        return { success: false, message: `${error}`, status: "Catch" };
    }
}

async function POST(url, body, CallBackAuthorized) {

    if (!url) return console.warn('Param url not found');
    else if (!body) return console.warn('Param body not found');
    else if (body.length <= 0) return console.warn('Param body is empty');
    let General = { method: 'POST', headers: defaultHeaders, body: JSON.stringify(body) };
    return await sendRequest(url, General, CallBackAuthorized);

}

async function POSTG(url, body, CallBackAuthorized) {
    if (!url) return console.warn('Param url not found');
    else if (!body) return console.warn('Param body not found');
    else if (body.length <= 0) return console.warn('Param body is empty');
    let General = { method: 'POST', headers: defaultHeaders, body: JSON.stringify(body) };
    return await sendGenericRequest(url, General, CallBackAuthorized);
}

async function PUT(url, body, CallBackAuthorized) {
    if (!url) return console.warn('Param url not found');
    else if (!body) return console.warn('Param body not found');
    else if (body.length <= 0) return console.warn('Param body is empty');
    let General = { method: 'PUT', headers: defaultHeaders, body: JSON.stringify(body) };
    return await sendRequest(url, General, CallBackAuthorized);

}

async function DELETE(url, CallBackAuthorized, body = {}) {

    if (!url) return console.warn('Param url not found');
    let General = { method: 'DELETE', headers: defaultHeaders, body: JSON.stringify(body) };
    return await sendRequest(url, General, CallBackAuthorized);

}

async function sendRequest(url, features, CallBackAuthorized) {

    try {
        return sendGenericRequest(SERVER + url, features, CallBackAuthorized);
    } catch (error) {
        return { success: false, message: `${error}`, status: "Catch" };
    }
}

async function sendGenericRequest(url, features, CallBackAuthorized) {

    try {
        const response = await fetch(url, features);
        // console.log(response);
        switch (response.status) {
            //It indicates that the REST API successfully carried out whatever action the client requested and that no more specific code in the 2xx series is appropriate.
            case 200: return await response.json();
            //A 401 error response indicates that the client tried to operate on a protected resource without providing the proper authorization. It may have provided the wrong credentials or none at all. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource.
            case 401: CallBackAuthorized({ success: false, message: "Unauthorized", status: 401 });
            //A 403 error response indicates that the client’s request is formed correctly, but the REST API refuses to honor it, i.e. the user does not have the necessary permissions for the resource. A 403 response is not a case of insufficient client credentials; that would be 401 (“Unauthorized”).
            case 403: CallBackAuthorized({ success: false, message: "Forbidden", status: 403 });
            default:
                try {
                    let a = await response.json();
                    return { success: false, message: `${a.msg}`, status: response.status };
                } catch (error) {
                    return { success: false, message: "A communication error has occurred, please try again", status: response.status };
                }
        }
    } catch (error) {
        return { success: false, message: `${error}`, status: "Catch" };
    }
}


const API = { GET, POST, POSTv2, PUT, DELETE, POSTG, GET_BT };

export default API;



// Ellos confian en nosotros
// Post
// GET
// ​/v1​/api​/Post

// Testimonios
// Testimony
// GET
// ​/v1​/api​/Testimony
