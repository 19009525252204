import { types } from '../types/types';

export const meetingReducer = (state = {}, action) => {
    switch (action.type) {
        case types.setMeeting:
            return {
                ...state,
                Meeting: action.payload.Meeting
            };
        case types.setDateTarget:
            return {
                ...state,
                DataTarget: action.payload.DataTarget
            };
        case types.saveIntervalToClear:
            return {
                ...state,
                IntervalToClear: action.payload.IntervalToClear
            };
        default:
            return state;
    }
};
