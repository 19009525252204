import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import HomeComponents from '@Components-Project/homeComponents';
import api from '@Constants-Project/api';
import { useDispatch } from 'react-redux';
import { setPosts, setTestimony, setBrands } from '@Redux-Project/actions/parallaxActions';

import { Helmet } from "react-helmet";

export default function Home() {

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(!open);
    const dispatch = useDispatch();

    const getPost = async () => {
        let params = { Order: 'ASC' };
        let response = await api.GET('api/Post', (e) => { console.log('e => ', e) }, params);
        if (response.success) dispatch(setPosts(response.data));
        else alert(response.message + ' Code: ' + response.status)
    }

    const getTestimony = async () => {
        let params = { Order: 'ASC' };
        let response = await api.GET('api/Testimony', (e) => { console.log('e => ', e) }, params);
        if (response.success) dispatch(setTestimony(response.data));
        else alert(response.message + ' Code: ' + response.status)
    }

    const getBrands = async () => {
        let params = { Order: 'ASC' };
        let response = await api.GET('api/Brands', (e) => { console.log('e => ', e) }, params);
        if (response.success) dispatch(setBrands(response.data));
        else alert(response.message + ' Code: ' + response.status)
    }

    React.useEffect(() => {
        getPost();
        getTestimony();
        getBrands();
    }, [])

    return (
        <>
            <Helmet
                title={"Polen, en comunidad la logras"}
                meta={[
                    {
                        name: 'description',
                        content: "Polen, en comunidad la logras",
                    }
                ]}
            />
            <Box style={{ display: 'block' }}>
                <HomeComponents open={open} handleClose={handleClose} />
            </Box>
        </>
    );
}
