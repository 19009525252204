import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import React from "react";

export const inputStyles = makeStyles((theme) => ({
    input: {
        // borderRadius: '4px',
        borderBottom: 'solid 2px #a7a8aa',
        // backgroundColor: '#ffffff',
        // padding: '5px 5px',
        margin: '10px 0px 20px',
    },
    inputContainer: {
        paddingTop: '10px',
        '& label + .MuiInput-formControl': {
            marginTop: '12px',
            // marginBottom: '25px'
        },
        "& .MuiFormLabel-asterisk": {
            color: 'rgba(254,91,2,1)'
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#000 !important'
        },
        '& .MuiFormHelperText-root': {
            fontSize: '12px',
            position: 'absolute',
            bottom: '5px',
        },
        '& .MuiInputBase-input': {
            color: 'white'
        },
    },
    inputContainerHome: {
        paddingTop: '10px',
        '& label + .MuiInput-formControl': {
            marginTop: '12px',
            borderBottom: 'none'
            // marginBottom: '25px'
        },
        "& .MuiFormLabel-asterisk": {
            color: 'rgba(254,91,2,1)'
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#000 !important'
        },
        '& .MuiFormHelperText-root': {
            fontSize: '12px',
            position: 'absolute',
            bottom: '5px',
        },
        '& .MuiInputBase-input': {
            color: 'white',
            border: '1px solid',
            borderColor: theme.palette.white.main,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '5px',
            webkitBorderRadius: '5px',
            mozBorderRadius: '5px',
            padding: '10px',
            margin: '0px 10px'
        },
    },
    inputTalkUs: {
        borderRadius: '4px',
        border: 'solid 1px #a7a8aa',
        backgroundColor: '#ffffff',
        padding: '5px',
        margin: '10px 0px 20px',
    },
    inputContainerTalkUs: {
        paddingTop: '10px',
        '& label + .MuiInput-formControl': {
            marginTop: '12px',
            marginBottom: '25px'
        },
        "& .MuiFormLabel-asterisk": {
            color: 'rgba(254,91,2,1)'
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#000 !important'
        },
        '& .MuiFormHelperText-root': {
            fontSize: '12px',
            position: 'absolute',
            bottom: '5px',
        }
    },
    label: {
        textAlign: 'left',
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        lineHeight: '22.8px',
        letterSpacing: 'normal',
        color: theme.palette.white.main,
        width: '100%',
        marginTop: '0px'
    },
    labelTalkUs: {
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        lineHeight: '22.8px',
        letterSpacing: 'normal',
        color: theme.palette.black.main,
        width: '100%',
        marginTop: '0px'
    },
    labelHome: {
        textAlign: 'left',
        fontSize: '20px !important',
        fontWeight: 'normal',
        fontStretch: 'normal',
        lineHeight: '22.8px !important',
        letterSpacing: 'normal',
        color: theme.palette.white.main,
        width: '100%',
        marginTop: '0px',
        marginLeft: '10px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important',
            lineHeight: '16.6px !important',
        },
    },
    selectContainer: {
        width: '100%',
        height: '0px',
        marginTop: '0px',
        '&:before': {
            borderBottom: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
        },
        '&.MuiInput-underline:after': {
            borderBottom: 'none'
        },
        '& .MuiSelect-root': {
            borderRadius: '4px',
            border: 'solid 1px',
            borderColor: theme.palette.gray.gray_A7,
            backgroundColor: theme.palette.white.main,
            width: '100%',
            padding: '10px 5px',
            margin: '10px 0px 20px',
            '& input': {
                display: 'none',

            }
        },
        '& .MuiSelect-icon': {
            top: 'calc(50% - 18px)',
        },
    },
    headerSelectTwo: {
        textAlign: 'left',
        color: theme.palette.white.main,
        width: '100%',
        fontSize: '14px',
        marginTop: '0px',
        fontWeight: 'normal',
        lineHeight: '20.8px',
        fontStretch: 'normal',
        letterSpacing: 'normal'
    },
    selectContainerTwo: {
        width: '100%',
        '&:before': {
            borderBottom: 'none'
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
        },
        '&.MuiInput-underline:after': {
            borderBottom: 'none'
        },
        '& .MuiSelect-root': {

            color: 'white',
            border: '1px solid',
            borderColor: theme.palette.white.main,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '5px',
            webkitBorderRadius: '5px',
            mozBorderRadius: '5px',
            padding: '10px',
            margin: '0px 10px',

            '& input': {
                display: 'none',

            }
        },
        '& .MuiSelect-selectMenu': {
            textAlign: 'left',
            color: 'white',
        },
        '& .MuiSelect-icon': {
            top: 'calc(50% - 15px)',
            right: '20px',
            width: '30px',
            height: '30px'
        },
    },
    inputContainerDate: {
        width: '100%',
        border: '1px solid #a7a8aa',
        height: '40px',
        borderRadius: '5px',
        '& .MuiInput-underline': {
            borderBottom: '0px solid red !important',
            '&:before': {
                borderBottom: '0px solid red !important',
            },
            '&:after': {
                borderBottom: '0px solid red !important',
            }
        },
        '& .MuiFormLabel-root': {
            fontSize: '20px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            lineHeight: '22.8px',
            letterSpacing: 'normal',
            width: '100%',
            marginTop: '-25px'
        },
        '& .MuiInputBase-input': {
            border: '0px solid red',
            padding: '0px',
            marginLeft: '5px',
            marginTop: '-5px'
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#000',
        },
        '& .MuiFormLabel-root  .MuiFormLabel-asterisk': {
            color: 'rgba(254,91,2,1) !important',
            '&:before': {
                color: 'rgba(254,91,2,1) !important',
            },
            '&:after': {
                color: 'rgba(254,91,2,1) !important',
            }
        },
        '& .MuiPaper-root': {
            border: '1px solid red !important',
            marginLeft: '-15px !important',
            width: '35% !important',
            display: 'flex !important',
            justifyContent: 'center !important',
        }
    },
    nonBorder: {
        '& .MuiSelect-root': {
            border: 'none !important',
        },
    },
    myDropzone: {
        border: '1px dotted',
        borderColor: theme.palette.primary.main,
        width: '100%',
        height: '150px',
    }
}));

export const OrangeCheckbox = withStyles({
    root: {
        color: '#fe5b02',
        '&$checked': {
            color: '#fe5b02',
        },
    },
    checked: {},
})((props) => <Checkbox color='default' {...props} />);
