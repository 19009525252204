import { makeStyles } from "@material-ui/core/styles";

export const stepsStyles = makeStyles((theme) => ({
    showMobile: {
        minHeight: '50px',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        '&.pInheriit': {
            position: 'inherit',
            zIndex: '100',
        }
    },
    showDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    boxLinearProgress: {
        marginTop: '45px !important',
    },
    nonLinearProgress: {
        marginTop: '0px',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginTop: '30px',
        }
    },
    withLinearProgress: {
        marginTop: '30px !important',
    },
    LinearMobile: {
        width: '100%'
    },
    mb90: {
        marginBottom: '90px',
    },
    mb15: {
        marginBottom: '15px',
    },
    mb5: {
        marginBottom: '5px',
    },
    mb20: {
        marginBottom: '20px',
    },
    mb0: {
        marginBottom: '0px !important',
    },
    mt0: {
        marginTop: '0px',
    },
    mt5: {
        marginTop: '5px',
    },
    mt35: {
        marginTop: '35px',
    },
    mt15: {
        marginTop: '15px'
    },
    mt20: {
        marginTop: '20px'
    },
    mt25: {
        marginTop: '25px'
    },
    mt30: {
        marginTop: '30px',
    },
    mt70: {
        marginTop: '70px'
    },
    mt90: {
        marginTop: '90px'
    },
    mt218: {
        marginTop: '218px'
    },
    boxBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bubble: {
        width: '100%',
        maxWidth: '353px',
        borderRadius: '56.5px',
        backgroundColor: theme.palette.gray.gray_F2,
        padding: '12px 48px 12px 48px',
        marginTop: '1rem',
        textAlign: 'center',
    },
    bubbleLeft: {
        borderRadius: '56.5px',
        backgroundColor: theme.palette.gray.gray_F2,
        padding: '24px 14px 16px',
        textAlign: 'center',
    },
    littleBubblesBox: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    },
    littleBubblesBoxLeft: {
        width: '80%',
        textAlign: 'center',
        position: 'relative',
        left: '0'
    },
    littleBubblesBoxRight: {
        width: '80%',
        textAlign: 'center',
        position: 'relative',
        left: '20%'
    },
    bubbleCircleLg: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: theme.palette.gray.gray_F2,
        margin: 'auto',
        marginTop: '5px',
    },
    bubbleCircleMd: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: theme.palette.gray.gray_F2,
        margin: 'auto',
        marginTop: '3px',
    },
    bubbleCircleSm: {
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: theme.palette.gray.gray_F2,
        margin: 'auto',
        marginTop: '2px',
        marginBottom: '15px',
    },
    bubbleRight: {
        left: '20px',
        position: 'relative',
    },
    boxBtnNext: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '30px',
        [theme.breakpoints.up('md')]: {
            marginBottom: '50px',
        }
    },
    boxVideo: {
        width: '100%',
    },
    alignBottom: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        padding: '0px 15px',
    },
    modelImage: {
        width: '100%',
        maxWidth: '435px'
    },
    // CheckBox
    ckBoxInline: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-root': {
            marginRight: '0px',
        }
    },
    // ModelContainersStyles
    modelLeft: {
        alignItems: 'flex-end',
        position: 'relative',
        padding: '0px 0px 0px 20px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    },
    modelCenter: {
        textAlign: 'center',
        alignItems: 'flex-end',
        position: 'relative',
        minHeight: '550px',
        height: '100%',
    },
    iconSupport: {
        width: '97px',
        [theme.breakpoints.down('xs')]: {
            width: '100px',
        }
    },
    // STEP 3
    contractSample: {
        padding: '7px',
        justifyContent: 'space-between',
    },
    contractSampleBorder: {
        border: '2px solid #fe5b02',
        borderRadius: '5px',
        position: 'relative',
    },
    contractSampleNumber: {
        backgroundColor: 'rgba(254, 91, 2, 0.2)',
        border: '3px solid #fe5b02',
        borderRadius: '5px',
        width: '53px',
        height: '31px'
    },
    contractSampleDialog: {
        position: 'absolute',
        backgroundColor: 'rgba(254, 91, 2)',
        border: '3px solid',
        borderColor: theme.palette.white.main,
        borderRadius: '5px',
        width: '153px',
        height: '43px',
        right: '13px',
        top: '-20px',
        padding: '2',
    },
    dialog: {
        fontWeight: 'bold',
        margin: 'auto'
    },
    logoContractSample: {
        height: '31px'
    },
    xxxx: {
        display: 'block',
        textAlign: 'center',
        width: '100%',
    },
    anexo: {
        display: 'block',
        width: '100%',
        margin: '5px 0px 0px 0px',
    },
    anexoBold: {
        fontWeight: 'bold',
        display: 'block',
        width: '100%',
        margin: '0px 0px 0px 0px',
    },
    alignCenter: {
        textAlign: "center"
    },
    textColorWhite: {
        color: '#fff'
    },
    sendAgain: {
        border: '0px solid #fe5b02',
        justifyContent: 'flex-end',
        color: 'red'
    },
    sendAgainText: {
        cursor: "pointer",
        color: '#fe5b02',
        textDecorationLine: 'underline',
        marginTop: '-15px',
    },
    Ellipse84: {
        width: '120px',
        height: '120px',
        backgroundColor: '#2ecc71',
        borderRadius: '120px'
    },
    mb30: {
        marginBottom: '30px !important',
    },
    mb10: {
        marginBottom: '10px !important',
    },
    mt10: {
        marginTop: '10px !important',
    },
    mr15: {
        marginRight: '15px !important',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    flex: {
        border: '0px solid red', display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    Rectangle_133: {
        padding: '23px',
        flexDirection: 'column',
        height: '198px',
        borderRadius: '10px',
        boxShadow: '0 4px 4px 0 rgba(141, 141, 141, 0.15)',
        backgroundColor: '#ffffff'
    },
    prevImagesContainer: {
        width: '100%',
        display: 'flex',
        minHeight: '120px',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '30px',
        marginBottom: '20px',
    },
    prevImagesBox: {
        margin: 'auto',
    },
    prevImagesBackground: {
        borderRadius: '10px',
        webkitBorderRadius: '10px',
        backgroundColor: theme.palette.gray.gray_F2,
        width: '120px',
        height: '120px',
        textAlign: 'center',
        '& img': {
            width: 'auto',
            maxWidth: '100px',
            height: 'auto',
            maxHeight: '100px',
            margin: '10px auto'
        }
    },
    divAlert: {
        marginTop: '35px',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.t_2,
        padding: '20px 30px',
    },
    flexStart: {
        justifyContent: 'flex-start',
        display: 'flex'
    },
    apps: {
        width: '15rem',
        marginTop: '-5rem',
        position: 'absolute',
        marginLeft: '1.6rem', display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    textEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    // HelpButton
    mobileHelpButton: {
        height: '50px',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        marginBottom: '20px',
        position: 'absolute',
        top: '0px',
        left: '0px',
        display: 'flex',
        alignItems: 'center',
    },
    mobileHelpButtonIcon: {
        marginRight: '10px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& img': {
            width: 'auto',
            maxHeight: '40px',
        }
    },
    mobileHelpButtonArrow: {
        marginRight: '20px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& img': {
            width: 'auto',
            maxHeight: '40px',
        }
    },
    mobileHelpContainer: {
        // position: "fixed",
        position: 'absolute',
        textAlign: 'center',
        left: '0px',
        // top: '135px',
        top: '50px',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '99%',
        color: theme.palette.white.main,
        zIndex: '100',
    },
    mobileHelpBody1: {
        margin: '20px auto',
        maxWidth: '380px',
    },
    mobileHelpContainerButton: {
        marginTop: '30px',
        '& img': {
            height: '80px',
            width: 'auto',
        },
        '& p': {
            textAlign: 'center',
            fontWeight: 'bold'
        }
    },
    // DropZone
    dropzonePrev: {
        textAlign: 'center',
        padding: '5px 20px',
        border: '1px solid',
        borderColor: theme.palette.gray.gray_A7,
        marginBottom: '90px',
        '& .image': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& img': {
                width: 'auto',
                maxHeight: '30px',
            }
        },
        '& .content': {
            textAlign: 'left',
            '& .title': {
                fontSize: '12px',
            },
            '& div': {
                border: '1px solid',
                borderRadius: '25px',
                webkitBorderRadius: '25px',
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
                height: '5px',
                width: '100%',
            },
            '& .percent': {
                fontSize: '10px',
                color: theme.palette.gray.gray_A7,
                margin: '1px 0px',
            }
        },
        '& .delete': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& button': {
                minWidth: '20px'
            },
            '& img': {
                width: 'auto',
                maxHeight: '25px',
            }
        }
    },
    // GrayBox
    grayBox: {
        width: '100%',
        backgroundColor: theme.palette.gray.gray_F2,
        marginBottom: '50px',
        borderRadius: '4px',
        boxShadow: 'none',
        padding: '15px 15px 5px',
    },
    pdH10: {
        padding: '0px 10px',
    }

}));
