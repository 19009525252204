import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import ContainerComponent from "@Components-Project/containerComponent";
import { scheduleStyles } from '@Styles-Project/theme/layout/schedule';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { saveIntervalToClear } from '@Redux-Project/actions/meetingActions';
import { useSelector, useDispatch } from 'react-redux';

export default function EventTimer({ open, handleClose, formDateTarget }) {

    const sStyles = scheduleStyles();
    const dispatch = useDispatch();
    const bStyles = buttonsStyles();
    const { IntervalToClear } = useSelector(state => state.meeting)
    const [CountDown, setCountDown] = React.useState({ eventSelect: '', days: 0, hours: 0, minutes: 0, seconds: 0 })
    const { days, hours, minutes, seconds, finish } = CountDown;

    const Timing = () => {
        if (formDateTarget) {
            var hasInvertal = setInterval(() => {
                let now = new Date().getTime();
                let distance = new Date(formDateTarget.initDate) - now;
                if (distance > 0) {
                    let tempDays = Math.floor(distance / (1000 * 60 * 60 * 24));
                    let tempHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let tempMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let tempSeconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setCountDown({ ...CountDown, days: tempDays, hours: tempHours, minutes: tempMinutes, seconds: tempSeconds, finish: false })
                } else {
                    let tempDays = ((-1) * Math.floor(distance / (1000 * 60 * 60 * 24))) -1;
                    let tempHours = (-1) *  Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) -1;
                    let tempMinutes = (-1) * Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let tempSeconds = (-1) * Math.floor((distance % (1000 * 60)) / 1000);
                    setCountDown({ ...CountDown, days: tempDays, hours: tempHours, minutes: tempMinutes, seconds: tempSeconds, finish: true })
                    // setCountDown({ ...CountDown, days: 0, hours: 0, minutes: 0, seconds: 0, finish: true })
                }
            }, 1000);
            dispatch(saveIntervalToClear(hasInvertal))
        }
    }

    const formatDate = (date) => {
        const getMonthString = (Month) => {
            let MonthString = '';
            switch (Month) {
                case 0: MonthString = 'Enero'; break;
                case 1: MonthString = 'Febrero'; break;
                case 2: MonthString = 'Marzo'; break;
                case 3: MonthString = 'Abril'; break;
                case 4: MonthString = 'Mayo'; break;
                case 5: MonthString = 'Junio'; break;
                case 6: MonthString = 'Julio'; break;
                case 7: MonthString = 'Agosto'; break;
                case 8: MonthString = 'Septiembre'; break;
                case 9: MonthString = 'Octubre'; break;
                case 10: MonthString = 'Noviembre'; break;
                case 11: MonthString = 'Diciembre'; break;
                default: MonthString = Month; break;
            }
            return MonthString;
        }
        if (date) {
            let tempCountDown = new Date(date);
            let getDate = tempCountDown.getDate();
            let Month = tempCountDown.getMonth();
            let getFullYear = tempCountDown.getFullYear();
            let hours = tempCountDown.getHours() < 10 ? '0' + tempCountDown.getHours() : tempCountDown.getHours();
            let minutes = tempCountDown.getMinutes() < 10 ? '0' + tempCountDown.getMinutes() : tempCountDown.getMinutes();
            let dateString = `${getDate} de ${getMonthString(Month)} de ${getFullYear} a la(s) ${hours}:${minutes}`;
            return dateString;
        }
    }

    const validateButton = () => {
        let disabled = true;
        if ( finish && (formDateTarget && formDateTarget.link) && days < 2) disabled = false
        return disabled;
    }

    React.useEffect(() => {
        if (formDateTarget) {
            clearInterval(IntervalToClear);
            Timing()
        }
    }, [formDateTarget])

    return <ContainerComponent className={sStyles.eventTimer}>
        <Typography variant={'h1'}>{ finish === false ? 'Este evento comienza pronto' : 'Este evento ha finalizado' }</Typography>
        <Typography variant={'h1'} className={'orange'}>{formDateTarget ? formDateTarget.title : ''}</Typography>
        <Typography variant={'body1'}>{formDateTarget ? formDateTarget.description : ''}</Typography>
        <Typography variant={'h2'}>{formatDate(formDateTarget ? formDateTarget.initDate : false)}{ finish === true ? ' - Finalizó hace:' : '' }</Typography>
        <Grid container spacing={4} className={'timer'}>
            <Grid item xs={3}>
                <Box className={'bubble'}>
                    <Typography variant={'h1'}>{days}</Typography>
                </Box>
                <Typography variant="body2">Días</Typography>
            </Grid>
            <Grid item xs={3}>
                <Box className={'bubble'}>
                    <Typography variant={'h1'}>{hours}</Typography>
                </Box>
                <Typography variant="body2">Horas</Typography>
            </Grid>
            <Grid item xs={3}>
                <Box className={'bubble'}>
                    <Typography variant={'h1'}>{minutes}</Typography>
                </Box>
                <Typography variant="body2">Minutos</Typography>
            </Grid>
            <Grid item xs={3}>
                <Box className={'bubble'}>
                    <Typography variant={'h1'}>{seconds}</Typography>
                </Box>
                <Typography variant="body2">Segundos</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => window.open(formDateTarget ? formDateTarget.link : '')} className={`${bStyles.button} ${bStyles.orangeButton}`} disabled={validateButton()}>Ver Evento</Button>
            </Grid>
        </Grid>
    </ContainerComponent>
}
