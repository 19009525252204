import React from 'react';
import { Box } from "@material-ui/core";
import { carouselBrandsStyles } from '../../../styles/theme/components/carouselBrands';
import { useMediaQuery } from "@material-ui/core";
// import Swiper core and required components
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// install Swiper components
SwiperCore.use([Navigation, A11y]);

export const CarouselBrands = ({ data, onClick }) => {
    const cbStyles = carouselBrandsStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const count = isSmallScreen ? 2 : 4;

    return (
        <Box className={cbStyles.containerCB}>
            {!data ? null :
                <Swiper
                    spaceBetween={0}
                    slidesPerView={count}
                    navigation
                    onSwiper={(swiper) => {/*console.log(swiper)*/ }}
                    onSlideChange={() => {/*console.log('slide change')*/ }}
                >
                    {data.map((val) => {
                        return (
                            <SwiperSlide><img src={val.image} alt={val.title} /></SwiperSlide>
                        )
                    })}
                </Swiper>
            }
        </Box>
    )
};
