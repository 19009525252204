import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import convert from "htmr";
import { Autoplay } from "swiper";

export const whatIsPolenStyles = makeStyles((theme) => ({

    myVideo: {
        position: 'relative',
        width: '100%',
        display: 'block',
        height: 'auto',
        overflow: 'hidden',
    },

    mainContainer: {
        position: 'relative',
        padding: '0px !important',
    },
    h600: {
        height: '640px !important',
        marginBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            height: 'auto !important',
            marginBottom: '-380px',
        },
    },
    h800: {
        height: '800px !important',
        [theme.breakpoints.down('sm')]: {
            height: '600px !important',
        },
    },
    bgGray: {
        width: '100vw',
        height: '384px',
        backgroundColor: theme.palette.gray.gray_F6,
        '& .divContainer': {
            height: '100%',
            maxHeight: '384px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-start !important'
            },
            '& h1': {
                fontSize: '48px',
                lineHeight: '50.2px',
                textAlign: 'right',
                paddingRight: '30px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '24px !important',
                    marginTop: '100px !important',
                    textAlign: 'center',
                    paddingRight: '0px !important',
                },
                '& b': {
                    color: theme.palette.primary.main
                }
            }
        }
    },
    bgGrayHowitsWork: {
        width: '100vw',
        height: '400px',
        backgroundColor: theme.palette.gray.gray_F6,
        position: 'absolute',
        top: '0px',
    },
    headerImage: {
        position: 'relative',
        top: '-300px',
        padding: '24px 25px',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        width: "100vw",
        maxWidth: '1024px',
        margin: 'auto',
        maxHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            padding: '25px 25px 35px !important',
        },
        '& img': {
            width: '100%',
            maxWidth: '460px',
            position: 'relative',
            left: '-230px',
            zIndex: '1',
            [theme.breakpoints.down('sm')]: {
                left: '0px',
                marginTop: '50px',
                width: '80%',
                maxWidth: '300px',
            },
        },
        '& .divText': {
            position: 'absolute',
            top: '300px',
            width: '100vw',
            overflow: 'hidden',
            '& .lineText': {
                width: '100vw',
                whiteSpace: 'nowrap',
                marginBottom: '10px',
                [theme.breakpoints.down('sm')]: {
                    marginBottom: '0',
                },
                '& span': {
                    textAlign: 'justify',
                    color: theme.palette.gray.gray_A7,
                    fontSize: '24px',
                    lineHeight: '25.8px',
                    overflow: 'hidden',
                    height: '30px',
                    margin: '0 !important',
                    width: '1950px',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '12px',
                        lineHeight: '13.8px',
                        height: '16px',
                        margin: '0 !important',
                    },
                }
            },
        },
    },
    canDoIt: {
        height: 'auto !important',
        padding: '24px 25px',
        margin: 'auto !important',
        '& h1': {
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '65px',
        },
        '& img': {
            maxWidth: '100%',
        },
        '& p': {
            marginTop: '30px',
            fontSize: '16px',
            lineHeight: '18.6px',
            textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            '& .center-xs': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& img': {
                maxWidth: '89%'
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: '20px !important',
                lineHeight: '22.9px !important',
                marginBottom: '30px !important',
            },
            '& p': {
                textAlign: 'center',
            },
        },
    },
    divVideo: {
        height: 'auto !important',
        padding: '24px 25px',
        margin: 'auto !important',
        minHeight: '350px',
        '& h1': {
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '65px',
        },
        '& .video-js': {
            width: '100%',
            maxWidth: '95%',
            margin: 'auto',
            borderRadius: '20px',
            webkitBorderRadius: '20px',
            mozBorderRadius: '20px',
            [theme.breakpoints.down('xs')]: {
                borderRadius: '0px',
            },
            '& .vjs-tech': {
                backgroundColor: theme.palette.black.main,
            },
            '& video': {
                borderRadius: '20px',
                webkitBorderRadius: '20px',
                mozBorderRadius: '20px',
                [theme.breakpoints.down('xs')]: {
                    borderRadius: '0px',
                    webkitBorderRadius: '0px',
                    mozBorderRadius: '0px',
                },
            },
            '& .vjs-control-bar': {
                width: '95%',
                margin: 'auto',
                [theme.breakpoints.down('sm')]: {
                    width: '90%',
                }
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            '& h1': {
                fontSize: '20px !important',
                lineHeight: '22.9px !important',
                marginBottom: '30px !important',
                paddingLeft: '5px',
                paddingRight: '5px'
            },
            '& .video-js': {
                maxWidth: '700px',
            },
        },
    },
    howItsWork: {
        position: 'relative',
        height: 'auto',
    },
    divHowItsWork: {
        height: 'auto !important',
        padding: '24px 25px',
        position: 'relative',
        margin: 'auto !important',
        '&>h1': {
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '30px',
        },
        '& .imageContent': {
            position: 'relative',
            height: '400px',
            '& div.image': {
                position: 'relative',
                '& img': {
                    position: 'absolute',
                    maxWidth: '80%',
                    '&.pc': {
                        top: '0px',
                        right: '50px',
                        maxWidth: '550px'
                    }
                }
            },
            '& div.text': {
                position: 'relative',
                '& h1': {
                    color: theme.palette.primary.main,
                    textAlign: 'left',
                },
                '& p': {
                    fontSize: '16px',
                    lineHeight: '18.6px !important',
                    maxWidth: '350px'
                }
            },
        },
        '& .imageContentColumn': {
            display: 'flex',
            flexDirection: "column-reverse",
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 31,
            '& div.image': {
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                    marginTop: 23,
                    width: '60%',
                    [theme.breakpoints.down('sm')]: {
                        width: 'auto !important',
                        maxWidth: '80% !important',
                        maxHeight: '350px'
                    },
                    [theme.breakpoints.down('xs')]: {
                        width: 'auto !important',
                        maxWidth: '100% !important',
                        maxHeight: '250px'
                    },
                }
            },
            '& div.text': {
                '& h1': {
                    color: theme.palette.primary.main,
                    textAlign: 'center',
                    fontSize: '18px',
                    lineHeight: '20.8px !important',
                },
                '& p': {
                    fontSize: '16px',
                    lineHeight: '18.6px !important',
                    width: '100%',
                    textAlign: 'justify',
                }
            },
        },
        '& .buttonsContent': {
            width: '100%',
            maxWidth: '600px',
            margin: 'auto',
            '& button': {
                borderBottom: '1px solid',
                borderBottomColor: theme.palette.black.main,
                fontSize: '12px',
                lineHeight: '14.4px',
                width: '100%',
                textTransform: 'none',
                padding: '10px 0px',
                color: theme.palette.gray.gray_A7,
                '&:hover': {
                    color: theme.palette.black.main,
                    fontWeight: 'bold',
                    backgroundColor: theme.palette.white.main,
                    borderBottomColor: theme.palette.primary.main,
                }
            },
            '& .active': {
                color: theme.palette.black.main,
                fontWeight: 'bold',
                backgroundColor: theme.palette.white.main,
                borderBottomColor: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            '& >h1': {
                fontSize: '20px !important',
                lineHeight: '22.9px !important',
                marginBottom: '30px !important',
                height: 'auto !important',
            },
        },
    },
    divAchievements: {
        borderColor: 'red',
        borderWidth: 0,
        borderStyle: 'solid',
        width: '100vw',
        minHeight: '400px',
        height: 'auto !important',
        padding: '24px 25px',
        margin: 'auto !important',
        textAlign: 'center',
        backgroundColor: theme.palette.gray.gray_F6,
        '& >div': {
            height: 'auto !important',
            '& >h1': {
                fontSize: '36px',
                lineHeight: '36.8px',
                marginBottom: '30px',
            },
            '& >div': {
                maxWidth: '650px',
                textAlign: 'center',
                margin: 'auto',
                '& >div': {
                    margin: 'auto',
                    textAlign: 'center',
                },
                '& .bubble': {
                    borderRadius: '50%',
                    backgroundColor: theme.palette.gray.gray_A7_30,
                    width: '165px',
                    height: '165px',
                    position: 'relative',
                    margin: 'auto',
                    '& img': {
                        maxWidth: '45px',
                        margin: '16px auto',
                    },
                    '& h2': {
                        fontSize: '18px',
                        lineHeight: '18.8px',
                        position: 'absolute',
                        top: '75px'
                    },
                    '& h1': {
                        fontSize: '48px',
                        lineHeight: '49.8px',
                        position: 'absolute',
                        bottom: '25px'
                    }
                },
                '& .divSeparator': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    '& img': {
                        width: '100%',
                        maxWidth: '35px'
                    }
                }
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& >div': {
                width: '100% !important',
                height: 'auto !important',
                '& >h1': {
                    fontSize: '20px !important',
                    lineHeight: 'normal !important',
                },
                '& >div': {
                    '& .divSeparator': {
                        webkitTransform: 'rotate(90deg)',
                        moztransform: 'rotate(90deg)',
                        transform: 'rotate(90deg)',
                    }
                }
            },
        },
    },
    divDoubts: {
        width: '100%',
        minHeight: '280px',
        height: 'auto !important',
        padding: '29px 25px',
        margin: 'auto !important',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        '& >div': {
            height: 'auto !important',
            '& .centerContent': {
                display: 'inline-flex',
                justifyContent: 'center',
                '& .divText': {
                    width: '500px',
                    maxWidth: '100%',
                }
            },
            '& h1': {
                fontSize: '36px',
                lineHeight: '36.8px',
                marginTop: '20px',
                marginBottom: '16px',
                color: theme.palette.white.main,
                textAlign: 'left'
            },
            '& p': {
                fontSize: '16px',
                lineHeight: '16.8px',
                marginBottom: '35px',
                maxWidth: '332px',
                color: theme.palette.white.main,
                [theme.breakpoints.down('xs')]: {
                    fontSize: '14px'
                }
            },
            '& a': {
                fontWeight: 'bold',
                [theme.breakpoints.down('xs')]: {
                    width: '139px',
                    fontSize: '16px'
                }
            },
            '& img': {
                position: 'absolute',
                maxWidth: '260px',
                bottom: '0px',
                [theme.breakpoints.down('xs')]: {
                    maxWidth: '59% !important',
                    marginTop: 29
                }
            },
        },
        [theme.breakpoints.down('xs')]: {
            '& h1': {
                textAlign: 'center !important'
            },
            '& .center-xs': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            },
            '& img': {
                maxWidth: '40% !important',
                position: 'relative !important',
                marginBottom: '-29px',
                marginTop: '-30px',
            },
            '& .img-sm': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center !important',
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .img-sm': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
            },
            '& img': {
                maxWidth: '500px',
                width: '100%'
            },
            '& >div': {
                width: '100% !important',
                '& h1': {
                    fontSize: '30px !important',
                    lineHeight: 'normal !important',
                    marginBottom: '30px !important',
                },
                '& p': {
                    maxWidth: '100% !important',
                },
                '& button': {
                    float: 'right',
                }
            },
        },
    },
    divMyGoal: {
        width: '100vw',
        minHeight: '450px',
        height: 'auto !important',
        padding: '30px 25px 24px',
        margin: 'auto !important',
        backgroundColor: theme.palette.gray.gray_F6,
        '& >div': {
            height: 'auto !important',
            '& .centerContent': {
                display: 'inline-flex',
                justifyContent: 'center',
            },
            '& >h1': {
                fontSize: '36px',
                lineHeight: '36.8px',
                marginBottom: '30px',
            },
            '& img': {
                maxWidth: '500px',
            },
            '& .text': {
                '& >div': {
                    '& p': {
                        fontSize: '16px',
                        lineHeight: '16.8px',
                        marginBottom: '65px',
                        maxWidth: '350px',
                    },
                    '& button': {
                        width: '180px',
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }
                },
            },
            [theme.breakpoints.down('xs')]: {
                '& .center-xs': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .img-sm': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end'
            },
            '& >div': {
                width: '100% !important',
                '& >h1': {
                    fontSize: '20px !important',
                    lineHeight: '20.9px !important',
                    marginBottom: '30px !important',
                },
                '& img': {
                    maxWidth: '500px',
                    width: '100%'
                },
                '& .text': {
                    '& >div': {
                        '& p': {
                            marginBottom: '40px !important',
                        },
                    },
                }
            },
        },
    },
    barProgress: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
}));
