import { makeStyles } from "@material-ui/core/styles";

export const alertStyles = makeStyles((theme) => ({
    '@keyframes show': {
        '0%': { opacity: '0', },
        '100%': { opacity: '1', }
    },
    '@keyframes hide': {
        '0%': { opacity: '1' },
        '100%': { opacity: '0' }
    },
    
    bgAlert: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.gray.gray_A7_40,
        zIndex: '100000',
        justifyContent: 'center',
        alignItems: 'center',
        '&.show': {
            display: 'flex',
            animationDelay: '0s',
            animationDuration: '500ms',
            animationName: '$show',
        },
        '&.hide': { display: 'none' },
    },
    myAlert: {
        position: 'relative',
        width: '90vw',
        height: 'auto',
        maxWidth: '512px',
        backgroundColor: theme.palette.white.main,
        borderRadius: '10px',
        mozBorderRadius: '10px',
        webkitBorderRadius: '10px',
        padding: '50px 20px 30px',
        textAlign: 'center',
        '& button.option_close': {
            position: 'absolute !important',
            top: '30px !important',
            right: '30px !important',
            backgroundColor: 'rgba(255, 255, 255, 0) !important'
        },
        '& .logo': {
            marginBottom: '20px',
            '& img': {
                width: 'auto',
                maxWidth: '150px',
                height: 'auto',
            }
        },
        '& h1': {
            color: theme.palette.black.main,
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '20px',
            width: '100%',
            textAlign: 'center !important',
            height: 'auto !important',
        },
        '& p': {
            color: theme.palette.black.main,
            fontSize: '20px',
            lineHeight: '22.4px',
            textAlign: 'center',
            width: '100%',
            height: 'auto !important',
        },
        '& button.btn': {
            margin: 'auto',
            width: '90%',
            textTransform: 'Capitalize',
            position: 'relative',
            maxWidth: '320px',
            marginTop: '30px',
            '& .icon': {
                width: '23.2px',
                height: '25.8px',
                marginRight: '10px',
            }
        },
    }
}));
