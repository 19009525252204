import React from 'react';
import { Grid, Box, Typography, Button, useMediaQuery } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import ReactTypingEffect from 'react-typing-effect';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import convert from "htmr";

export default function HowWorking() {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [OptionTag, setOptionTag] = React.useState({
        option: 'Simula',
        image: Images.pc_window,
        title: 'Simula tu plan',
        subTitle: 'En unos cuantos clicks podrás simular el plan que te conducirá al logro de tus metas. Polen te da más opciones para que elijas la que más te emocione y mejor se acomode a tus finanzas.'
    });
    const setOptionToShow = (option) => {
        let data = {};
        switch (option) {
            case 'Adquiere':
                data = {
                    option: 'Adquiere',
                    image: Images.pc_purchasePlan,
                    title: 'El plan que quieres',
                    subTitle: 'Desde la palma de tu mano podrás adquirir un plan hecho a tu medida y cancelarlo a través de nuestros medios de pago seguro. En nuestra comunidad, tú tienes el poder de elegir el bien o servicio que más te guste y pagarlo con la cuota que prefieras.<br/><br/>Decídete a ingresar en un grupo conformado por personas como tú, que desean invertir en su futuro.'
                };
                break;
            case 'Participa':
                data = {
                    option: 'Participa',
                    image: Images.pc_participle,
                    title: 'Participa',
                    subTitle: 'Con cada cuota pagada oportunamente, participarás en la asamblea mensual por los mecanismos de sorteo u oferta. Esta oferta te permitirá anticipar tu adjudicación mediante el adelantamiento de cuotas de tu plan. Para tu tranquilidad, administraremos tus recursos a través de Fiduciaria Bancolombia.<br/><br/>Recuerda que nuestra asamblea será transmitida por internet, para que asistas virtualmente y hagas realidad tus metas ¡sin salir de casa!'
                };
                break;
            case 'Disfruta':
                data = {
                    option: 'Disfruta',
                    image: Images.pc_enjoy,
                    title: 'Disfruta',
                    subTitle: 'Una vez seas adjudicado y con la aprobación de tu crédito, obtendrás los recursos necesarios para acceder al producto de tu preferencia.<br/><br/>Disfruta la satisfacción de haber tomado la mejor alternativa financiera para alcanzar tus metas.<br/><br/>Con Polen, en comunidad, la logras.'
                };
                break;
            default:
                data = {
                    option: 'Simula',
                    image: Images.pc_window,
                    title: 'Simula tu plan',
                    subTitle: 'En unos cuantos clicks podrás simular el plan que te conducirá al logro de tus metas. Polen te da más opciones para que elijas la que más te emocione y mejor se acomode a tus finanzas.'
                };
                break;
        }
        setOptionTag(data);
    }

    const wipStyles = whatIsPolenStyles();
    return <Box className={wipStyles.howItsWork}>
        <Box className={wipStyles.bgGrayHowitsWork}></Box>
        <ContainerComponent className={wipStyles.divHowItsWork}>
            <Typography variant={'h1'}>¿Cómo funciona?</Typography>
            {
                isSmallScreen ?
                    <Grid container className={'imageContentColumn'}>
                        <Grid xs={12} className={'text'}>
                            <Typography variant={'h1'}>{OptionTag.title}</Typography>
                            <Typography variant="body1">
                                {/* Desde la palma de tu mano podrás adquirir un plan a tu medida. Decídete a ingresar en un grupo conformado por personas como tú, que prefieren invertir en su futuro.<br></br><br></br>En nuestra comunidad, tu compromiso te permitirá alcanzar tus metas y las de todos los miembros de tu grupo. */}
                                { convert(OptionTag.subTitle) }
                            </Typography>
                            <Grid xs={0} className={'image'}>
                                <img alt={OptionTag.image} src={OptionTag.image} className={'pc'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction='row' className={'imageContent'}>
                        <Grid xs={12} sm={7} className={'image'}>
                            <img alt={OptionTag.image} src={OptionTag.image} className={'pc'} />
                        </Grid>
                        <Grid xs={12} sm={5} className={'text'}>
                            <Typography variant={'h1'}>{OptionTag.title}</Typography>
                            <Typography variant="body1">
                                {/* Desde la palma de tu mano podrás adquirir un plan a tu medida. Decídete a ingresar en un grupo conformado por personas como tú, que prefieren invertir en su futuro.<br></br><br></br>En nuestra comunidad, tu compromiso te permitirá alcanzar tus metas y las de todos los miembros de tu grupo. */}
                                { convert(OptionTag.subTitle) }
                            </Typography>
                        </Grid>
                    </Grid>
            }
            <Grid container direction='row' spacing={2} className={'buttonsContent'}>
                <Grid item xs={6} sm={3}>
                    <Button className={`${OptionTag.option === 'Simula' ? 'active' : ''}`} onClick={() => setOptionToShow('Simula')} >Simula</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button className={`${OptionTag.option === 'Adquiere' ? 'active' : ''}`} onClick={() => setOptionToShow('Adquiere')} >Adquiere tu plan</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button className={`${OptionTag.option === 'Participa' ? 'active' : ''}`} onClick={() => setOptionToShow('Participa')} >Participa</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button className={`${OptionTag.option === 'Disfruta' ? 'active' : ''}`} onClick={() => setOptionToShow('Disfruta')} >Disfruta</Button>
                </Grid>
            </Grid>
        </ContainerComponent>
    </Box>
}
