import { makeStyles } from "@material-ui/core/styles";

export const buttonsStyles = makeStyles((theme) => ({
    button: {
        height: '44px',
        borderRadius: '4px',
        width: '100%',
        textTransform: 'none',
        color: theme.palette.primary.main,
        '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main,
        },
        '&:hover': {
            outline: 'none',
            textDecoration: 'none',
            textTransform: 'none',
        },
        [theme.breakpoints.up('md')]: {
            width: '150px',
        }
    },
    buttonLarge: {
        [theme.breakpoints.up('md')]: {
            width: '250px',
        }
    },
    button100: {
        [theme.breakpoints.up('md')]: {
            width: '100%',
        }
    },
    buttonTypeDropDown: {
        border: 'solid 1px #fe5b02',
        backgroundColor: '#ffffff',
        height: '44px',
        width: '180px',
        borderRadius: '4px',
        textTransform: 'none',
        color: theme.palette.primary.main,
        fontFamily: theme.typography.TBeVietnamRegular,
    },
    clickLinkInsideDropDown: {
        border: 'solid 0px ',
        textTransform: 'none',
        color: theme.palette.primary.main,
    },
    orangeButton: {
        color: theme.palette.white.main,
        backgroundColor: theme.palette.primary.main,
        fontFamily: theme.typography.TBeVietnamBold,
        '&:hover': {
            backgroundColor: theme.palette.primary.t_9,
        },
        '& span': {
            '&:hover': {
                textDecoration: 'none',
                textTransform: 'none',
            }
        }
    },
    whiteButton: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white.main,
        fontFamily: theme.typography.TBeVietnamBold,
        fontSize: '16px',
        lineHeight: '16.8px',
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.palette.white.main,
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative !important',
        },
    },
    grayButton: {
        color: theme.palette.black.t_24,
        backgroundColor: theme.palette.black.t_12,
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '16px',
        '&:hover': {
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.main,
        }
    }
}));
