import React from 'react';
import { Box } from '@material-ui/core';
import WhatIsPolenComponents from '@Components-Project/whatIsPolenComponents';
import { useDispatch } from "react-redux";
import api from "@Constants-Project/api";
import { setOurGoals } from "@Redux-Project/actions/whatIsPolenActions";

import { Helmet } from "react-helmet";

export default function WhatIsPolen() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(!open);
    const getOurGoals = async () => {
        let response = await api.GET('api/NuestrosLogros', (e) => { console.log('e => ', e) });
        if (response.success) {
            dispatch(setOurGoals(response.data[0]));
        }
        else alert(response.message + ' Code: ' + response.status)
    }

    //document.title = "Polen | ¿Qué es Polen?";

    React.useEffect(() => {
        getOurGoals();
    }, [])

    return (
        <>
            <Helmet
                title={"Polen | ¿Qué es Polen?"}
                meta={[
                    {
                        name: 'description',
                        content: "Hacemos de tu meta, nuestra meta",
                    }
                ]}
            />
            <Box>
                <WhatIsPolenComponents open={open} handleClose={handleClose} />
            </Box>
        </>
    );
}
