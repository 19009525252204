import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button, Container } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { talktoUSStyles } from '@Styles-Project/theme/layout/talkToUs';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';

export default function Header() {
    const TalkStyles = talktoUSStyles();
    const Stypographies = typographiesStyles();
    return <Box className={TalkStyles.bgOrange}>
        <ContainerComponent className={'divContainer'}>
            <img className={`notch`} src={Images.notch} alt={Images.notch} />
            <Typography className={`${Stypographies.colorWhite}`} variant={'h1'}>Háblanos</Typography>
            <Typography className={`${Stypographies.colorWhite}`} variant={'h6'}>Resolvemos todas tus preguntas e inquietudes</Typography>
            <img className={`imageBig`} alt={Images.group1073} src={Images.group1073} />
        </ContainerComponent>
    </Box>;
}
