import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom';
import Home from '../main/body/home';
import WhatIsPolen from '../main/body/whatIsPolen';
import Schedule from '../main/body/schedule';
import TalkToUs from '../main/body/talkToUs';

export const BodyRoutes = ({ ...props }) => {

    return (
        <Switch>
            <Route exact path='/inicio' component={Home} />
            <Route exact path='/que-es-polen' component={WhatIsPolen} />
            <Route exact path='/eventos' component={Schedule} />
            <Route exact path='/hablanos/:id' component={TalkToUs} />
            <Redirect to='/inicio' />
        </Switch>
    )
}
