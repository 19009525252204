import { types } from '../types/types';

export const parallaxReducer = (state = {}, action) => {
    switch (action.type) {
        case types.setPost:
            return {
                ...state,
                Post: action.payload.posts
            };
        case types.setTestimony:
            return {
                ...state,
                Testimony: action.payload.Testimony
            };
        case types.setBrands:
            return {
                ...state,
                Brands: action.payload.Brands
            };
        default:
            return state;
    }
};
