/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import { videoPlayerStyles } from "@Styles-Project/theme/components/videoPlayer";
import { PlayArrow } from '@material-ui/icons';
import ReactPlayer from "react-player";
import * as $ from 'jquery';

export const VideoPlayer = ({src, auto = true, mute = false}) => {
    const vpStyles = videoPlayerStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const play = () => {
        if (!isSmallScreen) $('.big-play-button').fadeOut();
    }

    const pause = () => {
        if (!isSmallScreen) $('.big-play-button').fadeIn();
    }

    const buttonPlay = () => {
        $('.video>video').get(0).play();
    }

    return (
        <Box className={vpStyles.myVideo}>
        {/* width="70%"
        height="auto"
                className={'video'} */}
            <ReactPlayer
                url={src}
                playing={auto}
                controls={true}
                muted={mute}
                onPlay={play}
                onPause={pause}
                loop={false}
                playsinline={true}
                width="100%"
                height="auto"
            />
            <Box className={'big-play-button'}>
                <PlayArrow onClick={buttonPlay} style={{ cursor: 'pointer', color: '#FFF', width: '32px', height: '32px' }} />
            </Box>
        </Box>
    );
};
