import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Images from '@Constants-Project/images';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';

export default function WhatPollenDoingDoforYou() {
    const wipStyles = whatIsPolenStyles();
    return <ContainerComponent className={wipStyles.canDoIt}>
        <Typography variant={'h1'}>Ventajas del sistema de compra programada</Typography>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3} className={"center-xs"}>
                <img alt={Images.doingDoforYouImage1} src={Images.doingDoforYouImage1} />
                <Typography variant="body1">Alcanza tus metas con la alternativa económica que se ajusta a tu bolsillo. En Polen, tú escoges la cuota que quieres pagar.</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={"center-xs"}>
                <img alt={Images.doingDoforYouImage2} src={Images.doingDoforYouImage2} />
                <Typography variant="body1">Administra tu plan desde la palma de tu mano, a través de una plataforma digital, sencilla y segura.</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={"center-xs"}>
                <img alt={Images.doingDoforYouImage3} src={Images.doingDoforYouImage3} />
                <Typography variant="body1">Puedes ser adjudicado por sorteo desde el primer mes, o anticipar tu adjudicación con una oferta (adelanto de cuotas) y elegir el bien o servicio de tu preferencia.</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={"center-xs"}>
                <img alt={Images.doingDoforYouImage4} src={Images.doingDoforYouImage4} />
                <Typography variant="body1" >Cuidaremos de tus recursos. Polen es Vigilada por la Superintendencia de Sociedades y administra tus recursos a través de Fiduciaria Bancolombia.</Typography>
            </Grid>
        </Grid>
    </ContainerComponent>
}
