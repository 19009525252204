import { makeStyles } from "@material-ui/core/styles";

export const headerStyles = makeStyles((theme) => ({
    textOrange: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.TBeVietnamBold,
        fontSize: '14px',
        fontWeight: 'bold'
    },
    textBase: {
        fontFamily: theme.typography.TBeVietnamRegular,
        color: '#000',
        fontSize: '14px'
    },
    clickLink: {
        textDecoration: 'none',
        '& Link': {
            textDecoration: 'none',
        }
    },
    headerContainer: {
        padding: '10px 0px',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
        position: 'fixed',
        maxWidth: '100vw',
        '& .MuiContainer-root.MuiContainer-maxWidthLg': {
            maxWidth: '100vw',
        },
        '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        }
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        padding: '12px',
        [theme.breakpoints.down('sm')]: {
            flexGrow: 'inherit',
            padding: '15px',
            width: '70vw',
        },
        '& a': {
            display: 'inline-flex',
            verticalAlign: 'middle',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                verticalAlign: 'middle',
                height: '30px',
            },
        },
    },
    logo_image: {
        height: 'auto',
        width: '100%',
        maxHeight: '40px',
        display: 'inline-block',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '30px',
        },
    },
    logo_super: {
        height: '30px',
        width: 'auto',
        marginLeft: '10px',
        display: 'inline-block',
        verticalAlign: 'bottom',
        [theme.breakpoints.down('xs')]: {
            height: '20px',
            verticalAlign: 'bottom',
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionDesktopLink: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-evenly'

        }
    },
    sectionMobile: {
        display: 'flex',
        position: 'absolute',
        right: '0',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    mr21: {
        marginRight: '21px',
    },
    barProgress: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
    },
    userDisplay: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));
