import { makeStyles } from "@material-ui/core/styles";
import Colors from '@Constants-Project/colors';
import { BeVietnamBold } from '@Constants-Project/fonts';

export const homeStyles = makeStyles((theme) => ({
    firstParallax: {
        width: '100%',
        height: 'auto !important',
        position: 'relative',
    },
    containerFromPalmYourHand: {
        position: 'relative',
        padding: "0px",
        height: 'auto',
        width: "100%",
        [theme.breakpoints.down('md')]: {
            height: 'auto !important',
            maxHeight: 'auto !important',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            minHeight: 'auto !important',
            "& >div": {
                height: 'auto !important',
            },
            "& Button": {
                display: 'none',
            },
        },
        '& .container': {
            // maxHeight: '500px !important',
            [theme.breakpoints.down('md')]: {
                height: 'auto !important',
            },
            [theme.breakpoints.down('xs')]: {
                paddingBottom: '120px',
                height: 'auto !important',
            },
            '& .video-js': {
                '& .vjs-control-bar': {
                    '& .vjs-control': {
                        position: 'relative !important',
                    },
                    '& .vjs-volume-panel': {
                        [theme.breakpoints.down('md')]: {
                            left: '15px',
                        },
                    },
                },
            },
        },
        "& button": {
            position: "absolute",
            display: 'flex',
            left: '30px',
            bottom: '100px',
            width: '241px',
            height: '60px',
            fontWeight: 'bold',
            alignContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                position: 'absolute !important',
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute !important',
            },
            [theme.breakpoints.down('xs')]: {
                position: 'absolute !important',
                left: '50%',
                marginLeft: '-120px',
                bottom: '20px'
            },
            '& img': {
                marginRight: '15px'
            }
        },
    },
    ContaintParallax: {
        width: '100%',
        display: 'block',
        position: 'relative',
        paddingTop: '85px',
        // minHeight: '1250px',
        // maxHeight: '1250px',
        height: '100%',
        // height:'4000px'
        // overflow: 'hidden',
    },
    helpParallax: {
        // top: '200px',
        // position: 'absolute',
        // zIndex: 20,
        // maxHeight: '400px',
        // height: '100%',
        // position: 'relative'

        top: '580px',
        //bottom: '19vh',
        // height: '100%',
        //borderColor: 'blue', borderWidth: 1, borderStyle: 'solid',
        zIndex: 20,
        position: 'fixed',
        right: '20px',
        [theme.breakpoints.down('xs')]: {
            top: 'unset',
            bottom: '3vh',
            right: '2px',
        },
    },
    secondParallax: {
        height: 'auto',
        minHeight: 'auto',
        position: 'relative',
        overflow: 'inherit',
        textAlign: 'center',
        '& .divSup': {
            textAlign: 'center',
            padding: '25px 0 50px',
            '& img': {
                margin: 'auto',
            },
            '& h3': {
                fontSize: '24px',
                lineHeight: '25.2px',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '20px',
                    lineHeight: '21.9px',
                }
            }
        },
        '& .two': {
            height: '163px',
            marginBottom: '50px',
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
            },
            '& h1': {
                fontSize: '86px',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '141px',
                letterSpacing: 'normal',
                color: theme.palette.primary.main,
                height: '96px',
                marginTop: '-20px',
                marginBottom: '20px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '80px',
                    lineHeight: '83.7px',
                    height: 'auto',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '40px',
                    lineHeight: '73.3px',
                    height: 'auto',
                    margin: '0',
                }
            },
            '& h3': {
                fontSize: '86px',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: '100.4px',
                letterSpacing: 'normal',
                marginBottom: '40px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '80px',
                    lineHeight: '83.7px',
                    height: 'auto',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '40px',
                    lineHeight: '21.3px',
                    height: 'auto',
                }
            },
        },
        '& .animationBox': {
            position: 'relative',
            height: '500px',
            marginTop: '100px',
            overflow: 'hidden',
            '& .four': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                position: 'absolute',
                width: '100%',
                '&.centerBall': {
                    '& img': {
                        position: 'absolute',
                        width: '40%',
                        maxWidth: '338px',
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '40%',
                            maxWidth: '338px',
                            top: '25px'
                        }
                    }
                },
                '&.firstMan': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '22%',
                            height: 'auto',
                            left: '9%',
                            margin: 'auto',
                        }
                    }
                },
                '&.secondBall': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '10%',
                            height: 'auto',
                            left: '11%',
                            margin: 'auto',
                            top: '100px',
                        }
                    }
                },
                '&.firstWoman': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '15%',
                            height: 'auto',
                            left: '35%',
                            margin: 'auto',
                        }
                    }
                },
                '&.secondWoman': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '20%',
                            height: 'auto',
                            left: '55%',
                            margin: 'auto',
                            top: '25px'
                        }
                    }
                },
                '&.thirdBall': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '8%',
                            height: 'auto',
                            left: '55%',
                            margin: 'auto',
                            top: '300px',
                        }
                    }
                },
                '&.fourBall': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '15%',
                            height: 'auto',
                            left: '75%',
                            margin: 'auto',
                            top: '200px',
                        }
                    }
                },
                '&.secondMan': {
                    '& img': {
                        [theme.breakpoints.down('sm')]: {
                            position: 'absolute',
                            width: '22%',
                            height: 'auto',
                            left: '75%',
                            margin: 'auto',
                            top: '30px',
                        }
                    }
                },
                '& .oneBall': {
                    width: 103,
                    height: 103,
                    marginRight: 642,
                    borderWidth: 1,
                },
                '& .twoBall': {
                    width: 338,
                    height: 338,
                    borderWidth: 1,
                },
                '& .threeBall': {
                    // transition: '100ms',
                    // marginTop: '380px',
                    width: 69,
                    height: 69,
                    marginLeft: 151,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 28,
                        height: 28,
                        marginLeft: 106,
                        marginTop: '136px',
                        marginRight: '30px'
                    },
                    // backgroundColor: 'green',
                },
                '& .fourBall': {
                    // transition: '100ms',
                    // marginTop: '260px',
                    width: 155,
                    height: 155,
                    marginLeft: 549,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 62,
                        height: 62,
                        marginLeft: 266,
                        marginTop: '93px',
                        marginRight: '36px'
                    },
                    // backgroundColor: 'yellow',
                },
                '& .firstPerson': {
                    // transition: '100ms',
                    // marginTop: '10px',
                    width: 200.3,
                    height: 370,
                    marginRight: 565,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 81,
                        height: 148,
                        marginRight: 215,
                    },
                    // backgroundColor: 'pink',
                },
                '& .secondPerson': {
                    width: 131.6,
                    height: 494,
                    marginRight: 157,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 53,
                        height: 198,
                        marginRight: 51,
                    },
                    // backgroundColor: 'black',
                },
                '& .thirdPerson': {
                    // transition: '100ms',
                    // marginTop: '35px',
                    width: 171.3,
                    height: 405,
                    marginLeft: 228,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 69,
                        height: 162,
                        marginLeft: 106,
                    },
                    // backgroundColor: 'orange',
                },
                '& .quarterPerson': {
                    // transition: '100ms',
                    // marginTop: '39px',
                    width: 215.8,
                    height: 364,
                    marginLeft: 668,
                    borderWidth: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: 86,
                        height: 146,
                        marginLeft: 266,
                    },
                    // backgroundColor: 'grey',
                },
            },
        },
        '& .imageSmall': {
            width: '95%',
            margin: 'auto',
            maxWidth: '800px',
            position: 'relative',
            height: 'auto',
        }
    },
    /* secondParallax: {
        height: '3000px',
        minHeight: '100vh',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: '450px',
        },
        '&.hvh100': {
            height: '100vh'
        },
        '&.h840': {
            height: '840px',
        },
        '&.h940': {
            height: '940px',
        },
        '&.h1040': {
            height: '1040px',
        },
        '& .one': {
            height: '107px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '30px',
            },
        },
        '& .two': {
            // marginTop: '48px',
            height: '163px',
            '& .inComunity': {
                fontSize: '36px',
            },
            '& .achiveYou': {
                fontSize: '64px',
            },

        },
        '& .three': {
            // transition: '3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: '',
            backgroundColor: 'rgba(123,213,98,0.5)',
            height: '500px',
            width: '100%',
            '& .twoBall': {
                marginTop: '35px',
                width: 338,
                height: 338,
            },
        },

        '& .four': {
            // transition: '3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'absolute',
            // backgroundColor: 'rgba(52,65,68,0.1)',
            // height: '500px',
            width: '100%',
            '& .oneBall': {
                // transition: '100ms',
                // marginTop: '119px',
                width: 103,
                height: 103,
                marginRight: 642,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 41,
                    height: 41,
                    marginRight: 215,
                    marginTop: '42px',
                    marginLeft: '-42px'
                },
                // backgroundColor: 'red',
            },
            '& .twoBall': {
                // transition: '100ms',
                // marginTop: '35px',
                width: 338,
                height: 338,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 136,
                    height: 136,
                },
                // backgroundColor: 'blue',
            },
            '& .threeBall': {
                // transition: '100ms',
                // marginTop: '380px',
                width: 69,
                height: 69,
                marginLeft: 151,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 28,
                    height: 28,
                    marginLeft: 106,
                    marginTop: '136px',
                    marginRight: '30px'
                },
                // backgroundColor: 'green',
            },
            '& .fourBall': {
                // transition: '100ms',
                // marginTop: '260px',
                width: 155,
                height: 155,
                marginLeft: 549,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 62,
                    height: 62,
                    marginLeft: 266,
                    marginTop: '93px',
                    marginRight: '36px'
                },
                // backgroundColor: 'yellow',
            },
            '& .firstPerson': {
                // transition: '100ms',
                // marginTop: '10px',
                width: 200.3,
                height: 370,
                marginRight: 565,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 81,
                    height: 148,
                    marginRight: 215,
                },
                // backgroundColor: 'pink',
            },
            '& .secondPerson': {
                width: 131.6,
                height: 494,
                marginRight: 157,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 53,
                    height: 198,
                    marginRight: 51,
                },
                // backgroundColor: 'black',
            },
            '& .thirdPerson': {
                // transition: '100ms',
                // marginTop: '35px',
                width: 171.3,
                height: 405,
                marginLeft: 228,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 69,
                    height: 162,
                    marginLeft: 106,
                },
                // backgroundColor: 'orange',
            },
            '& .quarterPerson': {
                // transition: '100ms',
                // marginTop: '39px',
                width: 215.8,
                height: 364,
                marginLeft: 668,
                borderWidth: 1,
                [theme.breakpoints.down('sm')]: {
                    width: 86,
                    height: 146,
                    marginLeft: 266,
                },
                // backgroundColor: 'grey',
            },
        },

    }, */
    thirdyParallax: {
        padding: '20px 0px 20px',
        position: "relative",
        height: '255px',
        [theme.breakpoints.down('xs')]: {
            height: '300px',
        },
        '& h1': {
            fontSize: '36px',
            lineHeight: '37.7px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px'
            },
        }
    },
    quarterParallax: {
        position: "relative",
        height: '536px',
        // backgroundColor: 'rgba(114,191,20,0.3)',
        '& .hideText': {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        },
        '& .one': {
            height: '114px',
            display: 'flex',
            justifyContent: 'center',
        },
        '& .two': {
            marginTop: '22px',
            display: 'flex',
            justifyContent: 'center',
            '& .content': {
                alignItems: 'center',
                maxHeight: '500px',
                '& .strips_contain': {
                    '&.short': {
                        padding: '0px',
                        textAlign: 'left',
                        '& img': {
                            marginLeft: '10px',
                            marginBottom: '10px',
                            height: '36px',
                            width: 'auto',
                        },
                        '& p': {
                            padding: '0px 10px',
                            height: '70px',
                            [theme.breakpoints.down('sm')]: {
                                fontSize: '16px',
                                lineHeight: '16.4px',
                                height: '80px',
                            },
                        },
                    },
                    '&.strip__inner-text': {
                        padding: '30px',
                        '& h6': {
                            fontSize: '18px',
                            lineHeight: '18.8px',
                            marginBottom: '30px'
                        },
                        '& h2': {
                            fontSize: '40px',
                            lineHeight: '41.8px',
                            width: '100%',
                            marginBottom: '50px',
                            maxWidth: '600px',
                            '&.onTop': {
                                marginTop: '-20px'
                            },
                        },
                        '& p': {
                            maxWidth: '360px'
                        },
                        '& button.button-left': {
                            left: '30px',
                            '& .MuiButton-label': {
                                '& img': {
                                    marginRight: '20px'
                                }
                            }
                        },
                        '& .divButton': {
                            '& button': {
                                '& .MuiButton-label': {
                                    '& img': {
                                        marginRight: '20px'
                                    }
                                }
                            },
                            '& button.MuiButton-contained.Mui-disabled': {
                                backgroundColor: 'rgba(255, 255, 255, 0.8)'
                            }
                        }
                    }
                }
            }
        },
        '& .divTop': {
            position: 'absolute',
            top: '30px',
            left: '15px'
        }
    },
    quarterParallaxMovil: {
        marginTop: '60px',
        backgroundColor: theme.palette.primary.main,
    },
    fifthParallax: {
        alignItems: 'center !important',
        width: '100vw !important',
        height: 'auto !important',
        padding:'0 20px !important',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding:'10px 0 !important',
        },
    },
    sixthParallax: {
        width: '100%',
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#f6f6f7',
        padding: '0 0 50px',
        // maxHeight: '550px',
        // height: '100%',
        '& .TheyTrust': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
            },
        },
        '&.h550': {
            height: '550px',
        },
        '&.h800': {
            height: '800px',
        },
        '& .one': {
            width: '100%',
            maxHeight: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        '& .two': {
            width: '100%',
            maxHeight: '100%',
            maxWidth: '1024px',
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    seventhParallax: {
        marginTop: '0',
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
        height: '600px',
        overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
            height: '580px',
        },
        [theme.breakpoints.down('md')]: {
            height: '550px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '450px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '1050px',
        },
        '& .bgColor': {
            justifyContent: 'center',
            width: '200vw',
            height: '100%',
            backgroundColor: '#fe5b02',
            position: 'relative',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '100%',
            },
            [theme.breakpoints.down('xs')]: {
                height: '1050px',
                marginTop: '0px',
            },
            '& .containterSeventhParallax': {
                overflow: 'hidden',
                margin: '0',
                height: '100%',
                width: '200vw',
                [theme.breakpoints.down('xs')]: {
                    width: '100vw',
                },
                '& .movilRow': {
                    [theme.breakpoints.down('sm')]: {
                        overflow: 'hidden',
                    }
                },
                '& .one': {
                    // backgroundColor: 'rgba(123,200,83,0.5)',
                    display: 'inline-block',
                    height: '100%',
                    flexDirection: 'column',
                    marginLeft: '15vw',
                    maxWidth: '33vw',
                    verticalAlign: 'top',
                    zIndex: '2',
                    [theme.breakpoints.down('lg')]: {
                        marginLeft: '5vw',
                        width: '35vw',
                        maxWidth: 'inherit',
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '35vw',
                        marginLeft: '5vw',
                        margin: 'auto',
                    },
                    [theme.breakpoints.down('xs')]: {
                        width: '100%',
                        height: 'auto',
                        margin: 'auto',
                        textAlign: 'center',
                    },
                    '& .polenOnYourHand': {
                        width: "100%",
                        marginTop: '110px',
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '50px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: '100%',
                            textAlign: 'center',
                        },
                        '& h1': {
                            fontSize: '48px',
                            lineHeight: '50.2px',
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '36px',
                                lineHeight: '37.7px',
                            },
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '28px',
                                lineHeight: '28.8px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                display: 'none'
                            },
                        },
                        '& p': {
                            margin: '28px auto 0',
                            fontSize: '24px',
                            lineHeight: '25.1px',
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '24px',
                                lineHeight: '25.1px',
                            },
                            [theme.breakpoints.down('lg')]: {
                                fontSize: '20px',
                                lineHeight: '20.4px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                fontSize: '18px',
                                lineHeight: '18.8px',
                                marginTop: '10px',
                                textAlign: 'center',
                                width: '100%',
                            },
                        },
                    },
                    '& .imgDownloadApp': {
                        position: 'relative',
                        marginTop: '40px',
                        flexDirection: 'row',
                        [theme.breakpoints.down('xs')]: {
                            position: 'relative',
                            display: 'block',
                            width: '100%',
                            textAlign: 'center',
                            '& img': {
                                maxWidth: '40%',
                                display: 'inline-block',
                                margin: '0px 5%',
                            },
                        },
                    }
                },
                '& .two': {
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '26vw',
                    height: '100%',
                    verticalAlign: 'top',
                    position: 'relative',
                    zIndex: '1',
                    [theme.breakpoints.down('xs')]: {
                        height: '350px',
                        width: '100%',
                        margin: '20px auto',
                        textAlign: 'center',
                    },
                    '& .imageSquare': {
                        width: '200px',
                        margin: 'auto',
                        position: 'relative',
                        height: '100%',
                        [theme.breakpoints.down('sm')]: {
                            width: '200px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: '200px',
                        },
                        '& .phoneBig': {
                            width: "auto",
                            height: '450px',
                            position: 'absolute',
                            top: '50px',
                            left: '-130%',
                            [theme.breakpoints.down('lg')]: {
                                left: '-120%',
                                height: '400px',
                            },
                            [theme.breakpoints.down('md')]: {
                                left: '-110%',
                                height: '400px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                left: '-80%',
                                height: '300px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                left: '-80%',
                                height: '300px',
                            },
                            '&.showPhone': {
                                display: 'block'
                            },
                            '&.hidePhone': {
                                display: 'none'
                            },
                        },
                        '& .vectorOnPhone': {
                            width: "203px",
                            margin: "auto",
                            position: 'relative',
                            display: 'block',
                            marginTop: '50px',
                            [theme.breakpoints.down('lg')]: {
                                width: "200px",
                                marginTop: '30px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: "150px",
                                marginTop: '30px',
                            },
                            [theme.breakpoints.down('xs')]: {
                                width: "150px",
                                marginTop: '30px',
                            },
                        },
                        '& .logoPolenFlip': {
                            width: "163px",
                            height: "176px",
                            margin: "auto",
                            position: 'absolute',
                            display: 'block',
                            zIndex: '3',
                            bottom: '100px',
                            left: '-20%',
                            [theme.breakpoints.down('lg')]: {
                                bottom: '100px',
                                left: '-20%',
                            },
                            [theme.breakpoints.down('md')]: {
                                bottom: '100px',
                                left: '-15%',
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: "100px",
                                height: 'auto',
                                bottom: '100px',
                                left: '-5%',
                            },
                            [theme.breakpoints.down('xs')]: {
                                width: "100px",
                                height: 'auto',
                                bottom: '0',
                                left: '0',
                            },
                        }
                    },
                },

                '& .four': {
                    display: 'inline-flex',
                    flexDirection: 'column',
                    width: '50vw !important',
                    height: '100%',
                    verticalAlign: 'top',
                    marginLeft: '5vw',
                    zIndex: '1',
                    [theme.breakpoints.down('lg')]: {
                        marginLeft: '10vw',
                    },
                    [theme.breakpoints.down('xs')]: {
                        width: '100vw !important',
                        marginLeft: '0',
                        marginTop: '20px',
                        height: '100px',
                    },
                    '& .divRow': {
                        marginTop: '100px',
                        [theme.breakpoints.down('lg')]: {
                            marginTop: '50px',
                        },
                        '& .arrowDot': {
                            marginTop: '78.5px',
                            width: '16vw',
                            height: 'auto',
                            marginLeft: '4vw',
                            [theme.breakpoints.down('xs')]: {
                                display: 'none'
                            },
                        },
                        '& .vectorCutOnText': {
                            width: '11vw',
                            height: 'auto',
                            marginTop: '-57px',
                            marginBottom: '10px',
                            marginLeft: '3vw',
                            [theme.breakpoints.down('xs')]: {
                                display: 'none'
                            },
                        },
                        '& .vectorMiniOnText': {
                            width: '2vw',
                            height: 'auto',
                            marginLeft: '18px'
                        }
                    },
                    '& .vectorMiniBackText': {
                        width: '5vw',
                        height: 'auto',
                        marginLeft: '30vw',
                        marginTop: '30px',
                        [theme.breakpoints.down('xs')]: {
                            display: 'none'
                        },
                    },
                    '& h1': {
                        fontSize: '72px',
                        lineHeight: '75.3px',
                        height: 'auto !important',
                        [theme.breakpoints.down('lg')]: {
                            fontSize: '48px',
                            lineHeight: '50.2px',
                        },
                        [theme.breakpoints.down('md')]: {
                            fontSize: '40px',
                            lineHeight: '40.8px',
                        },
                        [theme.breakpoints.down('md')]: {
                            fontSize: '26px',
                            lineHeight: '26.8px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            fontSize: '26px',
                            lineHeight: '26.8px',
                            textAlign: 'center',
                        },
                    }

                },
                '& .five': {
                    height: '100%',
                    width: '40vw',
                    display: 'inline-flex',
                    flexDirection: 'column',
                    verticalAlign: 'top',
                    zIndex: '1',
                    position: 'relative',
                    [theme.breakpoints.down('lg')]: {
                        width: '50vw',
                    },
                    [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                        position: 'relative',
                        height: '100%',
                        width: '30vw',
                        margin: 'auto',
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '40vw',
                        marginLeft: '0',
                    },
                    [theme.breakpoints.down('xs')]: {
                        width: '100vw',
                        height: 'auto',
                        marginTop: '20px',
                    },
                    '& .imageInComunityAchive': {
                        position: 'absolute',
                        zIndex: '1',
                        width: 'auto',
                        height: '90%',
                        top: '5%',
                        [theme.breakpoints.down('md')]: {
                            left: '-20%',
                        },
                        [theme.breakpoints.down('sm')]: {
                            left: '-30%',
                            top: '20%',
                            height: '60%',
                        },
                        [theme.breakpoints.down('xs')]: {
                            width: '320px',
                            height: '247px',
                            position: 'relative',
                            left: '50%',
                            right: 'inherit',
                            marginLeft: '-160px'
                        },
                    },
                },
                '& .six': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '100%',
                    verticalAlign: 'top',
                    marginLeft: '50px',
                    zIndex: '1',
                    float: 'right',
                    [theme.breakpoints.down('lg')]: {
                        width: '20vw',
                        marginLeft: '0',
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '20vw',
                        marginLeft: '0',
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '30vw',
                        marginLeft: '0',
                    },
                    [theme.breakpoints.down('xs')]: {
                        width: '100vw',
                        margin: 'auto',
                        textAlign: 'center',
                        display: 'block',
                    },
                    "& Button": {
                        marginRight: "30px",
                        display: 'flex',
                        width: 241,
                        height: 60,
                        fontWeight: 'bold',
                        alignContent: 'center',
                        alignItems: 'center',
                        [theme.breakpoints.down('lg')]: {
                            marginTop: '-100px',
                        },
                        [theme.breakpoints.down('md')]: {
                            marginTop: '-100px',
                            marginRight: '0px',
                        },
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '-100px',
                            marginRight: '-10px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            margin: '0px auto 40px !important',
                        },
                        '& img': {
                            marginRight: '15px'
                        },
                    }
                },
                '& .seven': {
                    position: 'absolute',
                    bottom: '0',
                    height: '0',
                    backgroundColor: theme.palette.white.main,
                    zIndex: '0',
                    [theme.breakpoints.down('lg')]: {
                        height: '100px',
                        width: '200%'
                    },
                    [theme.breakpoints.down('xs')]: {
                        display: 'none',
                    },
                }
            }
        }
    },
    seventhParallaxMobile: {
        marginTop: '0',
        position: "relative",
        height: 'auto',
        '& .bgColor': {
            justifyContent: 'center',
            width: '100vw',
            backgroundColor: '#fe5b02',
            position: 'relative',
            height: 'auto',
            marginTop: '0',
            '& .containterSeventhParallax': {
                margin: '0',
                height: 'auto',
                width: '100vw',
                position: 'relative',
                '& .one': {
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '0',
                    width: '100%',
                    height: 'auto',
                    margin: 'auto',
                    textAlign: 'center',
                    '& .polenOnYourHand': {
                        marginTop: '110px',
                        width: '100%',
                        textAlign: 'center',
                        '& h1': {
                            display: 'none'
                        },
                        '& p': {
                            margin: '28px auto 0',
                            fontSize: '24px',
                            lineHeight: '24.8px',
                            marginTop: '0',
                            textAlign: 'center',
                            width: '100%',
                            [theme.breakpoints.down('xs')]: {
                                fontSize: '18px',
                                lineHeight: '18.8px',
                            },
                        },
                    },
                    '& .imgDownloadApp': {
                        position: 'relative',
                        marginTop: '40px',
                        flexDirection: 'row',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                        '& img': {
                            maxWidth: '40%',
                            display: 'inline-block',
                            margin: '0px 5%',
                        },
                    }
                },
                '& .two': {
                    display: 'block',
                    verticalAlign: 'top',
                    position: 'relative',
                    zIndex: '1',
                    height: '390px',
                    width: '100%',
                    margin: '20px auto',
                    textAlign: 'center',
                    overflow: 'hidden',
                    paddingBottom: '20px',
                    '& .imageSquare': {
                        width: '200px',
                        margin: 'auto',
                        position: 'relative',
                        height: '100%',
                        '& .phoneBig': {
                            width: "auto",
                            position: 'absolute',
                            top: '50px',
                            left: '-80%',
                            height: '300px',
                            '&.showPhone': {
                                display: 'block'
                            },
                            '&.hidePhone': {
                                display: 'none'
                            },
                        },
                        '& .vectorOnPhone': {
                            margin: "auto",
                            position: 'relative',
                            display: 'block',
                            width: "150px",
                            marginTop: '30px',
                        },
                        '& .logoPolenFlip': {
                            margin: "auto",
                            position: 'absolute',
                            display: 'block',
                            zIndex: '3',
                            width: "100px",
                            height: 'auto',
                            bottom: '0',
                            left: '0',
                        }
                    },
                },
                '& .four': {
                    display: 'block',
                    zIndex: '1',
                    width: '100vw !important',
                    height: 'auto',
                    position: 'relative',
                    margin: 'auto',
                    textAlign: 'center',
                    '& .divRow': {
                        marginTop: '0',
                        position: 'relative',
                        height: '80px',
                        display: 'block',
                        margin: 'auto',
                        maxWidth: '360px',
                        '& .vectorCutOnText': {
                            display: 'block',
                            position: 'absolute',
                            height: '100%',
                            width: 'auto',
                            right: '20px',
                        },
                        '& .vectorMiniOnText': {
                            display: 'block',
                            width: 'auto',
                            height: '50%',
                            left: '20px',
                            bottom: '0px',
                            position: 'absolute',
                        }
                    },
                    '& h1': {
                        fontSize: '32px',
                        lineHeight: '32.8px',
                        height: 'auto !important',
                        textAlign: 'center',
                        [theme.breakpoints.down('xs')]: {
                            fontSize: '24px',
                            lineHeight: '24.8px',
                        },
                    }
                },
                '& .five': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1',
                    position: 'relative',
                    width: '100vw',
                    height: 'auto',
                    marginTop: '20px',
                    '& .imageInComunityAchive': {
                        position: 'relative',
                        zIndex: '1',
                        width: '90%',
                        height: 'auto',
                        maxWidth: '400px',
                    },
                },
                '& .six': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    "& Button": {
                        display: 'flex',
                        width: '100%',
                        maxWidth: '250px',
                        height: '60px',
                        fontWeight: 'bold',
                        alignContent: 'center',
                        alignItems: 'center',
                        margin: '0px auto 40px !important',
                        '& img': {
                            marginRight: '15px'
                        },
                    }
                }
            }
        }
    },
    divHelp: {
        position: 'relative',
        float: 'right',
        top: '-50px',
        right: '10px',
        height: '45px'
    },
    arrowDownWithDot: {
        marginTop: '25px',
        width: 36.2,
        height: 53.7,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        // marginBottom: '10px'
    },
    iconPolen: {
        width: 23.2,
        height: 25.8
    },
    // firstParallax firstParallaxfirstParallax
    // firstParallax firstParallaxfirstParallax
    containterFlexDirection: {
        marginTop: 52,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    // firstParallax firstParallaxfirstParallax
    // firstParallax firstParallaxfirstParallax
    containterYouCompleteOneAchive: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        background: '#60BFBF',
        height: "500px",
    },
    containterYouCompleteAchive: {
        justifyContent: 'center',
        flexDirection: "row",
        alignItems: "center",
    },
    ballSwiper: {
        justifyContent: 'center',
        alignItems: 'center',
        width: "242px",
        height: "242px",
        borderRadius: "121px",
        backgroundColor: "#ffffff",
    },
    iconProfileSix: {
        width: '115px',
        height: '115px'
    },
    downloadIos: {
        width: "193px",
        height: "64.1px",
        [theme.breakpoints.down('sm')]: {
            width: "144px",
            height: "48px",
        },
        // marginTop: "254px"
    },
    downloadAndroid: {
        width: "193px",
        height: "64.1px",
        marginLeft: "14px",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0px",
            width: "144px",
            height: "48px",
            marginTop: '10px',
        },
    },
    PH30: {
        paddingLeft: "30px",
        paddingRight: "30px"
    },
    imageForm: {
        [theme.breakpoints.down('sm')]: {
            right: "0px",
        },
    },
    divFormHome: {
        '& div.MuiGrid-grid-xs-6 .MuiFormControl-fullWidth': {
            width: '90% !important',
            margin: 'auto !important',
            '& .MuiInputBase-root': {
                marginBottom: '10px !important',
            },

        },
        '& div.MuiGrid-grid-xs-6 .MuiFormControl-fullWidth.divSelect': {
            '& .MuiInputBase-root': {
                marginBottom: '0px !important',
            },
        },
        '& button': {
            marginTop: '10px',
            '& p': {
                margin: '5px auto !important',
            }
        },
        '& .divButton': {
            float: 'right',
            maxWidth: '270px',
            marginRight: '5%',
            [theme.breakpoints.down('sm')]: {
                float: 'none',
                maxWidth: '90%',
                margin: '0px 5%',
            },
            [theme.breakpoints.down('xs')]: {
                float: 'none',
                maxWidth: '90%',
                margin: '0px 5%',
                textAlign: 'center',
                '& p': {
                    width: '100% !important',
                    textAlign: 'center !center',
                },
                '& button': {
                    margin: '10px auto !important'
                }
            },
        }
    }
}));
