import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Images from '@Constants-Project/images'
import { setGoal } from '@Redux-Project/actions/globalActions';
import { imageGoalStyles } from '@Styles-Project/theme/components/imageGoal';
import { useMediaQuery } from "@material-ui/core";

const GOALS = [
    { id: '1', 'inactive': Images.iconCircleCar.inactive, 'label': 'Vehículo', 'active': Images.iconCircleCar.active, ref: 'ImageSelectTheGoalYouWillAchieveWithPollen1' },
    { id: '2', 'inactive': Images.iconCircleTravel.inactive, 'label': 'Viaje', 'active': Images.iconCircleTravel.active, ref: 'ImageSelectTheGoalYouWillAchieveWithPollen2' },
    { id: '3', 'inactive': Images.iconCircleBike.inactive, 'label': 'Moto', 'active': Images.iconCircleBike.active, ref: 'ImageSelectTheGoalYouWillAchieveWithPollen3' },
    { id: '4', 'inactive': Images.iconCircleMobile.inactive, 'label': 'Tecnología', 'active': Images.iconCircleMobile.active, ref: 'ImageSelectTheGoalYouWillAchieveWithPollen4' },
    { id: '5', 'inactive': Images.iconCircleHouse.inactive, 'label': 'Remodelación', 'active': Images.iconCircleHouse.active, ref: 'ImageSelectTheGoalYouWillAchieveWithPollen5' }
];

export const ImageGoal = ({ onClick }) => {

    const dispatch = useDispatch();
    const { goal } = useSelector(state => state.global);
    const [idGoalAtive, setIdGoalAtive] = useState(goal);
    const classes = imageGoalStyles();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const clickGoal = (event) => {
        onClick(event);
        setIdGoalAtive(event.target.id);
        dispatch(setGoal(event.target.id))
    };

    useEffect(() => {
        setIdGoalAtive(goal);
    });

    return (
        <Grid container direction={"row"} className={classes.containerIG}>
            {GOALS.map((val, index) => {
                return (
                    <Grid className={`animationTheGoal`} item xs={4} sm={2} key={val.id}>
                        <Grid>
                            <img src={idGoalAtive === val.id ? val.active : val.inactive} id={val.id} alt={val.label} className={classes.rootIG} onClick={clickGoal} />
                            <Grid>
                                <Typography variant="body1" className={`${classes[idGoalAtive === val.id ? 'active' : 'inactive']}`} >{val.label}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
            { isSmallScreen ? <Grid item xs={4} sm={2} ></Grid> : null }
        </Grid>
    )
};
