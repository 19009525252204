let vars = window.configs;
let localVars = JSON.parse(localStorage.getItem('vars'));

export let SERVER = vars === undefined ?
    process.env.REACT_APP_API_DEV :
    (
        vars.ASPNETCORE_ENVIRONMENT === 'Development' ? process.env.REACT_APP_API_DEV :
        (
            vars.ASPNETCORE_ENVIRONMENT === 'Staging' ? process.env.REACT_APP_API_STG : process.env.REACT_APP_API_PROD
        )
    ); // process.env.REACT_APP_SERVER;
export let URL_ALIADOS = localVars !== null ? localVars.reacT_APP_ALIADOS : null; // process.env.REACT_APP_ALIADOS;
export let URL_AUTOATENCION = localVars !== null ? localVars.reacT_APP_AUTOAYUDA : null; // process.env.reacT_APP_AUTOATENCION;
export let URL_AUTOCOMPRA = localVars !== null ? localVars.reacT_APP_AUTOCOMPRA : null; // process.env.REACT_APP_AUTOCOMPRA;
export let LEGALES_CONDITIONS = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_CONDITIONS : null; // process.env.REACT_APP_LINK_LEGALES_CONDITIONS;
export let LEGALES_TYC = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_TERMS : null; // process.env.REACT_APP_LINK_LEGALES_DOCUMENTS;
export let LEGALES_INFO = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_DOCUMENTS : null; // process.env.REACT_APP_LINK_LEGALES_CONSUMER;
export let LEGALES_DEBIT = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_PROCCESS : null; // process.env.REACT_APP_LINK_LEGALES_PROCCESS;
export let LEGALES_DOCS_CREDITO = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_DOCUMENTS_CREDITS : null; // process.env.REACT_APP_LINK_LEGALES_TERMS;
export let LEGALES_IMAGE = localVars !== null ? localVars.reacT_APP_LINK_LEGALES_CONSUMER : null; // process.env.REACT_APP_LINK_LEGALES_TERMS;
export let FORM_CONTACT_URL = localVars !== null ? localVars.reacT_APP_FORM_CONTACT_URL : null; // process.env.REACT_APP_FORM_CONTACT_URL;
export let FORM_CONTACT_TOKEN = localVars !== null ? localVars.reacT_APP_FORM_CONTACT_TOKEN : null; // process.env.REACT_APP_FORM_CONTACT_TOKEN;
export let FORM_BITRIX24 = localVars !== null ? localVars.reacT_APP_FORM_BITRIX24 : null; // process.env.REACT_APP_FORM_BITRIX24;
export let LINK_VIDEO_WHATISPOLEN = localVars !== null ? localVars.reacT_APP_LINK_VIDEO_WHATISPOLEN : null; // process.env.REACT_APP_LINK_VIDEO_WHATISPOLEN;
export let LINK_VIDEO_HOME = localVars !== null ? localVars.reacT_APP_LINK_VIDEO_HOME : null; // process.env.REACT_APP_LINK_VIDEO_HOME;
export let ID_VEHICULO = localVars !== null ? localVars.reacT_APP_ID_VEHICULO : null; // process.env.REACT_APP_ID_VEHICULO;
export let ID_MOTO = localVars !== null ? localVars.reacT_APP_ID_MOTO : null; // process.env.REACT_APP_ID_MOTO;
export let ID_TECNOLOGIA = localVars !== null ? localVars.reacT_APP_ID_TECNOLOGIA : null; // process.env.REACT_APP_ID_TECNOLOGIA;
export let ID_VIAJES = localVars !== null ? localVars.reacT_APP_ID_VIAJES : null; // process.env.REACT_APP_ID_VIAJES;
export let ID_REMODELACION = localVars !== null ? localVars.reacT_APP_ID_REMODELACION : null; // process.env.REACT_APP_ID_REMODELACION;
export let DATA_PLANS = [
    { id: localVars !== null ? localVars.reacT_APP_ID_VEHICULO : null, type: "Vehículo" }, // process.env.REACT_APP_ID_VEHICULO, type: "VEHÍCULO" },
    { id: localVars !== null ? localVars.reacT_APP_ID_MOTO : null, type: "Moto" }, // process.env.REACT_APP_ID_MOTO, type: "MOTO" },
    { id: localVars !== null ? localVars.reacT_APP_ID_TECNOLOGIA : null, type: "Tecnología" }, // process.env.REACT_APP_ID_TECNOLOGIA, type: "TECNOLOGÍA" },
    { id: localVars !== null ? localVars.reacT_APP_ID_VIAJES : null, type: "Viajes" }, // process.env.REACT_APP_ID_VIAJES, type: "VIAJES" },
    { id: localVars !== null ? localVars.reacT_APP_ID_REMODELACION : null, type: "Remodelación" }, // process.env.REACT_APP_ID_REMODELACION, type: "REMODELACIÓN" },
];
export function reloadData () {
    vars = window.configs;
    SERVER = vars === undefined ?
        process.env.REACT_APP_API_DEV :
        (
            vars.ASPNETCORE_ENVIRONMENT === 'Development' ? process.env.REACT_APP_API_DEV :
                (
                    vars.ASPNETCORE_ENVIRONMENT === 'Staging' ? process.env.REACT_APP_API_STG : process.env.REACT_APP_API_PROD
                )
        );
    localVars = JSON.parse(localStorage.getItem('vars'));
    URL_ALIADOS = localVars.reacT_APP_ALIADOS;
    URL_AUTOCOMPRA = localVars.reacT_APP_AUTOCOMPRA;
    LEGALES_CONDITIONS = localVars.reacT_APP_LINK_LEGALES_CONDITIONS;
    LEGALES_TYC = localVars.reacT_APP_LINK_LEGALES_TERMS;
    LEGALES_INFO = localVars.reacT_APP_LINK_LEGALES_DOCUMENTS;
    LEGALES_DEBIT = localVars.reacT_APP_LINK_LEGALES_PROCCESS;
    LEGALES_IMAGE = localVars.reacT_APP_LINK_LEGALES_CONSUMER;
    FORM_CONTACT_URL = localVars.reacT_APP_FORM_CONTACT_URL;
    FORM_CONTACT_TOKEN = localVars.reacT_APP_FORM_CONTACT_TOKEN;
    FORM_BITRIX24 = localVars.reacT_APP_FORM_BITRIX24;
    LINK_VIDEO_WHATISPOLEN = localVars.reacT_APP_LINK_VIDEO_WHATISPOLEN;
    LINK_VIDEO_HOME = localVars.reacT_APP_LINK_VIDEO_HOME;
    ID_VEHICULO = localVars.reacT_APP_ID_VEHICULO;
    ID_MOTO = localVars.reacT_APP_ID_MOTO;
    ID_TECNOLOGIA = localVars.reacT_APP_ID_TECNOLOGIA;
    ID_VIAJES = localVars.reacT_APP_ID_VIAJES;
    ID_REMODELACION = localVars.reacT_APP_ID_REMODELACION;
    DATA_PLANS = [
        { id: localVars.reacT_APP_ID_VEHICULO, type: "Vehículo" },
        /*{ id: localVars.reacT_APP_ID_MOTO, type: "Moto" },
        { id: localVars.reacT_APP_ID_TECNOLOGIA, type: "Tecnología" },
        { id: localVars.reacT_APP_ID_VIAJES, type: "Viajes" },
        { id: localVars.reacT_APP_ID_REMODELACION, type: "Remodelación" },*/
    ];
}
export function reloadDataBase () {
    vars = window.configs;
    // console.log(vars);
    // console.log(process.env);
    SERVER = vars === undefined ?
        process.env.REACT_APP_API_DEV :
        (
            vars.ASPNETCORE_ENVIRONMENT === 'Development' ? process.env.REACT_APP_API_DEV :
                (
                    vars.ASPNETCORE_ENVIRONMENT === 'Staging' ? process.env.REACT_APP_API_STG : process.env.REACT_APP_API_PROD
                )
        );
    return SERVER !== undefined;
}
