import { makeStyles } from "@material-ui/core/styles";

export const scheduleStyles = makeStyles((theme) => ({
    bgGray: {
        width: '100vw',
        height: '450px',
        backgroundColor: theme.palette.gray.gray_F6,
        [theme.breakpoints.down('sm')]: {
            maxHeight: '400px !important',
        },
        '& .divContainer': {
            height: '100% !important',
            maxHeight: '450px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                paddingTop: '80px',
            },
            '& h1': {
                fontSize: '56px',
                lineHeight: '50.2px',
                textAlign: 'left',
                paddingLeft: '30px',
                marginTop: '-250px',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '48px',
                },
                [theme.breakpoints.down('sm')]: {
                    marginTop: '-30px',
                    fontSize: '48px',
                    textAlign: 'center !important',
                    paddingLeft: '0px !important',
                },
                [theme.breakpoints.down('xs')]: {
                    marginTop: '-30px',
                    fontSize: '24px',
                    textAlign: 'center !important',
                    paddingLeft: '0px !important',
                },
                '& b': {
                    color: theme.palette.primary.main
                }
            },
            '& img': {
                height: '80%',
                position: 'absolute',
                right: '30px',
                bottom: '0',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '85%',
                    height: 'auto !important',
                    position: 'absolute',
                    bottom: '0px',
                    maxHeight: '280px',
                },
            }
        },
    },
    eventTimer: {
        height: 'auto !important',
        padding: '30px 25px',
        margin: 'auto !important',
        '& >h1': {
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '5px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                lineHeight: '22.8px',
                height: 'auto !important',
                marginBottom: '10px',
            },
            '&.orange': {
                color: theme.palette.primary.main,
                fontSize: '38px',
                lineHeight: '40.8px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '22px',
                    lineHeight: '24.8px',
                },
            }
        },
        '& >h2': {
            fontSize: '24px',
            lineHeight: '24.8px',
            color: theme.palette.primary.main,
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '16.6px',
            },
        },
        '& >p': {
            fontSize: '16px',
            lineHeight: '18.8px',
            marginBottom: '40px',
            textAlign: 'center',
        },
        '& >div': {
            marginBottom: '40px',
            width: '100%',
            maxWidth: '600px',
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '0px',
            },
            '& div': {
                textAlign: 'center',
                [theme.breakpoints.down('sm')]: {
                    padding: '10px 0px !important',
                },
                '& .bubble': {
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.t_15,
                    width: '112px',
                    height: '112px',
                    position: 'relative',
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& >h1': {
                        fontSize: '36px',
                        lineHeight: '42px',
                        marginBottom: '7px',
                        [theme.breakpoints.down('sm')]: {
                            marginBottom: '-7px',
                            fontSize: '20px',
                            lineHeight: 'normal',
                        },
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '60px',
                        height: '60px',
                    },
                },
                '& p': {
                    fontSize: '18px',
                    lineHeight: '12.8px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginTop: '10px',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '14px',
                        lineHeight: '16.4px',
                        width: '100%'
                    },
                },
                '& button': {
                    margin: 'auto',
                    [theme.breakpoints.down('sm')]: {
                        width: '150px'
                    },
                }
            },
        }
    },
    bgGraySchedule: {
        width: '100vw',
        // height: '550px',
        backgroundColor: theme.palette.gray.gray_F6,
        '& >h1': {
            fontSize: '36px',
            lineHeight: '36.8px',
            marginBottom: '5px',

        },
        '& .divSchedule': {
            height: 'auto !important',
            padding: '24px 25px',
            position: 'relative',
            margin: 'auto !important',
            '& >h1': {
                fontSize: '36px',
                lineHeight: '36.8px',
                marginBottom: '30px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '20px',
                    lineHeight: '22.8px',
                },
            },
            '& .calendarContent': {
                position: 'relative',
                // height: '400px',
                '& .divScheduler': {
                    '& >div': {
                        [theme.breakpoints.down('sm')]: {
                            backgroundColor: theme.palette.white.main,
                            padding: '2px',
                            borderRadius: '5px',
                            webkitBorderRadius: '5px',
                            mozBorderRadius: '5px',
                        },
                    }
                },
                '& .MuiTable-root': {
                    minWidth: '100% !important',
                    width: '100% !important',
                    /* '& .MuiTableCell-root': {
                         backgroundColor: theme.palette.gray.gray_A7,
                         mozBorderRadius: '50%',
                         borderRadius: '50%',
                         webkitBorderRadius: '50%',
                         width: '15px',
                         height: '15px',
                     }*/
                    '& .MuiTableCell-root': {
                        height: 'auto !important',
                        '&.MuiTableCell-body': {
                            backgroundColor: 'transparent !important',
                            border: '0px !important',
                            textAlign: 'center',
                            '& >div': {
                                height: '30px',
                                overflow: 'hidden',
                                display: 'flex',
                                padding: '0px !important',
                                justifyContent: 'center',
                                width: '30px',
                                margin: '5px auto !important',
                                lineHeight: '30px',
                                position: 'relative',
                                textAlign: 'center',
                                alignItems: 'flex-start',
                            }
                        }
                    }
                }
            },
            '& .meetings': {
                overflow: 'hidden',
                overflowY: 'scroll',
                height: '400px',
                padding: '25px',
                [theme.breakpoints.down('sm')]: {
                    padding: '25px 0px',
                    height: '350px',
                },
                '& .cardSchedule': {
                    backgroundColor: theme.palette.white.main,
                    width: '100%',
                    height: '85px',
                    borderLeft: '5px solid',
                    borderLeftColor: theme.palette.primary.main,
                    margin: '10px 0px',
                    '& .date': {
                        height: '85px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& h1': {
                            fontSize: '36px',
                            lineHeight: '36.8px',
                            color: theme.palette.primary.main,
                        }
                    },
                    '& .event': {
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        '& >div': {
                            '& h1': {
                                textAlign: 'left',
                                fontSize: '18px',
                                color: theme.palette.black.main,
                                lineHeight: '18.8px',
                                marginBottom: '0px',
                                height: 'auto !important',
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '16px',
                                    lineHeight: '16.4px',
                                },
                            },
                            '& p': {
                                textAlign: 'left',
                                fontSize: '16px',
                                lineHeight: '16.2px',
                                color: theme.palette.black.main,
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '14px',
                                    lineHeight: '14.4px',
                                    textTransform: 'none',
                                },
                            }
                        }
                    },
                    '&.inactive': {
                        borderLeftColor: theme.palette.gray.gray_A7 + '!important',
                        '& .date': {
                            '& h1': {
                                color: theme.palette.gray.gray_A7 + '!important',
                            }
                        },
                        '& .event': {
                            '& >div': {
                                '& h1': {
                                    color: theme.palette.gray.gray_A7 + '!important',
                                },
                                '& p': {
                                    color: theme.palette.gray.gray_A7 + '!important',
                                }
                            }
                        },
                    }
                },
                '& .buttons': {
                    marginTop: '30px',
                    textAlign: 'center',
                    '& button': {
                        width: '90% !important',
                        margin: 'auto',
                        textTransform: 'none',
                        fontSize: '16px !important'
                    }
                }
            }
        },
    },
    btnDay: {
        backgroundColor: theme.palette.black.t_12,
        border: 'none',
        width: '30px',
        height: '30px',
        display: 'block',
        cursor: 'pointer'
    },
}));
