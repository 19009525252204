import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button, Container, withStyles, FormControlLabel, TextField, Select, MenuItem, Checkbox, Link } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { talktoUSStyles } from '@Styles-Project/theme/layout/talkToUs';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';
import { inputStyles } from '@Styles-Project/theme/components/inputs';
import { stepsStyles } from '@Styles-Project/theme/layout/steps/steps';
import convert from 'htmr';
import { setAllFAQsActive } from '@Redux-Project/actions/talkUsActions';
import { useSelector } from 'react-redux';
import api from '@Constants-Project/api';
import {
    FORM_CONTACT_URL,
    FORM_CONTACT_TOKEN,
    LEGALES_TYC,
    LEGALES_CONDITIONS,
    LEGALES_INFO
} from "@Constants-Project/constants";
import { Alert } from "@Components-Project/alert";

const OrangeCheckbox = withStyles({
    root: {
        color: '#fe5b02',
        '&$checked': {
            color: '#fe5b02',
        },
    },
    checked: {},
})((props) => <Checkbox color='default' {...props} />);

export default function Content({ menu, data }) {

    return <Grid className={'bgContent'} >
        <MountDataContent menu={menu} data={data} />
    </Grid>
}

const MountDataContent = ({ menu, data }) => {
    const TalkStyles = talktoUSStyles();
    const tClasses = typographiesStyles();
    const stepsClasses = stepsStyles();
    const buttonsClasses = buttonsStyles();
    const inputsClasses = inputStyles();
    const { PQRTypes } = useSelector(state => state.talkUs)
    const [formValid, setFormValid] = React.useState(false);
    const InputProps = {
        className: inputsClasses.inputTalkUs,
        disableUnderline: true,
    };
    const [questions, changeShowQuestions] = React.useState([]);
    const [dataAlert, setDataAlert] = React.useState({ type: '', title: '', messageAlert: '', showInput: false })
    const { type, title, messageAlert, showInput } = dataAlert;

    const [formState, SetFormState] = React.useState({
        name: '', lastName: '', email: '',
        celPhone: '', subject: '',
        message: '', agree: false
    });

    React.useEffect(() => {
        changeShowQuestions(data)
    })

    const { name, lastName, email, celPhone, subject, message, agree } = formState;

    const isFormValid = React.useCallback(() => {
        if (!name) return false;
        if (!lastName) return false;
        if (!email) return false;
        if (!celPhone) return false;
        if (!subject) return false;
        if (!message) return false;
        return true;
    }, [name, lastName, email, celPhone, subject, message, agree]);

    useEffect(() => {
        (isFormValid()) ? setFormValid(true) : setFormValid(false);
    }, [isFormValid]);

    const handleInputChange = ({ target }) => {
        SetFormState({
            ...formState,
            [target.name]: target.value
        });
        isFormValid();
    };

    const handleInputChangePhone = ({ target }) => {
        if (target.value.length <= 10 && (Math.sign(target.value) != -1 || target.value == 0)) SetFormState({
            ...formState,
            [target.name]: target.value
        });
    };

    const handleChange = (event) => {
        SetFormState({
            ...formState,
            [event.target.name]: event.target.value
        })
        isFormValid();
    };

    const handleCheckBoxChange = ({ target }) => {
        SetFormState({
            ...formState,
            [target.name]: target.checked
        });
        isFormValid();
    };

    const handleSendData = async (e) => {
        e.preventDefault();
        const body = { "pqrsContactTypeID": subject.split(' ')[0], name, lastName, email, "phone": celPhone, "comments": message }
        const response = await api.POST('api/Contacts', body);
        if (response.success) {
            const bodyToPolen = {
                'sagicc_token': FORM_CONTACT_TOKEN,
                'nombre': name,
                'apellido': lastName,
                'email': email,
                'telefono': celPhone,
                'mensaje': message,
                "tipoPQR": subject
            };
            const responsePolen = await api.POSTG(FORM_CONTACT_URL, bodyToPolen);
            if (responsePolen.status === 200) {
                setDataAlert({ type: 'Correct', title: 'Envío exitoso', messageAlert: 'La petición se realizó correctamente.', showInput: true })
                SetFormState({ name: '', lastName: '', email: '', celPhone: '', subject: '', message: '', agree: '' })
            }
        }
        else setDataAlert({ type: 'InCorrect', title: 'Error', messageAlert: response.message + ' Code: ' + response.status, showInput: true })
    };

    const showQuestion = (key) => {
        let questionsTemp = questions;
        questionsTemp.faQs[key].show = !questionsTemp.faQs[key].show
        changeShowQuestions({ ...questionsTemp })
    };

    console.log('questions => ', questions)
    if (menu == 1) return <>
        <Typography className={""} variant={'h1'}>Preguntas frecuentes</Typography>
        {
            !questions || questions.length <= 0 ? null :
                <>
                    <Typography className={`${TalkStyles.SubTitle} textWithBorderLeft`} variant={'h2'}>{questions.category}</Typography>
                    {questions.faQs.map((item, id) => {
                        return <Box onClick={() => showQuestion(id)} key={id.toString()} className={TalkStyles.bgContentMenu}>
                            <Button className={`options ${item.show ? 'show' : ''}`}>{item.question} <img src={Images.rowDown} /></Button>
                            <p className={`description  ${item.show ? '' : 'hide'}`}>{convert(item.answer)}</p>
                        </Box>
                    })}
                </>
        }
    </>
    if (menu == 2) return <>
        <form onSubmit={handleSendData} noValidate={true} autoComplete="off" className={"contactForm"}>
            <Typography variant={'h1'}>Déjanos Llamarte</Typography>
            <TextField
                name="name"
                value={name}
                onChange={handleInputChange}
                label="Nombres"
                focused={false}
                placeholder="Escribe tus nombres"
                fullWidth
                displayEmpty
                required
                InputProps={InputProps}
                className={inputsClasses.inputContainerTalkUs}
                InputLabelProps={{
                    shrink: true,
                    className: inputsClasses.labelTalkUs
                }}
            />
            <TextField
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                label="Apellidos"
                focused={false}
                placeholder="Escribe tus Apellidos"
                fullWidth
                displayEmpty
                required
                InputProps={InputProps}
                className={inputsClasses.inputContainerTalkUs}
                InputLabelProps={{
                    shrink: true,
                    className: inputsClasses.labelTalkUs
                }}
            />
            <TextField
                name="email"
                value={email}
                onChange={handleInputChange}
                label="Email"
                focused={false}
                placeholder="Escribe tu email"
                fullWidth
                displayEmpty
                required
                InputProps={InputProps}
                className={inputsClasses.inputContainerTalkUs}
                InputLabelProps={{
                    shrink: true,
                    className: inputsClasses.labelTalkUs
                }}
            />
            <TextField
                name="celPhone"
                value={celPhone}
                onChange={handleInputChangePhone}
                label="Celular"
                focused={false}
                placeholder="Escribe tu celular"
                type='number'
                fullWidth
                displayEmpty
                required
                InputProps={InputProps}
                className={inputsClasses.inputContainerTalkUs}
                InputLabelProps={{
                    shrink: true,
                    className: inputsClasses.labelTalkUs
                }}
            />

            <Box className={`${inputsClasses.inputContainerTalkUs} ${'MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth'}`}>
                <label variant="body1" className={`${inputsClasses.labelTalkUs} ${'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink'}`}>Asunto:</label>
                <Box>
                    <Select
                        value={subject}
                        onChange={handleChange}
                        name='subject'
                        displayEmpty
                        InputProps={InputProps}
                        className={inputsClasses.selectContainer}
                        style={{marginTop: '26px', marginBottom: '14px'}}
                    >
                        <MenuItem value="" disabled> Selecciona el asunto </MenuItem>
                        {
                            !PQRTypes ? null : PQRTypes.map((item, key) => <MenuItem value={item.id + ' - ' + item.type}>{item.type}</MenuItem>)
                        }

                    </Select>
                </Box>
            </Box>


            <TextField
                name="message"
                value={message}
                onChange={handleInputChange}
                label="Mensaje"
                focused={false}
                placeholder="Escribe tu mensaje"
                fullWidth
                displayEmpty
                required
                InputProps={InputProps}
                className={inputsClasses.inputContainerTalkUs}
                InputLabelProps={{
                    shrink: true,
                    className: inputsClasses.labelTalkUs
                }}
            />

            <Grid item xs={12} className={`${stepsClasses.mt15} ${stepsClasses.ckBoxInline}`}>
                <Typography variant="body1" className={tClasses.body112}>
                    Al hacer click en el botón “Enviar mensaje”, aceptas las <Link href={LEGALES_CONDITIONS} target="_blank"><b>Términos y Condiciones</b></Link> y las <Link href={LEGALES_INFO} target="_blank"><b>Políticas de Tratamiento de Datos de Polen</b></Link>.
                </Typography>
            </Grid>

            <Grid item xs={12} className={stepsClasses.boxBtnNext}>
                <Button variant="contained" type={'submit'} className={`${buttonsClasses.button} ${buttonsClasses.orangeButton}`} disabled={formValid === false} >Enviar mensaje</Button>
            </Grid>
            <Alert showInput={showInput} type={type} title={title} message={messageAlert} onPressClose={() => setDataAlert({ ...dataAlert, showInput: false })} />
        </form>
    </>
    if (menu == 3) return <Box className={TalkStyles.containCallUs}>
        <Typography variant={'h2'} className={'headerText'}>Llámanos</Typography>

        <Grid container className='contenInfoContacUS'>
            <Grid item xs={12} sm={6} className='contenInfo'>
                <Button className={'button'}>{'Ayuda en la compra'} </Button>
                <Box className={'divText'}>
                    <p className={'textLeft'}>Llama: 914 3918 <span>en Bogotá</span></p>
                    <p className={'textLeft'}>Whatsapp: <a href="https://wa.me/+573213095057">321 309 5057</a></p>
                    <p className={'textLeftAtencion'}>Atención:</p>
                    <p className={'textLeftValue'}>Lunes a viernes de 8 AM a 5 PM</p>
                    <p className={'textLeftValue'}>Sábado de 8 AM a 12 M</p>
                    <p className={'email'}><a href="mailto: ventas@polen.com.co" target="_blank">ventas@polen.com.co</a></p>
                    <Box className={'balls'}>
                        <Box className={'one'}></Box>
                        <Box className={'two'}></Box>
                        <Box className={'three'}></Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} className='contenInfo'>
                <Button className={'button'}>{'Usuario Polen'} </Button>
                <Box className={'divText right'}>
                    <p className={'textLeft'}>Llama: 914 3918 <span>en Bogotá</span></p>
                    <p className={'textLeft'}>Whatsapp: <a href="https://wa.me/+573213095057">321 309 5057</a></p>
                    <p className={'textLeftAtencion'}>Atención:</p>
                    <p className={'textLeftValue'}>Lunes a viernes de 8 AM a 5 PM</p>
                    <p className={'textLeftValue'}>Sábado de 8 AM a 12 M</p>
                    <p className={'email'}><a href="mailto: cliente@polen.com.co" target="_blank">cliente@polen.com.co</a></p>
                    <Box className={'balls right'}>
                        <Box className={'one'}></Box>
                        <Box className={'two'}></Box>
                        <Box className={'three'}></Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
        <img className='imagePeople' src={Images.groupFriends} alt={Images.groupFriends} />
    </Box>
    return null;
}
