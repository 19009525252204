import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import Images from '@Constants-Project/images';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import { useSelector } from "react-redux";
import $ from "jquery";

export default function NewAchievements() {
    const wipStyles = whatIsPolenStyles();
    const { OurGoals } = useSelector(state => state.whatIsPolen)
    let play = false;
    let count = 0;

    const handleScroll = (event) => {
        const { scrollTop } = event.target.scrollingElement;
        let secPosition = $('#OurGoals').position();
        let secHeight = $('#OurGoals').height();
        if (secPosition !== undefined && secPosition.hasOwnProperty('top')) {
            let startPoint = secPosition.top - (secHeight * 1.5);
            play = scrollTop >= startPoint;
            count = scrollTop < startPoint ? 0 : count;
            if (play && count === 0) {
                count++;
                $('.count').each(function () {
                    $(this).prop('Counter',0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 4000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return <Box className={wipStyles.divAchievements} id={'OurGoals'}>
        <ContainerComponent>
            <Typography variant={'h1'}>Nuestros logros</Typography>
            <Grid container direction='row' >
                <Grid item xs={12} sm={3}>
                    <Box className={'bubble'}>
                        <img alt={Images.iconProfileWithDot} src={Images.iconProfileWithDot} />
                        <Typography variant={'h2'}>Clientes activos</Typography>
                        <Typography variant={'h1'} className={'count'}>{ OurGoals.clientesActivos }</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={1} className={'divSeparator'}>
                    <img alt={Images.bubbleSeparator} src={Images.bubbleSeparator} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box className={'bubble'}>
                        <img alt={Images.iconHeartWithDot} src={Images.iconHeartWithDot} />
                        <Typography variant={'h2'}>Metas cumplidas</Typography>
                        <Typography variant={'h1'} className={'count'}>{ OurGoals.metasCumplidas }</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={1} className={'divSeparator'}>
                    <img alt={Images.bubbleSeparator} src={Images.bubbleSeparator} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box className={'bubble'}>
                        <img alt={Images.iconProfileWithDot} src={Images.iconProfileWithDot} />
                        <Typography variant={'h2'}>Adjudicaciones</Typography>
                        <Typography variant={'h1'} className={'count'}>{ OurGoals.adjudicaciones }</Typography>
                    </Box>
                </Grid>
            </Grid>
        </ContainerComponent>
    </Box>
}
