const Colors = {
    primary: 'rgba(254,91,2,1)',
    primary_9: 'rgba(254,91,2,0.9)',
    primary_15: 'rgba(254, 91, 2, 0.15)',
    primary_70: 'rgba(254, 91, 2, 0.7)',
    primary_c: 'rgba(255, 140, 77, 1)',
    white: 'rgba(255, 255, 255, 1)',
    black: 'rgba(0, 0, 0, 1)',
    black_12: 'rgba(0, 0, 0, 0.12)',
    black_24: 'rgba(0, 0, 0, 0.24)',
    grayF2: 'rgba(242, 242, 242, 1)',
    grayA7: 'rgba(167, 168, 170, 1)',
    grayA7_80: 'rgba(167, 168, 170, 0.8)',
    grayA7_60: 'rgba(167, 168, 170, 0.6)',
    grayA7_40: 'rgba(167, 168, 170, 0.4)',
    grayA7_20: 'rgba(167, 168, 170, 0.2)',
    grayA7_30: 'rgba(167, 168, 170, 0.3)',
    grayF6: 'rgba(246, 246, 247, 1)',
    red: 'rgba(255, 0, 0, 1)',
    sepia: 'rgba(254, 91, 2, 0.6)',
};

export default Colors
