import { makeStyles } from "@material-ui/core/styles";
import { BeVietnamRegular } from '@Constants-Project/fonts'

export const talktoUSStyles = makeStyles((theme) => ({
    linkRoute: {
        height: '80px',
        alignItems: "center",
        width: "100vw",
        maxWidth: '1024px',
        margin: 'auto',
        flexDirection: 'row',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 31
        },
        '& .link': {
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '& .orange': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        '& .programate': {
            width: '213px',
            height: '46px',
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '22.4px',
            letterSpacing: 'normal',
            color: '#000000',
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        }
    },
    bgOrange: {
        width: '100vw',
        height: '469px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            height: 'auto',
        },
        backgroundColor: theme.palette.primary.main,
        '& .divContainer': {
            display: 'flex',
            height: '100%',
            maxHeight: '100%',
            position: 'relative',
            flexDirection:'column',
            alignItems: 'center',
            width: '100vw',
            maxWidth: '1024px',
            margin: 'auto',
            minHeight: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '450px !important',
            },
            [theme.breakpoints.down('xs')]: {
                height: '400px !important',
            },
            '& h1': {
                marginTop:'82px',
                fontSize: '50px',
                lineHeight: '52.3px',
                textAlign: 'left',
                color: theme.palette.white.main,
                paddingLeft: '30px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: "50px",
                    fontSize: '42px',
                    textAlign: 'center',
                    paddingRight: '30px',
                },
                [theme.breakpoints.down('xs')]: {
                    marginTop: "30px",
                    fontSize: '36px',
                    textAlign: 'center',
                    paddingRight: '30px',
                },
            },
            '& h6': {
                marginTop:'20px',
                fontSize: '18px',
                textAlign: 'left',
                color: theme.palette.white.main,
                paddingLeft: '30px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: "30px",
                    fontSize: '16px',
                    textAlign: 'center',
                    paddingRight: '30px',
                },
                [theme.breakpoints.down('xs')]: {
                    marginTop: "30px",
                    fontSize: '14px',
                    textAlign: 'center',
                    paddingRight: '30px',
                },
            },

            '& .notch': {
                top: "60px",
                left: "30px",
                width: "70px",
                position: 'absolute',
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                },
            },

            '& .imageBig': {
                height: '400px',
                [theme.breakpoints.down('sm')]: {
                    position: 'absolute',
                    height: '250px',
                    bottom: '0px',
                },
                [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    right: '40px',
                    bottom: '0px',
                },
            },

        }
    },

    bgMain: {
        width: '100vw',
        maxHeight: 'auto',
        // maxHeight: '720px',
        flexDirection: 'column',
        display: 'flex',
        backgroundColor: "#f6f6f7",
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
        },

        '& .divContainer': {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            // flexDirection: 'column !important',
            maxHeight: '700px',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column !important',
                alignContent: 'center'
            },

            '& .containerMenu': {
                margin: '30px',
                width: '250px',
                [theme.breakpoints.down('sm')]: {
                    width: '90%',
                    margin: '30px auto 0px !important',
                },
                '& .bgMenu': {
                    padding: '20px',
                    borderRadius: "4px",
                    color: "#a7a8aa",
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.white.main,
                    height: '100%',
                    width: '100%',
                    maxHeight: '800px',

                    [theme.breakpoints.down('sm')]: {
                        height: 'auto',
                    },

                    '& .buttonMain': {
                        fontFamily: BeVietnamRegular,
                        borderRadius: '0px',
                        marginTop: '20px',
                        fontSize: '16px',
                        color: "#a7a8aa",
                        // lineHeight: '1.05px',
                        textAlign: 'left',
                        textTransform: 'none',
                        '&--show': {
                            borderRadius: '0px',
                            marginTop: '20px',
                            fontSize: '16px',
                            // lineHeight: '1.05px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            textTransform: 'none',
                            borderLeft: '4px solid',
                            borderLeftColor: theme.palette.primary.main,
                            color: theme.palette.black.main,
                        }
                    },

                    '& .buttonSecondary': {
                        borderRadius: '0px',
                        display: 'none',
                        marginTop: '20px !important',
                        marginLeft: '20px !important',
                        fontSize: '14px !important',
                        // lineHeight: '1.05px !important',
                        color: "#a7a8aa",
                        textAlign: 'left !important',
                        // fontWeight: 'bold !important',
                        textTransform: 'none !important',
                        transition: 'none',
                        '&.show': {
                            display: 'flex',
                            fontWeight: 'bold !important',
                            '&.select': {
                                color: theme.palette.black.main,
                                display: 'flex',
                                borderLeft: '4px solid',
                                borderLeftColor: theme.palette.primary.main,
                            }
                        },
                    }
                },
            },

            '& .containerContent': {
                margin: '30px',
                display: 'flex',
                position: 'relative',
                width: '100%',
                maxWidth: '650px',
                height: '100%',
                maxHeight: '800px',
                [theme.breakpoints.down('md')]: {
                    height: "auto",
                    maxHeight: "inherit",
                    maxWidth: '100%',
                    width: '90% !important',
                },
                [theme.breakpoints.down('sm')]: {
                    height: "auto",
                    maxHeight: "inherit",
                    maxWidth: '90%',
                    margin: '30px auto !important',
                    width: '90% !important',
                },
                '& .bgContent': {
                    overflow: "auto",
                    padding: '40px',
                    borderRadius: "4px",
                    backgroundColor: theme.palette.white.main,
                    height: '100%',
                    maxHeight: '800px',
                    width: '100%',
                    [theme.breakpoints.down('md')]: {
                        height: "auto",
                        maxHeight: "inherit",
                    },
                    '& h1': {
                        fontSize: '36px',
                        fontWeight: 'bold',
                        lineHeight: '37.6px',
                        textAlign: 'left',
                        textTransform: 'none',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '20px',
                        },
                        [theme.breakpoints.down('xs')]: {
                            fontSize: '16px',
                        },
                    },

                    '& .textWithBorderLeft': {
                        marginTop: '20px',
                        fontSize: '25px',
                        // lineHeight: '1.05px',
                        textAlign: 'left',
                        textTransform: 'none',
                        borderLeft: '4px solid',
                        borderLeftColor: theme.palette.primary.main,
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '18px',
                        },
                    },
                    '& .contactForm': {
                        '& h1': {
                            lineHeight: '36.4px',
                            marginBottom: '30px'
                        },
                        '& .MuiFormControl-root': {
                            position: 'relative',
                            marginBottom: '10px',
                            height: 'auto',
                            paddingTop: '0px',
                            '& label': {
                                fontSize: '16px',
                                lineHeight: '18.6px',
                                height: '16px',
                            },
                            '&>div': {
                                height: 'auto',
                                marginTop: '18px',
                                marginBottom: '0px',
                                '& select': {
                                    width: '100%'
                                }
                            }
                        }
                    }
                },
            },

        }
    },

    bgContentMenu: {
        marginTop: '10px',
        '& .options': {
            borderRadius: '0px',
            borderColor: "#000",
            marginTop: '20px',
            fontWeight: 'bold',
            textAlign: 'left',
            color: "#000000",
            textTransform: 'none',
            width: '100%',
            // height: "25px",
            justifyContent: 'space-between',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.gray.gray_A7,
            [theme.breakpoints.down('sm')]: {
                // height: "40px",
                fontSize: '12px',
            },
            '& img': {
                width: '10px',
                // height: '5px'
            },
            '&.show': {
                borderBottom: "0px solid",
            }
        },
        '& .description': {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            // lineHeight: '1.05',
            letterSpacing: 'normal',
            color: '#a7a8aa',
            borderBottom: "0.5px solid",
            '&.hide': {
                display: 'none',
            }
        }
    },

    SubTitle: {
        // lineHeight: "0px !important",
        borderLeft: '4px solid',
        borderLeftColor: theme.palette.primary.main,
        paddingLeft: '10px',
    },

    containCallUs: {
        backgroundColor: theme.palette.primary.main,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        '& .headerText': {
            width: '100%',
            fontSize: '32px',
            lineHeight: '34.4px',
            letterSpacing: 'normal',
            color: '#ffffff',
            textAlign: 'left',
            padding: '30px 30px 0'
        },
        '& .contenInfoContacUS': {
            alignItems: 'center',
            // display: 'flex',
            // flexDirection: 'row',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
            '& .contenInfo': {
                paddingTop: '30px',
                alignItems: 'top',
                // justifyContent: 'center',
                textAlign: 'center',
                '& .button': {
                    width: '100%',
                    maxWidth: '250px',
                    height: '50px',
                    borderRadius: '27.5px',
                    backgroundColor: '#ffffff',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    textTransform: 'none',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    lineHeight: '22.8px',
                    color: '#000000',
                    margin: 'auto',
                },
                '& .divText': {
                    width: '100%',
                    maxWidth: '250px',
                    padding: '10px 10px',
                    textAlign: 'left',
                    position: 'relative',
                    margin: 'auto',
                    '&.right': {
                        paddingLeft: '40px',
                        [theme.breakpoints.down('xs')]: {
                            paddingLeft: '10px',
                        },
                    },
                    '& .balls': {
                        position: 'absolute',
                        maxWidth: '40px',
                        top: '10px',
                        right: '0',
                        textAlign: 'center',
                        [theme.breakpoints.down('xs')]: {
                            display: 'none',
                        },
                        '& div': {
                            border: '1px solid white',
                            borderRadius: '50%',
                            webkitBorderRadius: '50%',
                            mozBorderRadius: '50%',
                            backgroundColor: theme.palette.white.main,
                            display: 'block',
                            margin: '8px auto',
                            '&.one': {
                                width: '20px',
                                height: '20px'
                            },
                            '&.two': {
                                width: '15px',
                                height: '15px'
                            },
                            '&.three': {
                                width: '10px',
                                height: '10px'
                            }
                        },
                        '&.right': {
                            right: 'inherit',
                            left: '0'
                        }
                    }
                },
            },
            '& .textLeft': {
                fontSize: '14px',
                fontWeight: 'bold',
                margin: '5px 0px',
                color: '#ffffff',
                '& a': {
                    color: theme.palette.white.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                    }
                }
            },

            '& .textLeftAtencion': {
                fontSize: '14px',
                fontWeight: 'bold',
                margin: '10px 0px 0px',
                color: '#ffffff',
                '& a': {
                    color: theme.palette.white.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                    }
                }
            },

            '& .textLeftValue': {
                // width: '150px',
                fontSize: '13px',
                margin: '0px 0px',
                fontWeight: 'normal',
                color: '#ffffff',
                '& a': {
                    color: theme.palette.white.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                    }
                }
            },
            '& .email': {
                margin: '5px 0px',
                marginTop: '10px',
                fontSize: '14px',
                fontWeight: 'bold',
                color: '#ffffff',
                '& a': {
                    color: theme.palette.white.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                    }
                }
            },

        },
        '& .imagePeople': {
            width: '600px',
            [theme.breakpoints.down('sm')]: {
                width: '360px',
            },
        }
    }
}));
