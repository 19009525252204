import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import ContainerComponent from "@Components-Project/containerComponent";
import Header from "@Components-Project/talkToUsComponents/header";
import Menu from "@Components-Project/talkToUsComponents/menu";
import Content from "@Components-Project/talkToUsComponents/content";
import { talktoUSStyles } from '@Styles-Project/theme/layout/talkToUs';
import { useSelector } from 'react-redux';
import { Help } from "@Components-Project/homeComponents";
import { useParams, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import $ from "jquery";
import { useMediaQuery } from "@material-ui/core";

export default function TalkToUsComponent({ open, handleClose }) {
    const tagManagerArgs = {
        gtmId: 'G-NR81DL4TCG',
        dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs)

    let { id } = useParams();
    const [menu, setMenu] = useState(id | 0);
    const TalkStyles = talktoUSStyles();
    const [selectQuestion, setSelectQuestion] = useState(false)
    const { AllFAQsActive } = useSelector(state => state.talkUs)
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const selectMenu = (number) => {
        setMenu(number);
        if (isSmallScreen && number !== 1) {
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".containerContent").offset().top
                }, 1500);
            }, 500);
        }
    }

    const selectOption = (number) => {
        setSelectQuestion(number);
        if (isSmallScreen) {
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".containerContent").offset().top
                }, 1500);
            }, 500);
        }
    }

    const query = useQuery();

    React.useEffect(() => {
        let a = query.get("optionSubMenu");
        if (a) setSelectQuestion(a);
    }, [])

    React.useEffect(() => {
        let c = 0
        if (AllFAQsActive) {
            while (c >= 0) {
                if (AllFAQsActive && AllFAQsActive[c].faQs.length > 0) {
                    selectOption(c)
                    c  = -1;
                } else {
                    c++;
                }
            }
        }
    }, [AllFAQsActive])

    return <Box style={{ position: 'relative', paddingTop: '85px' }} >
        <Help open={open} handleClose={handleClose} />
        <Header />
        <Box className={TalkStyles.bgMain}>
            <ContainerComponent style={{ maxHeight: '100%', width: "100%", maxWidth: '1024px', margin: 'auto' }} className={'divContainer'}>
                <div className='containerMenu'>
                    <Menu
                        menu={menu}
                        setMenu={(number) => selectMenu(number)}
                        data={AllFAQsActive}
                        option={(number) => selectOption(number)}
                        selectQuestion={selectQuestion}
                    />
                </div>

                <div className='containerContent'>
                    <Content
                        menu={menu}
                        data={!AllFAQsActive || selectQuestion === false ? [] : AllFAQsActive[selectQuestion]}
                    />
                </div>

            </ContainerComponent>

        </Box >
    </Box>
}
