/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Images from "@Constants-Project/images";
import { alertStyles } from "@Styles-Project/theme/components/alert";
import { buttonsStyles } from "@Styles-Project/theme/components/buttons";

export const Alert = ({ type = 'Correct', title, message, showInput, onPressClose }) => {
  const aStyles = alertStyles();
  const bStyles = buttonsStyles();

  return (
      <Box className={`${showInput ? 'show' : 'hide'} ${aStyles.bgAlert}`}>
        <Box className={aStyles.myAlert}>
          {
            type === 'Correct' || type === 'Error' ?
                <Button onClick={() => onPressClose()} className={'option_close'} >
                  <img src={Images.closeBlack} />
                </Button>
                : null
          }
          <Box className={'logo'}>
            <img src={type === 'Correct' ? Images.correctSent : (type === 'Error' ? Images.errorSent : Images.loader)} />
          </Box>
          <Typography variant={'h1'} >{title}</Typography>
          <Typography variant="body1">{message}</Typography>
          {
            type === 'Correct' || type === 'Error' ?
                <Button onClick={() => onPressClose()} className={`${bStyles.orangeButton} ${'btn'}`} variant="contained" disabled={false} >
                  <img className={'icon'} alt={Images.iconPolenWhite} src={Images.iconPolenWhite} /> Cerrar
                </Button>
                : null
          }
        </Box>
      </Box>
  );
};
