import { makeStyles } from "@material-ui/core/styles";

export const carouselNewsStyles = makeStyles((theme) => ({
    overflowCN: {
        width: '100%',
        maxWidth: '900px',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        margin: 'auto'
    },
    containerCN: {
        marginTop: '30px',
        height: '260px',
        width: '300px',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
        '& .swiper-container': {
            padding: '0px 22px',
            height: '260px',
            overflow: 'inherit'
        },
        '& .swiper-button-prev, .swiper-button-next': {
            width: '56px',
            height: '56px',
            backgroundColor: theme.palette.gray.gray_A7,
            borderRadius: '50%',
            webkitBorderRadius: '50%',
            mozBorderRadius: '50%',
        },
        '& .swiper-button-prev': {
            marginLeft: '-59px',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '0px',
                position: 'absolute',
                left: '0px',
            },
        },
        '& .swiper-button-next': {
            marginRight: '-59px',
            [theme.breakpoints.down('xs')]: {
                marginRight: '0px',
                position: 'absolute',
                right: '0px',
            },
        },
        '& .swiper-button-prev:after, .swiper-button-next:after': {
            fontSize: '22px',
            color: theme.palette.white.main,
            fontWeight: 'bold',
        },
        '& .swiper-slide': {
            marginTop: '50px',
            '& .circleWhite': {
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                margin: 'auto',
                width: '152px',
                height: '152px',
                padding: '20px',
                position: 'relative',
                backgroundColor: theme.palette.white.main,
                '& h1.title': {
                    position: 'relative',
                    top: '20px',
                    fontSize: '20px',
                    lineHeight: '20.4px',
                    height: '20.4px !important'
                },
                '& h1.quotes': {
                    position: 'relative',
                    top: '30px',
                    color: theme.palette.primary.main,
                    fontSize: '36px',
                    lineHeight: '36.6px',
                    textAlign: 'left',
                    height: '25px',
                },
                '& p.description': {
                    display: '-webkit-box',
                    '-webkit-line-clamp': '2',
                    '-webkit-box-orient': 'vertical',
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    top: '20px',
                    textAlign: 'left',
                    fontSize: '16px',
                    lineHeight: '18px',
                    padding: '0px 5px',
                    height: '38px',
                    overflow: 'hidden',
                }
            },
            '& .circleOrange': {
                borderRadius: '50%',
                webkitBorderRadius: '50%',
                mozBorderRadius: '50%',
                margin: 'auto',
                width: '70px',
                height: '70px',
                position: 'absolute',
                bottom: '-18px',
                right: '0px',
                backgroundColor: theme.palette.gray.gray_A7,
                display: 'flex',
                alignItems: 'center',
                '& h1, a': {
                    height: 'auto',
                    color: theme.palette.white.main,
                    fontSize: '14px',
                    lineHeight: '14.4px',
                }
            }
        },
        '& .swiper-slide-active': {
            height: '100%',
            marginTop: '0px !important',
            '& .circleWhite': {
                width: '202px !important',
                height: '202px !important',/**Cambiar a un tamaño que se va bien todas las resoluciones */
                '& h1.title': {
                    fontSize: '24px !important',
                    lineHeight: '24.4px !important',
                    height: '24.4px !important',
                    [theme.breakpoints.down('xs')]: {
                        lineHeight: '22.2px !important',
                        height: '22.2px !important',
                    },
                },
                '& h1.quotes': {
                    fontSize: '48px !important',
                    lineHeight: '48.8px !important',
                    height: '48px !important',
                    [theme.breakpoints.down('xs')]: {
                        padding: '0px 5% !important',
                        width: '90% !important',
                    },
                },
                '& p.description': {
                    fontSize: '18px !important',
                    lineHeight: '20px !important',
                    padding: '5px !important',
                    height: '80px !important',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '3 !important',
                    '-webkit-box-orient': 'vertical !important',
                    textOverflow: 'ellipsis',
                    [theme.breakpoints.down('xs')]: {
                        padding: '0px 5% !important',
                        width: '90% !important',
                        fontSize: '16px !important',
                        lineHeight: '16.6px !important',
                        '-webkit-line-clamp': '4 !important',
                    },
                }
            },
            '& .circleOrange': {
                backgroundColor: theme.palette.primary.main + ' !important',
            }
        },
    },
}));
