import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { URL_AUTOCOMPRA } from '@Constants-Project/constants';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { whatIsPolenStyles } from '@Styles-Project/theme/layout/whatIsPolen';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import ReactTypingEffect from 'react-typing-effect';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';

import { useMediaQuery } from '@material-ui/core';

export default function WhatAreYouWaitingForToStartAchievingYourGoal() {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("xs"));
    const wipStyles = whatIsPolenStyles();
    const bStyles = buttonsStyles();
    return <Box className={wipStyles.divMyGoal}>
        <ContainerComponent>
            <Typography variant={'h1'}>¿Qué esperas para comenzar a cumplir tu meta?</Typography>
            {
                isSmallScreen ?
                    <Grid container direction='column' >
                        <Grid item xs={12} sm={6} className={'text'}>
                            <Box className={"center-xs"}>
                                <Typography variant="body1">Pasa de escribir tus metas a hacerlas realidad, te invitamos a conocer todos los beneficios de pertenecer a nuestra familia Polen.</Typography>
                                <Button onClick={() => window.open(URL_AUTOCOMPRA)} variant="contained" className={`${bStyles.button} ${bStyles.orangeButton}`}>Comienza tu meta</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 61 }}>
                            <img alt={Images.paperWithPen} src={Images.paperWithPen} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction='row' >
                        <Grid item xs={12} sm={6} className={'centerContent'}>
                            <img alt={Images.paperWithPen} src={Images.paperWithPen} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'text'}>
                            <Box>
                                <Typography variant="body1">Pasa de escribir tus metas a hacerlas realidad, te invitamos a conocer todos los beneficios de pertenecer a nuestra familia Polen.</Typography>
                                <Button onClick={() => window.open(URL_AUTOCOMPRA)} variant="contained" className={`${bStyles.button} ${bStyles.orangeButton}`}>Comienza tu meta</Button>
                            </Box>
                        </Grid>
                    </Grid>
            }
        </ContainerComponent>
    </Box>
}
