import { makeStyles } from "@material-ui/core/styles";

export const videoPlayerStyles = makeStyles((theme) => ({
    myVideo: {
        position: 'relative',
        width: '100%',
        display: 'block',
        height: 'auto',
        overflow: 'hidden',
        '& .big-play-button': {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50px',
            height: '50px',
            top: '50%',
            marginTop: '-25px',
            left: '50%',
            marginLeft: '-25px',
            border: '2px solid white',
            borderRadius: '10px',
            webkitBorderRadius: '10px',
            mozBorderRadius: '10px',
            position: 'absolute',
            [theme.breakpoints.down('sm')]: { display: 'none' },
            '& span:before': {
                fontSize: '40px',
                top: '0px',
                height: '46px',
            }
        },
        '& .video-js': {
            '& .vjs-tech': {
                backgroundColor: theme.palette.primary.main,
                height: '100%',
            },
            '&.vjs-16-9': {
                paddingTop: '56.25% !important',
                [theme.breakpoints.down('md')]: { paddingTop: '56.25% !important' },
                [theme.breakpoints.down('sm')]: { paddingTop: '56.25% !important' },
            },
            '& .vjs-big-play-button': {
                backgroundColor: theme.palette.primary.main,
                justifyContent: 'center',
                alignItems: 'center',
                width: '50px',
                height: '50px',
                top: '50%',
                marginTop: '-25px',
                left: '50%',
                marginLeft: '-25px',
                '& span:before': {
                    fontSize: '40px',
                    top: '0px',
                    height: '46px',
                }
            },
            '& .vjs-control-bar': {
                height: '40px',
                paddingTop: '5px',
                '& .vjs-control': {
                    position: 'relative',
                    display: 'inline-flex',
                },
                '& .vjs-volume-panel': {
                    left: '15px',
                    top: '0px',
                    height: '30px',
                    width: '80px',
                    [theme.breakpoints.down('md')]: { left: '30px' },
                    [theme.breakpoints.down('sm')]: { left: '30px' },
                    '& .vjs-mute-control': {
                        left: '0px',
                        top: '0px',
                        width: '25px',
                    },
                    '& .vjs-volume-control': {
                        display: 'flex',
                        width: '40px !important',
                        height: '30px !important',
                        top: '0px',
                        transition: 'none !important',
                        opacity: '1',
                        // backgroundColor: theme.palette.white.main,
                    }
                },
                '& .vjs-play-control': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '5px',
                    webkitBorderRadius: '5px',
                    mozBorderRadius: '5px',
                    border: '2px solid',
                    borderColor: theme.palette.white.main,
                    height: '30px',
                    width: '30px',
                    left: '10px',
                    top: '0px',
                    '& span:before': {
                        height: '26px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '0px'
                    },
                    "& .vjs-icon-placeholder:before": {
                        top: "0px important",
                        fontSize: '26px'
                    }
                },
                '& .vjs-progress-control': {
                    height: '30px',
                    top: '0px',
                },
                '& .vjs-playback-rate, .vjs-picture-in-picture-control, .vjs-seek-to-live-control, .vjs-duration': { display: 'none', },
                '& .vjs-fullscreen-control': {
                    top: '0px',
                    left: '0px',
                    height: '30px',
                    '& span:before': {
                        height: '26px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '0px'
                    },
                    '& span.vjs-control-text': { display: 'none' }
                }
            }
        }
    }
}));
