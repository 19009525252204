import { types } from "../types/types";

export const setPosts = (posts) => ({
    type: types.setPost,
    payload: {
        posts
    }
});

export const setTestimony = (Testimony) => ({
    type: types.setTestimony,
    payload: {
        Testimony
    }
});

export const setBrands = (Brands) => ({
    type: types.setBrands,
    payload: {
        Brands
    }
});
