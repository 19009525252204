import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button, Container } from '@material-ui/core';
import Images from '@Constants-Project/images';
import { VideoPlayer } from '@Components-Project/videoPlayer';
import ContainerComponent from "@Components-Project/containerComponent";
import { talktoUSStyles } from '@Styles-Project/theme/layout/talkToUs';
import { buttonsStyles } from '@Styles-Project/theme/components/buttons';
import { typographiesStyles } from '@Styles-Project/theme/components/typographies';

export default function Menu({ data, option, selectQuestion, menu, setMenu }) {

    const [menuSecundary, setMenuSecundary] = React.useState(0);

    let Categoria = ({ menu, selectQuestion, option, index, item }) => {

        return item.faQs.length > 0 ?
            <Button
                key={index}
                onClick={() => {
                    option(index)
                }}
                className={`buttonSecondary ${menu === 1 ? 'show' : ''} ${selectQuestion === index ? "select" : ""}`}
            >
                {item.category}
            </Button>
            : null
    }

    return (
        <Grid className={'bgMenu'}>
            <Button
                onClick={() => {
                    setMenu(menu === 1 ? 0 : 1)
                }}
                className={`buttonMain${menu === 1 ? '--show' : ''}`}>Preguntas Frecuentes</Button>
            <>
                {
                    !data ? null :
                        data.map((item, index) => {

                            return (
                                <Categoria
                                    item={item}
                                    index={index}
                                    menu={menu}
                                    option={option}
                                    selectQuestion={selectQuestion}
                                />
                            )
                        })
                }
            </>
            <Button
                onClick={() => setMenu(menu === 2 ? 0 : 2)}
                className={`buttonMain${menu === 2 ? '--show' : ''}`}>Déjanos llamarte</Button>
            <Button
                onClick={() => setMenu(menu === 3 ? 0 : 3)}
                className={`buttonMain${menu === 3 ? '--show' : ''}`}>Llámanos</Button>
        </Grid>
    );
}
