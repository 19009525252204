import { types } from "../types/types";

export const setMeeting = (Meeting) => ({
    type: types.setMeeting,
    payload: {
        Meeting
    }
});

export const setDateTarget = (DataTarget) => ({
    type: types.setDateTarget,
    payload: {
        DataTarget
    }
});

export const saveIntervalToClear = (IntervalToClear) => ({
    type: types.saveIntervalToClear,
    payload: {
        IntervalToClear
    }
});


