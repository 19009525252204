import React, { useRef, useEffect } from 'react';
import { Grid, Box, Typography, Button, Container } from '@material-ui/core';
import Images from '@Constants-Project/images';
import ContainerComponent from "@Components-Project/containerComponent";
import { scheduleStyles } from '@Styles-Project/theme/layout/schedule';

export default function Header() {
    const sStyles = scheduleStyles();
    // </ContainerComponent>Typography variant={'h1'}>Próximos <b>eventos</b></Typography>
    
    return (
        <Box className={sStyles.bgGray}>
            <ContainerComponent className={'divContainer'}>
                <img alt={Images.imgHeaderScheduleNew} src={Images.imgHeaderScheduleNew} />
            </ContainerComponent>
        </Box>
    );
}
